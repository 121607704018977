import React from "react";

// Components
import {
  faCheck,
  faCopy,
  faPencil,
  faRotateRight,
  faSpinner,
  faArrowUpRightFromSquare,
  faTrash,
  faTriangleExclamation,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleXmarkIcon from "./img/CircleXmarkIcon";
import ArrowDownIcon from "./img/ArrowDownIcon";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import TextareaAutosize from "react-textarea-autosize";
import TextsPanel from "./TextsPanel";

// Functions
import numeral from "numeral";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import he from "he";
import Tooltip from "./Tooltip";
// import CartloomScript from "./CartloomScript";

class Folders extends React.Component {
  constructor(props) {
    super(props);
    this.textPanelRef = React.createRef();
    let folderType = Helper.isOrderView(this.props.appState.currentView) ? Constants.FOLDER_PRINTED_NOTES : Constants.FOLDER_NOTES;

    // Prospects view shows the potential matches for a customer by default (unless already matched)
    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS &&
      [Constants.PROSPECT_STATUS_NEW, Constants.PROSPECT_STATUS_PENDING].includes(this.props.prospect?.prospectstatus) &&
      !this.props.prospect?.contactuuid
    ) {
      folderType = Constants.FOLDER_PROSPECT_MATCHES;
    }
    this.state = {
      copied: [],
      editingPayments: false,
      emails: null,
      error: null,
      folderType: folderType,
      hideAuthorizations: true,
      hideInactiveSubscriptions: true,
      matches: null,
      notes: [],
      notesCount: 0,
      orders: [],
      ordersCount: 0,
      selected: false,
      selectedsmscontact: null,
      smscontacts: null,
      subscriptions: null,
      texts: null,
    };
  }

  componentDidMount() {
    if (this.props.setRefreshFoldersCallback) {
      this.props.setRefreshFoldersCallback(this.refreshFolders);
    }
    if (this.state.folderType === Constants.FOLDER_NOTES) {
      this.getNotes();
    }
    if (this.state.folderType === Constants.FOLDER_PROSPECT_MATCHES) {
      this.getProspectMatches();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.appState.currentView === Constants.PAY &&
      (!prevProps.order?.authorizations || prevProps.order?.authorizations?.length === 0) &&
      this.props.order?.authorizations?.length > 0
    ) {
      this.setState({ folderType: Constants.FOLDER_AUTHORIZATIONS });
    }
    // If the component re-renders with a new refuuid, then load the notes
    // Note: This probably won't be an issue once we switch the default tab to "Printed Notes"
    if (
      [Constants.FOLDER_NOTES, Constants.FOLDER_PROSPECT_MATCHES].includes(this.state.folderType) &&
      (prevProps.refuuid !== this.props.refuuid ||
        prevProps.companyuuid !== this.props.companyuuid ||
        prevProps.prospect?.prospectstatus !== this.props.prospect?.prospectstatus)
    ) {
      this.getNotes();
    }
    // If the subscription list changes in state, then update the notes field in state
    // to include the subscription plan_desc
    if (
      Constants.BILLING_DETAIL_VIEWS.includes(this.props.appState.currentView) &&
      this.state.folderType === Constants.FOLDER_NOTES &&
      prevProps.subscriptions !== this.props.subscriptions
    ) {
      const regex = /subscription_id:(\d+)/;
      this.setState(prevState => ({
        notes: prevState.notes.map(note => {
          const matchResult = note.note.match(regex);
          const subscriptionId = matchResult ? matchResult[1] : null;
          if (subscriptionId) {
            const subscription = this.props.subscriptions?.find(subscription => {
              return (
                // Match against either the subscription_id or the external_subscription_id
                parseInt(subscription.external_subscription_id) === parseInt(subscriptionId) ||
                parseInt(subscription.subscription_id) === parseInt(subscriptionId)
              );
            });
            if (subscription) {
              const name = subscription.plan_desc || subscription.plan_name;
              // The following regex replace uses a function to replace the match with the subscription name instead of 'name' directly,
              // because the 'name' variable could contain special characters that would be interpreted as regex syntax
              note.note = note.note.replace(regex, match => ` ${name} `);
            }
          }
          return note;
        }),
      }));
    }
  }

  render = () => {
    return (
      <div className="areaFoldersContainer">
        {this.renderProspectMatchesTab()}
        {this.renderPrintedNotesTab()}
        {this.renderInternalNotesTab()}
        {this.renderNotesTab()}
        {this.renderLinkedItemsTab()}
        {this.renderInvoicesTab()}
        {this.renderUnpaidInvoicesTab()}
        {this.renderOrdersTab()}
        {this.renderRepairsTab()}
        {this.renderPurchasesTab()}
        {this.renderQuotesTab()}
        {this.renderEmailTab()}
        {this.renderTextTab()}
        {this.renderPhotosTab()}
        {this.renderReverbTab()}
        {this.renderCartloomTab()}
        {this.renderCustomFieldsTab()}
        {this.renderMaastTransactionsTab()}
        {this.renderMaastInvoicesTab()}
        {this.renderMaastCardVaultTab()}
        {this.renderShippingTab()}
        {this.renderAgreementsTab()}
        {this.renderAuthorizationsTab()}
        {this.renderSubsciptionsTab()}
        {this.renderSubscriptionInvoicesTab()}
        {this.renderContent()}
      </div>
    );
  };

  renderContent = () => {
    if (this.state.error) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder">
            <div>
              There was an error loading the data.
              <br />
              <br />
            </div>
          </div>
        </div>
      );
    } else if (this.state.folderType === Constants.FOLDER_NOTES || this.state.folderType === Constants.FOLDER_INTERNAL_NOTES) {
      return this.renderNotes();
    } else if (this.state.folderType === Constants.FOLDER_PRINTED_NOTES) {
      return this.renderPrintedNotes();
    } else if (this.state.folderType === Constants.FOLDER_LINKED_ITEMS) {
      return this.renderLinkedList();
    } else if (this.state.folderType === Constants.FOLDER_INVOICES) {
      return this.renderOrderList();
    } else if (this.state.folderType === Constants.FOLDER_UNPAID_INVOICES) {
      return this.renderOrderList();
    } else if (this.state.folderType === Constants.FOLDER_ORDERS) {
      return this.renderOrderList();
    } else if (this.state.folderType === Constants.FOLDER_REPAIRS) {
      return this.renderOrderList();
    } else if (this.state.folderType === Constants.FOLDER_PURCHASES) {
      return this.renderOrderList();
    } else if (this.state.folderType === Constants.FOLDER_QUOTES) {
      return this.renderOrderList();
    } else if (this.state.folderType === Constants.FOLDER_PHOTOS) {
      return this.renderPhotoList();
    } else if (this.state.folderType === Constants.FOLDER_REVERB) {
      return this.renderReverbSettings();
    } else if (this.state.folderType === Constants.FOLDER_CARTLOOM) {
      return this.renderCartloom();
    } else if (this.state.folderType === Constants.FOLDER_MAAST_CUSTOM) {
      return this.renderCustomFields();
    } else if (this.state.folderType === Constants.FOLDER_MAAST_TRANSACTIONS) {
      return this.renderMaastTransactions();
    } else if (this.state.folderType === Constants.FOLDER_MAAST_INVOICES) {
      return this.renderMaastInvoices();
    } else if (this.state.folderType === Constants.FOLDER_MAAST_CARDS) {
      return this.renderCardVault();
    } else if (this.state.folderType === Constants.FOLDER_EMAIL) {
      return this.renderEmailList();
    } else if (this.state.folderType === Constants.FOLDER_TEXT) {
      return this.renderTextList();
    } else if (this.state.folderType === Constants.FOLDER_SHIPPING) {
      return this.renderShipping();
    } else if (this.state.folderType === Constants.FOLDER_PROSPECT_MATCHES) {
      return this.renderProspectMatches();
    } else if (this.state.folderType === Constants.FOLDER_AGREEMENTS) {
      return this.renderAgreements();
    } else if (this.state.folderType === Constants.FOLDER_AUTHORIZATIONS) {
      return this.renderAuthorizations();
    } else if (this.state.folderType === Constants.FOLDER_SUBSCRIPTIONS) {
      return this.renderSubscriptions();
    } else if (this.state.folderType === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
      return this.renderOrderList();
    } else {
      return "";
    }
  };

  renderCardVault = () => {
    if (this.props.maastCards.length === 0) {
      return (
        <div className="folderList">
          <div className="maastCardVaultTab openFolder threeColumnGrid">
            <span
              data-testid="Add Customer to Vault Button"
              className="action-button green-button vaultButton"
              onClick={this.props.handleAddPaymentMethod}
            >
              Add Customer to Vault
            </span>
            {this.renderVaultRequestElement()}
          </div>
        </div>
      );
    } else
      return (
        <div className="folderList">
          <div className="maastCardVaultTab openFolder">{this.renderAreaStoredPayments()}</div>
        </div>
      );
  };

  renderAreaStoredPayments = () => {
    let headers = [
      { classes: "header", columnheading: "Name" },
      { classes: "header desktop", columnheading: "Card Number" },
      { classes: "header mobile", columnheading: "Card" },
      { classes: "header", columnheading: "Exp" },
      { classes: "header centerAligned", columnheading: "Subscriptions" },
      {
        classes: "header",
        columnheading: (
          <span data-testid="Add Stored Payment" className="action-button green-button" onClick={this.props.handleAddPaymentMethod}>
            Add
          </span>
        ),
      },
      { classes: "header", columnheading: this.renderVaultRequestElement() },
      { classes: "header", columnheading: "" },
    ];
    return (
      <React.Fragment>
        <div className="areaCardVault">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.props.maastCards ?? []}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderStoredPaymentsToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={() => {}}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
        </div>
      </React.Fragment>
    );
  };

  renderStoredPaymentsToColumns = card => {
    if (!this.props.maastCards || this.props.maastCards.length === 0) {
      return (
        <div className="areaCardVault " data-testid="Card Vault List No items">
          No items found.
          <br />
          <br />
        </div>
      );
    }

    const name = card?.billing_first_name + " " + card?.billing_last_name;

    const cardNumber = <span data-testid="Card Number">{card?.card_number ?? "Unknown"}</span>;
    let mobileCardNumber = card?.card_number ?? "Unknown";
    if (mobileCardNumber.length > 4 && mobileCardNumber !== "Unknown") {
      mobileCardNumber = mobileCardNumber.slice(-5);
    }

    let expirationDate = card?.exp_date ?? "N/A";
    if (expirationDate.length === 4) {
      expirationDate = expirationDate.slice(0, 2) + "/" + expirationDate.slice(2);
    }
    let subsForCard = null;
    if (this.props.appState?.clientSettings?.PAYMENT_GATEWAY === Constants.NMI) {
      subsForCard = card.subscriptioncount ?? 0;
    } else {
      // Maast subscriptions
      if (this.props.subscriptions) {
        subsForCard = this.props.subscriptions?.filter(
          sub =>
            sub.card_id === card?.card_id &&
            (Constants.SUBSCRIPTION_ACTIVE_STATUSES.includes(sub.status) || Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(sub.status))
        ).length;
      }
    }
    const pencil =
      this.props.appState?.clientSettings?.PAYMENT_GATEWAY !== Constants.NMI ? (
        <span
          onClick={() => {
            this.props.handleEditStoredPayment(card);
          }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </span>
      ) : (
        ""
      );
    // Do not enable the delete button if the page has not finished loading or if the card is in use by an active subscription
    const trashcan =
      (this.props.appState?.clientSettings?.PAYMENT_GATEWAY === Constants.MAAST && !this.props.subscriptions) ||
      subsForCard === null ||
      subsForCard > 0 ? (
        <span className="save-disabled" title="Card is in use by an active subscription">
          <FontAwesomeIcon icon={faTrash} />
        </span>
      ) : (
        <span
          onClick={() => {
            this.props.handleDeleteStoredPayment(card);
          }}
          data-testid="Delete Stored Payment"
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      );
    const pencilTrashcan = (
      <div>
        {pencil}&nbsp;&nbsp;
        {trashcan}
      </div>
    );
    return [
      { rowvalue: name },
      { rowvalue: cardNumber, classes: "desktop" },
      { rowvalue: mobileCardNumber, classes: "mobile" },
      { rowvalue: expirationDate },
      { rowvalue: subsForCard ?? <FontAwesomeIcon icon={faSpinner} spin />, classes: "centerAligned" },
      { rowvalue: pencilTrashcan, classes: "centerAligned" },
      { rowvalue: "" },
      { rowvalue: "" },
    ];
  };

  renderMaastTransactions = () => {
    return (
      <div className="folderList">
        <div className="maastTransactionsTab openFolder">{this.renderAreaTransactions()}</div>
      </div>
    );
  };

  renderMaastInvoices = () => {
    return (
      <div className="folderList">
        <div className="maastInvoicesTab openFolder">{this.renderAreaInvoices()}</div>
      </div>
    );
  };

  renderAreaTransactions = () => {
    if (!this.props.maastTransactions || this.props.maastTransactions.length === 0) {
      return <span data-testid="No transactions on file">No transactions on file.</span>;
    } else {
      const headers = [
        { classes: "header", columnheading: "Date" },
        { classes: "header", columnheading: "Subscription" },
        { classes: "header desktop", columnheading: "Card Number" },
        { classes: "header mobile", columnheading: "Card" },
        { classes: "header", columnheading: "Result" },
        { classes: "header", columnheading: "Status" },
        { classes: "header right-aligned", columnheading: "Amount" },
        { classes: "header right-aligned", columnheading: "Refunded" },
        { classes: "header", columnheading: "" }, // Refund button
        { classes: "header", columnheading: "" }, // Spacer
      ];
      return (
        <React.Fragment>
          <div className="areaTransactions">
            <ListHeaderView headerRowItems={headers} />
            <ListItemView
              listitems={this.props.maastTransactions ?? []}
              expandedListItems={null}
              selectedListItems={[]}
              renderItemToColumns={this.renderTransactionsToColumns}
              toggleCollapsed={() => {}}
              selectListItem={() => {}}
              handleEditItem={() => {}}
              handleTouchStart={() => {}}
              handleTouchEnd={() => {}}
            />
          </div>
        </React.Fragment>
      );
    }
  };

  renderTransactionsToColumns = transaction => {
    const transactionDateTime = new Date(transaction.tran_time).toLocaleString();
    let refund = "";
    const amountAvailable = numeral(transaction.amt_tran).subtract(transaction.amt_refunded).value();
    if (
      Constants.SALE_APPROVAL_CODES.includes(transaction.rcode) &&
      amountAvailable > 0 &&
      Constants.REFUND_ENABLED_TRANSACTION_STATUSES.includes(transaction.tran_status)
    ) {
      refund = (
        <span
          className="action-button green-button"
          onClick={() => {
            this.handleRefundTransaction(transaction);
          }}
        >
          Refund
        </span>
      );
    }
    const status = <span data-testid="Transaction Status">{Helper.renderTransactionStatus(transaction.tran_status)}</span>;
    const voided = [Constants.TRANSACTION_VOIDED_BY_MERCHANT, Constants.TRANSACTION_VOIDED_BY_SYSTEM].includes(transaction.tran_status);
    const amountTransClasses = "right-aligned " + (voided ? "strikethrough" : "");
    const refundedAmount = voided ? "-" : numeral(transaction.amt_refunded).format(Constants.CURRENCY);
    return [
      { rowvalue: transactionDateTime },
      { rowvalue: transaction.subscription_description },
      { rowvalue: transaction.card_number, classes: "desktop" },
      { rowvalue: transaction.card_number.slice(-5), classes: "mobile" },
      { rowvalue: transaction.rmsg },
      { rowvalue: status },
      {
        rowvalue: numeral(transaction.amt_tran).format(Constants.CURRENCY),
        classes: amountTransClasses,
      },
      {
        rowvalue: refundedAmount,
        classes: "right-aligned",
      },
      { rowvalue: refund },
      { rowvalue: "" },
    ];
  };

  renderAreaInvoices = () => {
    let resendInvoiceClasses = "action-button brown-button";
    let markPaidInvoiceClasses = "action-button blue-button";
    let payInvoiceClasses = "action-button green-button";
    let saveClasses = "action-button green-button";
    if (this.props.selectedInvoices?.length === 0) {
      resendInvoiceClasses += " save-disabled";
    }
    if (
      this.props.selectedInvoices?.length === 0 ||
      !this.props.selectedInvoices?.find(invoice => invoice.amt_balance > 0) ||
      this.props.selectedInvoices?.find(invoice => invoice.amt_balance === 0)
    ) {
      markPaidInvoiceClasses += " save-disabled";
      payInvoiceClasses += " save-disabled";
    } else if (this.props.isMaastOnlyCustomer()) {
      payInvoiceClasses += " save-disabled";
    }

    // If we're editing payment, then run some checks against the selected invoices
    if (this.props.editingPayments) {
      // Check that the payment date and type fields are filled out for all selected invoices
      // before enabling the save button
      const completedInvoices = this.props.selectedInvoices.filter(invoice => invoice.map_date_payment && invoice.map_payment_type);
      if (completedInvoices.length !== this.props.selectedInvoices.length) {
        saveClasses += " save-disabled";
      }
    }

    let sendInvoice = (
      <span
        onClick={() => {
          if (this.props.selectedInvoices?.length === 0) {
            return;
          }
          this.props.handleEmail(email => {
            this.props.handleSendInvoices(this.props.selectedInvoices, email);
          });
        }}
        className={resendInvoiceClasses}
      >
        Send Invoice
      </span>
    );
    let markPaidInvoiceButton = (
      <span
        onClick={() => {
          if (
            this.props.selectedInvoices?.length === 0 ||
            !this.props.selectedInvoices?.find(invoice => invoice.amt_balance > 0) ||
            this.props.selectedInvoices?.find(invoice => invoice.amt_balance === 0)
          ) {
            return;
          }
          this.props.handleEditInvoicePayments(true);
        }}
        className={markPaidInvoiceClasses}
      >
        Mark as Paid
      </span>
    );
    let payButton = this.props.appState.features.includes(Constants.FEATURE_INVOICES) ? (
      <span
        title={this.props.isMaastOnlyCustomer() ? "Maast-only customers cannot use in-store payments" : ""}
        onClick={() => {
          if (
            this.props.selectedInvoices?.length === 0 ||
            !this.props.selectedInvoices?.find(invoice => invoice.amt_balance > 0) ||
            this.props.selectedInvoices?.find(invoice => invoice.amt_balance === 0)
          ) {
            return;
          }
          this.props.handlePayInvoices(this.props.selectedInvoices, this.props.company);
        }}
        className={payInvoiceClasses}
      >
        In-store Payment
      </span>
    ) : (
      ""
    );
    const saveButton = (
      <span
        onClick={() => {
          // Check that the payment date and type fields are filled out for all selected invoices
          const completedInvoices = this.props.selectedInvoices.filter(invoice => invoice.map_date_payment && invoice.map_payment_type);
          if (completedInvoices.length !== this.props.selectedInvoices.length) {
            return;
          }
          this.props.handleMarkInvoicesPaid(this.props.selectedInvoices);
        }}
        className={saveClasses}
      >
        Save
      </span>
    );
    const cancelButton = (
      <span
        onClick={() => {
          this.props.handleEditInvoicePayments(false);
        }}
        className={"action-button red-button"}
      >
        Cancel
      </span>
    );
    let more = "";
    if (this.props.maastInvoicesPagination.totalRecords > this.props.maastInvoices?.length) {
      more = (
        <span
          data-testid="Show More Items"
          className="selected italic showMoreInvoices cursorPointer"
          onClick={() => {
            const pageNumber = this.props.maastInvoicesPagination.pageNumber + 1;
            this.props.getMaastInvoices(this.props.refuuid, pageNumber, true);
          }}
        >
          Show more items
        </span>
      );
    }

    if (!this.props.maastInvoices || this.props.maastInvoices.length === 0) {
      return <span data-testid="No invoices on file">No invoices on file.</span>;
    } else {
      const headers = [
        { classes: "header", columnheading: "Date" },
        { classes: "header", columnheading: "Link" },
        { classes: "header", columnheading: "Sent" },
        { classes: "header", columnheading: "Subscription" },
        { classes: "header centerAligned", columnheading: "Status" },
        { classes: "header right-aligned", columnheading: "Total" },
        { classes: "header right-aligned", columnheading: "Paid" },
        { classes: "header right-aligned", columnheading: "Date Paid" },
        { classes: "header right-aligned", columnheading: "Balance" },
        { classes: "header", columnheading: this.props.editingPayments ? "Payment Date" : "" },
        { classes: "header", columnheading: this.props.editingPayments ? "Payment Type" : "" },
        // { classes: "header", columnheading: this.props.editingPayments ? "Description" : "" }, removing field
        { classes: "header", columnheading: "" },
        { classes: "buttonheader", columnheading: this.props.editingPayments ? cancelButton : sendInvoice },
        { classes: "buttonheader", columnheading: this.props.editingPayments ? "" : markPaidInvoiceButton },
        { classes: "buttonheader", columnheading: this.props.editingPayments ? saveButton : payButton },
      ];
      return (
        <React.Fragment>
          <div className="areaInvoices">
            <ListHeaderView headerRowItems={headers} />
            <ListItemView
              listitems={this.props.maastInvoices ?? []}
              expandedListItems={null}
              selectedListItems={this.props.selectedInvoices ?? []}
              renderItemToColumns={this.renderInvoicesToColumns}
              toggleCollapsed={() => {}}
              selectListItem={this.props.handleSelectInvoice}
              handleEditItem={() => {}}
              handleTouchStart={() => {}}
              handleTouchEnd={() => {}}
            />
            {more}
          </div>
        </React.Fragment>
      );
    }
  };

  renderInvoicesToColumns = invoice => {
    const invoiceDateTime = Helper.formatDate(invoice.date_invoice);
    let datePickerOrDelete = "";
    let typePicker = "";
    let dimmed = "";
    // Show the Cancel Payment icon if the invoice is open and has not been paid
    if (Constants.MAAST_INVOICE_STATUSES_OPEN.includes(invoice.status) && invoice.amt_paid === 0) {
      datePickerOrDelete = (
        <div className="ghost" title="Cancel invoice">
          <FontAwesomeIcon
            icon={faTrash}
            onClick={event => {
              event.stopPropagation();
              this.props.handleCancelMaastInvoice(invoice);
            }}
          />
        </div>
      );
    }
    if (this.props.editingPayments && !this.props.selectedInvoices?.find(i => i.invoice_id === invoice.invoice_id)) {
      dimmed = " save-disabled ";
    } else if (this.props.editingPayments) {
      datePickerOrDelete = (
        <input
          type="date"
          id="map_date_payment"
          onChange={event => {
            this.props.handleChange(event, Constants.BILLING_INVOICE, invoice.invoice_id);
          }}
          onBlur={event => {
            this.props.handleBlur(event, Constants.BILLING_INVOICE, invoice.invoice_id);
          }}
          value={invoice.map_date_payment ?? ""}
        />
      );
      typePicker = (
        <select
          id="map_payment_type"
          onChange={event => {
            this.props.handleChange(event, Constants.BILLING_INVOICE, invoice.invoice_id);
          }}
          selected={invoice.map_payment_type ?? ""}
          value={invoice.map_payment_type ?? ""}
        >
          <option value="">Select</option>
          <option value="CASH">Cash</option>
          <option value="CHECK">Check</option>
          <option value="CREDIT">Credit Card</option>
          <option value="OTHER">Other</option>
        </select>
      );
    }
    const copyInvoiceLink = invoice.invoice_link ? (
      this.state.copied.includes(invoice.invoice_link) ? (
        <FontAwesomeIcon
          icon={faCheck}
          onClick={event => {
            this.handleCopyUrlToClipboard(event, invoice.invoice_link);
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faCopy}
          onClick={event => {
            this.handleCopyUrlToClipboard(event, invoice.invoice_link);
          }}
        />
      )
    ) : (
      ""
    );

    const invoice_link = invoice.invoice_link ? (
      <span>
        <a
          href={invoice.invoice_link}
          className="white"
          target="_blank"
          rel="noreferrer"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {invoice.invoice_id}
        </a>{" "}
        {copyInvoiceLink}
      </span>
    ) : (
      invoice.invoice_id
    );

    return [
      { rowvalue: invoiceDateTime, classes: dimmed },
      { rowvalue: invoice_link, classes: dimmed },
      { rowvalue: Helper.formatDate(invoice.date_sent), classes: dimmed },
      { rowvalue: invoice.subscription_description, classes: dimmed },
      { rowvalue: invoice.status, classes: "centerAligned" + dimmed },
      {
        rowvalue: numeral(invoice.amt_sub_total).add(invoice.amt_tax).format(Constants.CURRENCY),
        classes: "right-aligned" + dimmed,
      },
      { rowvalue: numeral(invoice.amt_paid).format(Constants.CURRENCY), classes: "right-aligned" + dimmed },
      { rowvalue: Helper.formatDate(invoice.date_payment), classes: "right-aligned" + dimmed },
      { rowvalue: numeral(invoice.amt_balance).format(Constants.CURRENCY), classes: "right-aligned" + dimmed },
      { rowvalue: datePickerOrDelete },
      { rowvalue: typePicker, classes: "mapPaymentType" + dimmed },
      // { rowvalue: description, classes: "mapPaymentDescription" + dimmed }, this value does not return from the API to display after the fact, so I am getting rid of it.
      { rowvalue: "", classes: dimmed },
      { rowvalue: "", classes: dimmed },
      { rowvalue: "", classes: dimmed },
      { rowvalue: "", classes: dimmed },
    ];
  };

  renderCustomFields = () => {
    // TODO: Hyperlink to the setting view
    if (!this.props.appState.customFields || this.props.appState.customFields?.length === 0) {
      return (
        <div className="folderList">
          <div className="maastCustomFieldsTab openFolder">
            <span className="highlight" data-testid="Custom Fields not setup">
              You must define your custom field labels in the "Billing Custom Fields" section of the application settings before assigning values to a
              subscription.
            </span>
          </div>
        </div>
      );
    } else if (this.props.maastCustomer && (!this.props.company?.vaulted || !this.props.subscriptions || this.props.subscriptions?.length === 0)) {
      return (
        <div className="folderList">
          <div className="maastCustomFieldsTab openFolder">
            <span>No subscriptions found.</span>
          </div>
        </div>
      );
    } else if (!this.props.company?.vaulted) {
      return (
        <div className="folderList">
          <div className="maastCustomFieldsTab openFolder">
            <span>This customer has no subscriptions.</span>
          </div>
        </div>
      );
    } else if (!this.props.subscriptions) {
      return (
        <div className="folderList">
          <div className="maastCustomFieldsTab openFolder">
            <span>Searching for subscriptions...</span>
          </div>
        </div>
      );
    } else {
      // Headers not needed
      const body = this.props.subscriptions
        ?.filter(subscription => {
          if (this.props.hideInactiveSubscriptions) {
            // REVISIT: CH_SUBSCRIPTION_ACTIVE_STATUSES will be the default when Maast subscriptions have been fully retired
            return (
              Constants.SUBSCRIPTION_ACTIVE_STATUSES.includes(subscription.status) ||
              Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(subscription.status)
            );
          } else {
            return true;
          }
        })
        // Don't show custom fields for new, unsaved subscriptions (they don't have any)
        .filter(subscription => {
          return !subscription.isNew;
        })
        .map(subscription => {
          const mapped = (
            <ListItemView
              listitems={subscription.custom_fields}
              expandedListItems={null}
              selectedListItems={[]}
              renderItemToColumns={(item, rowindex) => {
                return this.renderCustomFieldsToColumns(item, subscription);
              }}
              toggleCollapsed={() => {}}
              selectListItem={() => {}}
              handleEditItem={() => {}}
              handleTouchStart={() => {}}
              handleTouchEnd={() => {}}
            />
          );

          // REVISIT: the plan_name will be the default when Maast subscriptions have been fully retired
          return (
            <React.Fragment key={subscription.subscription_id}>
              <div className="headercustomfield">{subscription.plan_name || subscription.plan_desc}</div>
              <div className="headercustomfield">{Helper.renderSubscriptionStatus(subscription.status)}</div>
              {mapped}
              {/* {unmapped} */}
            </React.Fragment>
          );
        });
      return (
        <div className="folderList">
          <div className="maastCustomFieldsTab">
            <div className="areaCustomFields">{body}</div>
          </div>
        </div>
      );
    }
  };

  renderCustomFieldsToColumns = (field, subscription) => {
    const subscription_id = subscription.subscription_id;
    // REVISIT: subscription.companyuuid will be the default when Maast subscriptions have been fully retired
    const companyuuid = subscription.customer_id || subscription.companyuuid;
    const input = (
      <input
        type="text"
        name="custom_field_value"
        placeholder={field.description}
        maxLength={128}
        value={field.customfieldvalue}
        onFocus={Helper.handleFocus}
        onChange={event => {
          this.props.handleChangeCustomField(event, subscription_id, field.customfieldlabeluuid);
        }}
        onBlur={event => {
          this.props.handleBlurCustomField(event, companyuuid, subscription_id, field.customfieldlabeluuid, subscription.isNew);
        }}
      />
    );
    return [{ rowvalue: field.description }, { rowvalue: input }];
  };

  isReadyToPublish = () => {
    return (
      this.props.product?.make &&
      this.props.product?.model &&
      this.props.product?.photos?.length > 0 &&
      this.props.product?.storesku &&
      this.props.product?.reverbListing?.categories &&
      this.props.product?.reverbListing?.categories?.length > 0 &&
      this.props.product?.reverbListing?.categories[0]?.uuid &&
      this.props.product?.reverbListing?.condition?.uuid !== -1 &&
      this.props.product?.reverbListing?.condition?.uuid &&
      this.props.product?.reverbListing?.shipping_profile_id !== "-1" &&
      this.props.product?.reverbListing?.shipping_profile_id
    );
  };

  renderCartloom = () => {
    const pid = this.props.product?.externalid;
    if (!pid) {
      return (
        <div className="folderList">
          <div className="cartloomTab openFolder">
            <div className="cartLoomProductLink ">
              <div className="instructions">
                <span className="highlight">This product has not been published to Cartloom.</span>
              </div>
              <div className="instructions">
                To publish this product to Cartloom, ensure that the product has:
                <ul>
                  <li>At least one photo</li>
                  <li>Short description</li>
                  <li>Full description</li>
                  <li>Sell price</li>
                  <li>Store SKU</li>
                </ul>
                Then activate the "Publish Product to Cartloom" switch above.
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    }

    const subdomain = this.props.appState.subdomain;
    return (
      <div className="folderList">
        <div className="cartloomTab openFolder">
          <div className="cartLoomProductLink ">
            <div className="instructions">
              To manage your product ({pid}) on Cartloom, click the product link below and sign in using your Cartloom username and password, if
              prompted.
            </div>
            <a className="inlineLink " href={`https://${subdomain}.cartloom.com/admin/products/edit/${pid}#basic`} target="_blank" rel="noreferrer">
              Manage Product on Cartloom
            </a>
          </div>
        </div>
      </div>
    );
    // return <CartloomScript product={this.props.product} />;
  };

  renderReverbSettings = () => {
    // Check for Reverb API errors
    // if this.props.shippingProfiles is an instance of AppError, then we have an error
    if (this.props.appState.thirdparty.reverbapikey && this.props.shippingProfiles instanceof Helper.AppError) {
      let instructions =
        // Client error
        this.props.shippingProfiles.code >= 400 && this.props.shippingProfiles.code < 500 ? (
          <div className="span2 reverbInstructions">
            <span className="highlight">
              We attempted to retrieve your merchant information from Reverb and received an error response: "
              <span className="italic">{this.props.shippingProfiles.message}</span>".
            </span>
            <br />
            <br />
            Please ensure that the API key is entered correctly in the ClerkHound settings and the key's "OAuth Scopes" include "public",
            "read_listings", "write_listings", "read_orders", "write_orders", and "read_profile".
            <br />
            <br />
            On the Reverb website, you can access your API key by navigating to your "Shop Settings", selecting the "My Account" menu, choosing "API &
            Integrations", and clicking the "Edit" button next to your API key.
            <br />
            <br />
            Don't forget to click the "Update Token" button after making changes.
          </div>
        ) : (
          // Server error
          <div className="span2 reverbInstructions">
            <span className="highlight">We attempted to retrieve your merchant information from Reverb and received an error response.</span>
            <br />
            <br />
            Reverb response: {this.props.shippingProfiles.message}
            <br />
            <br />
            The error code indicates a problem with the Reverb API. Please try again later.
          </div>
        );
      return (
        <div className="folderList">
          <div className="reverbListItems openFolder  ">{instructions}</div>
        </div>
      );
    }
    let isNew = this.props.product?.reverbListing?.id ? false : true;
    let status = "Not published";
    let reverbLink = "";
    let listingCategory = "";
    let refresh = "";
    if (this.props.product?.reverbListing?.categories?.length > 0) {
      listingCategory = this.props.product?.reverbListing?.categories[0]?.uuid;
    }
    if (!isNew && this.props.product?.reverbListing?._links?.web?.href) {
      if (!this.props.product?.reverbListing?.live) {
        refresh = (
          <span
            className="highlightHover"
            onClick={() => {
              this.props.getReverbListing(this.props.product?.storesku);
            }}
          >
            <FontAwesomeIcon icon={faRotateRight} />
          </span>
        );
      }
      status = this.props.product?.reverbListing?.state?.description ?? "Unknown";
      if (this.props.product?.reverbListing?.draft) {
        status += " (publishing status may be delayed... Refresh to check for updates)";
      } else if (this.props.product?.reverbListing?.live && this.props.product?.reverbListing?.published_at) {
        status += " on " + Helper.formatDate(this.props.product?.reverbListing?.published_at);
      }
      if (this.props.product?.reverbListing?.live) {
        status +=
          " (" +
          (this.props.product?.reverbListing?.stats?.views ?? "0") +
          " views and " +
          (this.props.product?.reverbListing?.stats?.watches ?? "0") +
          " watches)";
      }
      reverbLink = (
        <div className="reverbLink">
          <a href={this.props.product?.reverbListing?._links?.web?.href} target="_blank" rel="noreferrer" className="inlineLink gridCenter">
            {this.props.product?.reverbListing?._links?.web?.href}
          </a>
        </div>
      );
    }
    let publishClassName = "action-button green-button reverbPublish";
    let publish = () => {
      this.props.maybePublishOrPutToReverb(this.props.product);
    };
    if (!this.isReadyToPublish()) {
      publishClassName += " save-disabled";
      publish = () => {};
    }
    let focusField = "model";
    if (this.props.product?.make === "") {
      focusField = "make";
    }
    if (this.props.product?.storesku === "") {
      focusField = "storesku";
    }

    // Use a temp array of shipping options while we build the list of valid shipping options for the select
    let shippingProfilesTemp = this.props.shippingProfiles ? Helper.deepCopy(this.props.shippingProfiles) : [];
    // If we have shipping profiles, insert a Please Select option at the top
    if (shippingProfilesTemp && shippingProfilesTemp.length > 0) {
      shippingProfilesTemp.unshift({ id: "-1", name: "Please select" });
    }
    // If the profile list is empty, switch to "No profiles found" as the only option
    else if (shippingProfilesTemp && shippingProfilesTemp.length === 0) {
      shippingProfilesTemp = [{ id: "-1", name: "No profiles found" }];
    }

    // Build a list of shipping profiles options from the array
    const profileOptions = shippingProfilesTemp.map(profile => {
      return (
        <option key={profile.id} value={profile.id}>
          {profile.name}
        </option>
      );
    });

    // If there are no shipping profiles defined on Reverb, add a warning message
    let profilesWarning = "";
    if (this.props.shippingProfiles?.length === 0) {
      profilesWarning = (
        <span className="highlight">
          &nbsp;
          <FontAwesomeIcon icon={faTriangleExclamation} /> You must return to the Reverb website to define your shipping profiles before you can post
          products.
        </span>
      );
    }

    let profiles = (
      <div>
        <select
          name="shipping_profile"
          id="shipping_profile"
          data-testid="Listing Shipping Profile"
          onChange={e => {
            this.props.handleChangeReverbAttribute(Constants.REVERB_CHANGE_SHIPPING, e);
          }}
          value={this.props.product?.reverbListing?.shipping_profile_id}
        >
          {profileOptions}
        </select>
        {profilesWarning}
      </div>
    );

    const conditions = !this.props.listingConditions ? (
      <option key="no-condition-found" value="-1">
        No conditions found
      </option>
    ) : (
      this.props.listingConditions.map(condition => {
        return (
          <option key={condition.uuid} value={condition.uuid}>
            {condition.display_name}
          </option>
        );
      })
    );
    let conditionDescription =
      this.props.listingConditions?.find(condition => condition.uuid === this.props.product?.reverbListing?.condition?.uuid)?.description || "";

    if (conditionDescription) {
      conditionDescription = <span className="italic">({conditionDescription})</span>;
    }
    const categories = !this.props.listingCategories ? (
      <option key="no-category-found" value="-1">
        No categories found
      </option>
    ) : (
      this.props.listingCategories.map(category => {
        return (
          <option key={category.uuid} value={category.uuid}>
            {category.name}
          </option>
        );
      })
    );
    if (this.props.appState.thirdparty.reverbapikey) {
      // Render overlay until the data loads
      if (!this.props.listingCategories || !this.props.listingConditions || !this.props.shippingProfiles) {
        return (
          <div className="folderList">
            <div className="reverbListItems openFolder  ">
              <div className="span2 reverbInstructions">Requesting your listing information from Reverb...</div>
            </div>
          </div>
        );
      } else {
        const photoClass = this.props.photos?.length > 0 ? "" : "highlight";
        return (
          <div className="folderList">
            <div className="reverbListItems openFolder  ">
              <div className="span2 reverbInstructions" data-testid="Listing instructions">
                Reverb listings must include <span className={photoClass}>at least one photo, </span>
                a store SKU, make, model, shipping profile, condition, category, and up to two subcategories. <br /> When you are ready to publish
                your item and photos to Reverb, press the Publish button. Listing will go
                <b className="highlight"> live</b> immediately.
                <br />
                Please note that the order of your photos must be managed on Reverb.
              </div>
              <label htmlFor="status" className="product-header">
                Status {refresh}
              </label>
              <div className="areaInputItem">
                <input type="text" name="status" id="status" data-testid="Listing Status" autoComplete="off" disabled={true} value={status} />
              </div>
              <label htmlFor="storesku" className="product-header">
                Store SKU
              </label>
              <div className="areaInputItem">
                <input
                  type="text"
                  name="storesku"
                  id="storesku"
                  data-testid="Listing Store SKU"
                  autoFocus={focusField === "storesku"}
                  placeholder={"Store SKU"}
                  autoComplete="off"
                  maxLength={32}
                  onFocus={Helper.handleFocus}
                  onChange={event => {
                    this.props.handleChange(event, Constants.PRODUCT, this.props.product?.productuuid);
                  }}
                  onBlur={event => {
                    this.props.handleBlur(event, Constants.PRODUCT, this.props.product?.productuuid);
                  }}
                  value={this.props.product?.storesku ?? ""}
                />
              </div>
              <label htmlFor="make" className="product-header">
                Make/Brand
              </label>
              <div className="areaInputItem">
                <input
                  type="text"
                  name="make"
                  id="make"
                  data-testid="Listing Make"
                  autoFocus={focusField === "make"}
                  placeholder={"Make/Brand"}
                  maxLength={255}
                  autoComplete="off"
                  onFocus={Helper.handleFocus}
                  onBlur={event => {
                    this.props.handleBlur(event, Constants.PRODUCT, this.props.product?.productuuid);
                  }}
                  onChange={event => {
                    this.props.handleChange(event, Constants.PRODUCT, this.props.product?.productuuid);
                  }}
                  value={this.props.product?.make ?? ""}
                />
              </div>

              <label htmlFor="model" className="product-header">
                Model
              </label>
              <div className="areaInputItem">
                <input
                  type="text"
                  name="model"
                  id="model"
                  data-testid="Listing Model"
                  autoFocus={focusField === "model"}
                  placeholder={"Model"}
                  maxLength={255}
                  autoComplete="off"
                  onFocus={Helper.handleFocus}
                  onBlur={event => {
                    this.props.handleBlur(event, Constants.PRODUCT, this.props.product?.productuuid);
                  }}
                  onChange={event => {
                    this.props.handleChange(event, Constants.PRODUCT, this.props.product?.productuuid);
                  }}
                  value={this.props.product?.model ?? ""}
                />
              </div>

              <label htmlFor="shipping_profile" className="product-header">
                Shipping Profile
              </label>
              <div className="areaInputItem">{profiles}</div>

              <label htmlFor="listingCondition" className="product-header">
                Condition
              </label>
              <div className="areaInputItem">
                <select
                  name="listingCondition"
                  id="listingCondition"
                  data-testid="Listing Condition"
                  onChange={e => {
                    this.props.handleChangeReverbAttribute(Constants.REVERB_CHANGE_CONDITION, e);
                  }}
                  value={this.props.product?.reverbListing?.condition?.uuid}
                >
                  <option key="select-condition" value="">
                    Please select
                  </option>
                  {conditions}
                </select>
                &nbsp; {conditionDescription}
              </div>

              <label htmlFor="listingCategory" className="product-header">
                Category
              </label>
              <div className="areaInputItem">
                <select
                  name="listingCategory"
                  id="listingCategory"
                  data-testid="Listing Category"
                  onChange={e => {
                    this.props.handleChangeReverbAttribute(Constants.REVERB_CHANGE_CATEGORY, e);
                  }}
                  value={listingCategory}
                >
                  <option key="select-category" value="">
                    Please select
                  </option>
                  {categories}
                </select>
              </div>

              {this.renderSubCategories(1)}
              {this.renderSubCategories(2)}

              <div className="areaInputItem">
                <div data-testid="Publish Update Button" className={publishClassName} onClick={publish}>
                  {isNew ? "Publish to" : "Update on"} Reverb
                </div>
              </div>
              {reverbLink}

              <div className="span2 reverbDisclaimer">
                Disclaimer: Reverb is a third-party marketplace. We do not control the listing process or pricing, nor do we receive any of the fees
                charged by Reverb.
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="folderList">
          <div className="reverbListItems openFolder noReverbKey ">
            <div className="span2 reverbInstructions">
              Clerkhound is ready to publish your products to Reverb. However, in order to integrate with Reverb's website, you must enter your Reverb
              API key in settings. Here's a step-by-step guide to enable Reverb integration:
              <ol>
                <li>Create a Reverb account or ensure that you already have one.</li>
                <li>
                  Define one or more Reverb
                  <a href="https://reverb.com/my/shop/edit" target="_blank" rel="noreferrer" className="inlineLink">
                    &nbsp;shipping profiles.&nbsp;
                  </a>
                </li>
                <li>
                  Generate a Reverb
                  <a href="https://reverb.com/my/api_settings" target="_blank" rel="noreferrer" className="inlineLink">
                    &nbsp;API key&nbsp;
                  </a>
                  with the necessary OAuth scopes:
                  <span className="italic">&nbsp;public, read_listings, write_listings, read_orders, "write_orders", and read_profile.</span>
                </li>
                <li>Finally, enter your Reverb API key in the ClerkHound settings under Third Party Integration.</li>
              </ol>
              By completing these straightforward steps, you will unlock the ability to effortlessly publish products for sale on Reverb. Enjoy the
              convenience and benefits of selling your items through this platform.
            </div>
            <div className="span2 reverbDisclaimer">
              Disclaimer: Reverb is a third-party marketplace. We do not control the listing process or pricing, nor do not receive any of the fees
              charged by Reverb.
            </div>
          </div>
        </div>
      );
    }
  };

  renderSubCategories = level => {
    // Do not render if the previous level is not selected
    if (level === 1 && this.props.product?.reverbListing?.categories?.length < 1) {
      return "";
    }
    if (level === 2 && this.props.product?.reverbListing?.categories?.length < 2) {
      return "";
    }

    // Build the subcategory list
    let categoryuuid = "";
    if (this.props.product?.reverbListing?.categories?.length > 0) {
      categoryuuid = this.props.product?.reverbListing?.categories[0].uuid;
    }
    let subcategories = null;
    if (level === 1) {
      subcategories = this.props.listingCategories?.find(category => category.uuid === categoryuuid)?.subcategories || [];
    } else if (level === 2) {
      subcategories = this.props.listingCategories?.find(category => category.uuid === categoryuuid)?.subcategories || [];
      subcategories = subcategories.filter(subcat => subcat.uuid !== this.props.product?.reverbListing?.categories[1]);
    }

    // If there are no subcategories, do not render
    if (!subcategories || subcategories.length === 0) {
      return "";
    }

    const optionlist = subcategories.map(subcategory => {
      return (
        <option key={subcategory.uuid} value={subcategory.uuid}>
          {subcategory.name}
        </option>
      );
    });

    // Render the subcategory list
    const name = `listingSubCategory${level}`;
    const key = `select-subcategory-${level}`;
    const type = level === 1 ? Constants.REVERB_CHANGE_SUBCAT1 : Constants.REVERB_CHANGE_SUBCAT2;
    return (
      <React.Fragment>
        <label htmlFor={name} className="product-header">
          &nbsp;&nbsp;Sub-Category
        </label>
        <div className="areaInputItem">
          <select
            name={name}
            id={name}
            data-testid={name}
            onChange={e => {
              this.props.handleChangeReverbAttribute(type, e);
            }}
            value={this.props.product?.reverbListing?.categories[level]?.uuid}
          >
            <option key={key} value="">
              Please select
            </option>
            {optionlist}
          </select>
          &nbsp; <span className="italic">(Optional)</span>
        </div>
      </React.Fragment>
    );
  };

  renderPhotoPlaceholder = () => {
    return (
      <div
        className="photoListItem placeholder"
        key="placeholder"
        onDrop={e => {
          Helper.findParent(e.target, "placeholder").classList.remove("dragOver");
          this.handleDropPhoto(e);
        }}
        onDragOver={e => {
          e.preventDefault();
        }}
        onDragEnter={e => {
          e.preventDefault();
          if (e.target.classList.contains("placeholder")) {
            e.target.classList.add("dragOver");
          }
        }}
        onDragExit={e => {
          e.preventDefault();
          // Only remove the class if leaving the parent element, not a nested element
          if (e.target.classList.contains("placeholder") && !Helper.findParent(e.relatedTarget, "placeholder")) {
            e.target.classList.remove("dragOver");
          }
        }}
        onDragLeave={e => {
          e.preventDefault();
          // Only remove the class if leaving the parent element, not a nested element
          if (e.target.classList.contains("placeholder") && !Helper.findParent(e.relatedTarget, "placeholder")) {
            e.target.classList.remove("dragOver");
          }
        }}
        onDragEnd={e => {
          e.preventDefault();
          // Only remove the class if leaving the parent element, not a nested element
          if (e.target.classList.contains("placeholder") && !Helper.findParent(e.relatedTarget, "placeholder")) {
            e.target.classList.remove("dragOver");
          }
        }}
      >
        <ArrowDownIcon />
        <div className="centerAligned" data-testid="Drag Files">
          Drag files here in the order you want them to appear
          <br />
          (up to 25 files, 5MB max file size, JPG/PNG/WEBP/BMP)
        </div>
      </div>
    );
  };

  renderPhotoList = () => {
    let photolist = this.props.product.photos;
    let photos;
    if (!photolist || photolist.length === 0) {
      photos = this.renderPhotoPlaceholder();
    } else {
      photos = this.props.product.photos.map(photo => {
        let photoListItemClasses = "photoListItem";
        let controls = (
          <div className="productPhotoControls">
            <span onClick={() => this.handleDeletePhoto(photo.photouuid)} data-testid={"Delete Photo " + photo.name}>
              <CircleXmarkIcon />
            </span>
          </div>
        );
        if (photo.deleting) {
          photoListItemClasses += " deleting ";
          controls = "";
        }

        return (
          <div key={photo.photouuid} className={photoListItemClasses} data-testid={"Photo " + photo.name}>
            {controls}
            <img className="productPhoto" alt={photo.photouuid} src={photo.photourl} />
          </div>
        );
      });
      if (this.props.product.photos.length < 25) {
        photos.push(this.renderPhotoPlaceholder());
      }
    }
    return (
      <div className="folderList">
        <div className="openFolder photoListItems">{photos}</div>
      </div>
    );
  };

  renderProspectMatchesTab = () => {
    // Only show for prospects that have not been matched
    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS &&
      [Constants.PROSPECT_STATUS_NEW, Constants.PROSPECT_STATUS_PENDING].includes(this.props.prospect?.prospectstatus) &&
      !this.props.prospect?.contactuuid
    ) {
      return (
        <span
          title="Prospect Matches Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_PROSPECT_MATCHES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_PROSPECT_MATCHES)}
        >
          Matches
        </span>
      );
    }
    return "";
  };

  renderPrintedNotesTab = () => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      return (
        <span
          data-testid="Printed Notes Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_PRINTED_NOTES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_PRINTED_NOTES)}
        >
          Printed Notes
        </span>
      );
    } else {
      return "";
    }
  };

  renderInternalNotesTab = () => {
    if (Helper.isOrderView(this.props.appState.currentView) && this.props.appState.currentView !== Constants.PAY) {
      return (
        <span
          data-testid="Internal Notes Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_INTERNAL_NOTES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_INTERNAL_NOTES)}
        >
          Internal Notes
        </span>
      );
    } else {
      return "";
    }
  };

  renderNotesTab = () => {
    if (
      Constants.FOLDER_NOTES_ENABLED_VIEWS.includes(this.props.appState.currentView) ||
      (Helper.isOrderView(this.props.appState.currentView) && !this.props.isProtectedCustomer && this.props.appState.currentView !== Constants.PAY) ||
      (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.PROSPECT)
    ) {
      let label = Helper.isOrderView(this.props.appState.currentView) ? "Customer Notes" : "Notes";
      if (this.props.appState.currentView === Constants.PURCHASE) {
        label = "Supplier Notes";
      }
      return (
        <span
          data-testid={label + " Folder"}
          onClick={() => {
            this.handleClick(Constants.FOLDER_NOTES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_NOTES)}
        >
          {label}
        </span>
      );
    } else {
      return "";
    }
  };

  renderNotes = () => {
    const notes = this.state.notes.map(note => {
      let date = note.lastupdated ? Helper.formatDateTime(note.lastupdated) : Helper.today();
      if (note.notetype === Constants.MAAST) {
        date = "Maast Comment";
      }
      const classNames = "notesValue" + (note.readonly ? " readonly" : "") + (note.error ? " error" : "");
      return (
        <React.Fragment key={note.noteuuid}>
          <div className="notesDate">{date}</div>
          <TextareaAutosize
            className={classNames}
            data-testid="Notes"
            disabled={note.readonly}
            onBlur={event => {
              this.handleBlurNote(event, note.noteuuid, event.target.value);
            }}
            onChange={event => {
              this.handleChangeNote(note.noteuuid, event.target.value);
            }}
            onFocus={Helper.handleFocus}
            placeholder="Type notes here"
            maxLength={5000}
            value={note.note}
          />
        </React.Fragment>
      );
    });
    let loadmore = "";
    const moreToLoad = this.state.notes.filter(item => item.noteuuid !== "new").length < this.state.notesCount;
    if (moreToLoad) {
      loadmore = (
        <div data-testid="Show More Items" className="list-item selected cursorPointer" onClick={this.getNotes}>
          Show more items
        </div>
      );
    }
    return (
      <div className="notesTab openFolder" data-testid="Folder Notes Container">
        {notes}
        {loadmore}
      </div>
    );
  };

  renderPrintedNotes = () => {
    return (
      <div className="printedNotesTab openFolder">
        <TextareaAutosize
          id="notes"
          data-testid="Printed Notes"
          name="notes"
          className="notesValue"
          onBlur={this.props.handleBlurPrintedNotes}
          onChange={this.props.handleChangePrintedNotes}
          onFocus={Helper.handleFocus}
          placeholder="Type notes here"
          maxLength={5000}
          value={this.props.printedNotes}
        />
      </div>
    );
  };

  renderDetailsTab = () => {
    // TODO: Do we need this tab when products have notes.
    if (Helper.inList([Constants.PRODUCT], this.props.appState.currentView)) {
      return <span className={this.getFolderClassName("details")}>Details</span>;
    } else {
      return "";
    }
  };

  renderLinkedItemsTab = () => {
    // Never show the Linked Items tab for External Online orders
    if (Constants.ONLINE_ORDER_STATUSES.includes(this.props.order?.orderstatus)) {
      return "";
    }

    if (
      this.props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
      Helper.isOrderView(this.props.appState.currentView) &&
      this.props.appState.currentView !== Constants.PAY
    ) {
      return (
        <span
          data-testid="Linked Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_LINKED_ITEMS);
          }}
          className={this.getFolderClassName(Constants.FOLDER_LINKED_ITEMS)}
        >
          Linked Items
        </span>
      );
    } else {
      return "";
    }
  };

  renderInvoicesTab = () => {
    if (
      this.props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab !== Constants.TAB_PROSPECTS
    ) {
      return (
        <span
          data-testid="Invoices Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_INVOICES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_INVOICES)}
        >
          Invoices
        </span>
      );
    } else {
      return "";
    }
  };

  renderUnpaidInvoicesTab = () => {
    // Never show the Unpaid tab for External Online orders
    if (Constants.ONLINE_ORDER_STATUSES.includes(this.props.order?.orderstatus)) {
      return "";
    }
    // Never show for external customers (online orders)
    if (Constants.CUSTOMER_COMPANY_TYPES_EXTERNAL.includes(this.props.company?.companytype)) {
      return "";
    }
    // Do not show for prospects
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return "";
    }
    if (
      this.props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
      Helper.inList([Constants.CUSTOMER, Constants.INVOICE, Constants.REPAIR, Constants.QUOTE, Constants.ORDER], this.props.appState.currentView) &&
      !this.props.isProtectedCustomer
    ) {
      let count = "";
      if (this.props.company && this.props.company.unpaid) {
        count = " (" + this.props.company.unpaid + ")";
      }
      return (
        <span
          data-testid="Unpaid Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_UNPAID_INVOICES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_UNPAID_INVOICES)}
        >
          Unpaid {count}
        </span>
      );
    } else {
      return "";
    }
  };

  renderOrdersTab = () => {
    // Never show for external customers (online orders)
    if (Constants.CUSTOMER_COMPANY_TYPES_EXTERNAL.includes(this.props.company?.companytype)) {
      return "";
    }
    // Do not show for prospects
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return "";
    }
    //Orders for a Product will be done via reports
    //Orders for the customer.
    if (this.props.appState.features?.includes(Constants.FEATURE_ORDERS) && Helper.inList([Constants.CUSTOMER], this.props.appState.currentView)) {
      return (
        <span
          data-testid="Orders Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_ORDERS);
          }}
          className={this.getFolderClassName(Constants.FOLDER_ORDERS)}
        >
          Orders
        </span>
      );
    } else {
      return "";
    }
  };

  renderQuotesTab = () => {
    // Never show for external customers (online orders)
    if (Constants.CUSTOMER_COMPANY_TYPES_EXTERNAL.includes(this.props.company?.companytype)) {
      return "";
    }
    // Do not show for prospects
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return "";
    }
    //Quotes for a Product will be done via reports
    //Quotes for the customer.
    if (this.props.appState.features?.includes(Constants.FEATURE_QUOTES) && Helper.inList([Constants.CUSTOMER], this.props.appState.currentView)) {
      return (
        <span
          data-testid="Quotes Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_QUOTES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_QUOTES)}
        >
          Quotes
        </span>
      );
    } else {
      return "";
    }
  };

  renderRepairsTab = () => {
    // Never show for external customers (online orders)
    if (Constants.CUSTOMER_COMPANY_TYPES_EXTERNAL.includes(this.props.company?.companytype)) {
      return "";
    }
    // Do not show for prospects
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return "";
    }
    //Service Orders (Repairs) for a Product will be done via reports
    //Service Orders (Repairs) for the customer.
    if (this.props.appState.features?.includes(Constants.FEATURE_REPAIRS) && Helper.inList([Constants.CUSTOMER], this.props.appState.currentView)) {
      return (
        <span
          data-testid="Service Folder"
          className={this.getFolderClassName(Constants.FOLDER_REPAIRS)}
          onClick={() => {
            this.handleClick(Constants.FOLDER_REPAIRS);
          }}
        >
          Service
        </span>
      );
    } else {
      return "";
    }
  };

  renderPurchasesTab = () => {
    if (this.props.appState.features?.includes(Constants.FEATURE_PURCHASES) && Helper.inList([Constants.SUPPLIER], this.props.appState.currentView)) {
      return (
        <span
          data-testid="Purchases Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_PURCHASES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_PURCHASES)}
        >
          Purchases
        </span>
      );
    } else {
      return "";
    }
  };

  renderEmailTab = () => {
    // Emails will display on purchases for that supplier
    if (
      this.props.appState.features?.includes(Constants.FEATURE_EMAIL) &&
      Constants.EMAIL_VIEWS.includes(this.props.appState.currentView) &&
      !this.props.isProtectedCustomer
    ) {
      return (
        <span
          data-testid="Emails Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_EMAIL);
          }}
          className={this.getFolderClassName(Constants.FOLDER_EMAIL)}
        >
          Emails
        </span>
      );
    } else {
      return "";
    }
  };

  renderTextTab = () => {
    // Texts will display on purchases for that supplier
    if (this.props.appState.features?.includes(Constants.FEATURE_TEXT) && Constants.TEXT_VIEWS.includes(this.props.appState.currentView)) {
      return (
        <span
          data-testid="Texts Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_TEXT);
          }}
          className={this.getFolderClassName(Constants.FOLDER_TEXT)}
        >
          Texts
        </span>
      );
    } else {
      return "";
    }
  };

  renderPhotosTab = () => {
    if (this.props.appState.features?.includes(Constants.FEATURE_PRODUCTS) && this.props.appState.currentView === Constants.PRODUCT) {
      return (
        <span
          data-testid="Photos Folder"
          onClick={() => {
            this.handleClick("photos");
          }}
          className={this.getFolderClassName("photos")}
        >
          Photos
        </span>
      );
    } else {
      return "";
    }
  };

  renderShippingTab = () => {
    if ([Constants.PRODUCT, Constants.INVOICE].includes(this.props.appState.currentView)) {
      return (
        <span
          data-testid="Shipping Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_SHIPPING);
          }}
          className={this.getFolderClassName(Constants.FOLDER_SHIPPING)}
        >
          Shipping
        </span>
      );
    }
  };

  renderAgreementsTab = () => {
    if (Constants.CUSTOMER === this.props.appState.currentView && this.props.filtertype?.tab !== Constants.TAB_PROSPECTS) {
      return (
        <span
          data-testid="Agreements Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_AGREEMENTS);
          }}
          className={this.getFolderClassName(Constants.FOLDER_AGREEMENTS)}
        >
          Agreements
        </span>
      );
    }
  };

  renderAuthorizationsTab = () => {
    if (Constants.AUTHORIZATION_VIEWS.includes(this.props.appState.currentView)) {
      return (
        <span
          data-testid="Authorizations Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_AUTHORIZATIONS);
          }}
          className={this.getFolderClassName(Constants.FOLDER_AUTHORIZATIONS)}
        >
          Authorizations
        </span>
      );
    }
  };

  renderSubsciptionsTab = () => {
    const paymentGateway = this.props.appState.clientSettings?.PAYMENT_GATEWAY;
    const isenabledMaast = paymentGateway === Constants.MAAST && this.props.appState.maast.merchant_id;
    const isenabledNMI = paymentGateway === Constants.NMI && this.props.appState.nmi?.collectApiKey;

    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab !== Constants.TAB_PROSPECTS &&
      (isenabledMaast || isenabledNMI)
    ) {
      return (
        <span
          data-testid="Subscriptions Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_SUBSCRIPTIONS);
          }}
          className={this.getFolderClassName(Constants.FOLDER_SUBSCRIPTIONS)}
        >
          Subscriptions
        </span>
      );
    } else {
      return "";
    }
  };

  renderSubscriptionInvoicesTab = () => {
    if (this.props.appState.currentView === Constants.RECURRING) {
      return (
        <span
          data-testid="Subscription Invoices Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_SUBSCRIPTION_INVOICES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_SUBSCRIPTION_INVOICES)}
        >
          Invoices
        </span>
      );
    } else {
      return "";
    }
  };

  renderMaastCardVaultTab = () => {
    // Never show for external customers (online orders)
    if (Constants.CUSTOMER_COMPANY_TYPES_EXTERNAL.includes(this.props.company?.companytype)) {
      return "";
    }
    // Do not show for prospects
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return "";
    }
    const paymentGateway = this.props.appState.clientSettings?.PAYMENT_GATEWAY;
    // Card vault only shows on the customer page under certain conditions
    const isenabledMaast = paymentGateway === Constants.MAAST && this.props.appState.maast.merchant_id;
    const isenabledNMI = paymentGateway === Constants.NMI && this.props.appState.nmi?.collectApiKey;
    if (
      Helper.isBillingFeatureEnabled(this.props.appState.features) &&
      (isenabledMaast || isenabledNMI) &&
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.appState.usertype >= Constants.USER_TYPE_MANAGER
    ) {
      return (
        <span
          data-testid="Card Vault Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_MAAST_CARDS);
          }}
          className={this.getFolderClassName(Constants.FOLDER_MAAST_CARDS)}
        >
          Card Vault
        </span>
      );
    } else {
      return "";
    }
  };

  renderMaastTransactionsTab = () => {
    // Transactions from Maast API only for Billing
    // REVISIT: Remove when Maast is fully retired
    if (this.props.appState.features?.includes(Constants.FEATURE_BILLING) && this.props.appState.currentView === Constants.BILLING) {
      return (
        <span
          data-testid="Maast Transactions Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_MAAST_TRANSACTIONS);
          }}
          className={this.getFolderClassName(Constants.FOLDER_MAAST_TRANSACTIONS)}
        >
          Transactions
        </span>
      );
    } else {
      return "";
    }
  };

  renderMaastInvoicesTab = () => {
    // Transactions from Maast API only for Billing
    // REVISIT: Remove when Maast is fully retired
    if (this.props.appState.features?.includes(Constants.FEATURE_BILLING) && this.props.appState.currentView === Constants.BILLING) {
      return (
        <span
          data-testid="Maast Invoices Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_MAAST_INVOICES);
          }}
          className={this.getFolderClassName(Constants.FOLDER_MAAST_INVOICES)}
        >
          Invoices
        </span>
      );
    } else {
      return "";
    }
  };

  renderCustomFieldsTab = () => {
    // Never show for external customers (online orders)
    if (Constants.CUSTOMER_COMPANY_TYPES_EXTERNAL.includes(this.props.company?.companytype)) {
      return "";
    }
    // Do not show for prospects
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return "";
    }
    // Do not show for Maast-only customers
    if (this.props.isMaastOnlyCustomer()) {
      return "";
    }
    // Custom fields from Maast API
    if (
      Helper.isBillingFeatureEnabled(this.props.appState.features) &&
      [Constants.BILLING, Constants.CUSTOMER, Constants.RECURRING].includes(this.props.appState.currentView)
    ) {
      return (
        <span
          data-testid="Custom Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_MAAST_CUSTOM);
          }}
          className={this.getFolderClassName(Constants.FOLDER_MAAST_CUSTOM)}
        >
          Custom
        </span>
      );
    } else {
      return "";
    }
  };

  renderReverbTab = () => {
    // Fields specific to Reverb on the Products page
    if (this.props.appState.features?.includes(Constants.FEATURE_REVERB) && this.props.appState.currentView === Constants.PRODUCT) {
      return (
        <span
          data-testid="Reverb Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_REVERB);
          }}
          className={this.getFolderClassName(Constants.FOLDER_REVERB)}
        >
          Reverb
        </span>
      );
    } else {
      return "";
    }
  };

  renderCartloomTab = () => {
    // Fields specific to Reverb on the Products page
    if (
      this.props.appState.features?.includes(Constants.FEATURE_CARTLOOM) &&
      this.props.appState.currentView === Constants.PRODUCT &&
      this.props.appState?.thirdparty?.cartloomsellerid
    ) {
      return (
        <span
          data-testid="Cartloom Folder"
          onClick={() => {
            this.handleClick(Constants.FOLDER_CARTLOOM);
          }}
          className={this.getFolderClassName(Constants.FOLDER_CARTLOOM)}
        >
          Cartloom
        </span>
      );
    } else {
      return "";
    }
  };

  renderOrderList = () => {
    if (this.state.orders === undefined || this.state.orders === null) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder " data-testid="Order List loading items">
            Loading...
            <br />
            <br />
          </div>
        </div>
      );
    } else if (this.state.orders.length === 0) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder " data-testid="Order List No items">
            No items found.
            <br />
            <br />
          </div>
        </div>
      );
    }
    let productLabel = "Product";
    let poLabel = "PO Number";
    if (this.state.folderType === Constants.FOLDER_REPAIRS) {
      productLabel = "Model";
    } else if (this.state.folderType === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
      productLabel = "Subscription";
      poLabel = "#";
    }
    let headers = [
      { classes: "header", columnheading: "Date" },
      { classes: "header", columnheading: "Invoice" },
      { classes: "header", columnheading: "Name" },
      { classes: "header", columnheading: poLabel },
      { classes: "header", columnheading: productLabel },
      { classes: "header right-aligned", columnheading: "Total" },
      {
        classes: "header right-aligned",
        columnheading: [Constants.FOLDER_INVOICES, Constants.FOLDER_UNPAID_INVOICES, Constants.FOLDER_SUBSCRIPTION_INVOICES].includes(
          this.state.folderType
        )
          ? "Bal. Due"
          : "",
      },
      { classes: "header centerAligned", columnheading: "Status" },
    ];
    // Add a column for the authorizations list, if this is the subscription invoices folder
    if (this.state.folderType === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
      // Insert the authorizations column after the Total column
      const authColumn = [
        {
          classes: "header",
          columnheading: "Authorizations",
          eyeball: true,
          eyeballSlashed: this.state.hideAuthorizations,
          handleToggleEyeball: this.handleToggleHideAuthorizations,
          eyeballTitle: "Click to " + (this.state.hideAuthorizations ? "hide" : "show") + " additional authorizations",
        },
      ];
      headers = headers.slice(0, 6).concat(authColumn).concat(headers.slice(6));
    }
    let loadmore = "";
    const moreToLoad = this.state.orders.length < this.state.ordersCount;
    if (moreToLoad) {
      loadmore = (
        <div data-testid="Show More Items" className="list-item selected cursorPointer span2" onClick={this.getOrders}>
          Show more items
        </div>
      );
    }
    let classNames = "folderListItems openFolder";
    if (this.state.folderType === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
      classNames += " subscriptionInvoices";
    }
    return (
      <div className="folderList">
        <div className={classNames} data-testid={"Folder " + this.state.folderType + " Container"}>
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.state.orders}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderOrderItemsToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={this.handleEditItem}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
          {loadmore}
        </div>
      </div>
    );
  };

  renderEmailList = () => {
    if (!this.state?.emails) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder">
            Loading messages... <FontAwesomeIcon icon={faSpinner} spin />
            <br />
            <br />
          </div>
        </div>
      );
    } else if (this.state.emails.length === 0) {
      return (
        <div className="folderList">
          <div data-testid="No messages found" className="folderListItems openFolder">
            No messages found.
            <br />
            <br />
          </div>
        </div>
      );
    }
    const headers = [
      { classes: "header", columnheading: "Date" },
      { classes: "header", columnheading: "Subject" },
      { classes: "header", columnheading: "Message" },
    ];
    let loadmore = "";
    // const moreToLoad = this.state.orders.length < this.state.ordersCount;
    // if (moreToLoad) {
    //   loadmore = (
    //     <div className="list-item selected cursorPointer" onClick={this.getOrders}>
    //       Show more items
    //     </div>
    //   );
    // }
    return (
      <div className="folderMessageList">
        <div className="folderMessageListItems openFolder">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.state.emails}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderMessageItemsToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={Helper.handleViewMessage}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
          {loadmore}
        </div>
      </div>
    );
  };

  renderShipping = () => {
    if (this.props.appState.currentView === Constants.PRODUCT) {
      return this.renderShippingProduct();
    } else if (this.props.appState.currentView === Constants.INVOICE) {
      return this.renderShippingInvoice();
    } else {
      return "";
    }
  };

  renderSubscriptions = () => {
    if (this.state.subscriptions === undefined || this.state.subscriptions === null) {
      return (
        <div className="folderList">
          <div data-testid="No subscriptions found" className="folderListItems openFolder">
            Loading...
            <br />
            <br />
          </div>
        </div>
      );
    } else if (this.state.subscriptions?.length === 0) {
      return (
        <div className="folderList">
          <div data-testid="No subscriptions found" className="folderListItems openFolder">
            No subscriptions found.
            <br />
            <br />
          </div>
        </div>
      );
    }
    const headers = [
      { classes: "header desktop centerAligned firstLeft", columnheading: "#" },
      { classes: "header desktop", columnheading: "Subscription" },
      { classes: "header desktop", columnheading: "Frequency" },
      { classes: "header desktop right-aligned", columnheading: "Amount" },
      { classes: "header desktop right-aligned", columnheading: "Overdue" },
      { classes: "header desktop centerAligned", columnheading: "Start Date" },
      { classes: "header desktop centerAligned", columnheading: "Next Bill" },
      { classes: "header desktop centerAligned", columnheading: "Expiration" },
      {
        classes: "header desktop centerAligned",
        columnheading: "Status",
        eyeball: true,
        handleToggleEyeball: this.handleToggleHideInactive,
        eyeballSlashed: this.state.hideInactiveSubscriptions,
        eyeballTitle: "Click to " + (this.state.hideInactiveSubscriptions ? "show" : "hide") + " inactive subscriptions",
      },
      { classes: "header desktop", columnheading: "Payment" },
      { classes: "header desktop lastRight", columnheading: "" },
    ];
    let subscriptions = this.state.subscriptions.filter(subscription => {
      return this.state.hideInactiveSubscriptions ? Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(subscription.status) : true;
    });
    return (
      <div className="folderList">
        <div className="folderListItems openFolder folderSubscriptionItems">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={subscriptions}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderSubscriptionToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={this.handleEditItem}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
        </div>
      </div>
    );
  };

  renderSubscriptionToColumns = (subscription, index) => {
    return Helper.renderSubscriptionToColumnsReadonly(
      subscription,
      index,
      false,
      () => {},
      () => {},
      () => {},
      this.props.appState,
      this.state.maastCustomer,
      this.state.subscriptions,
      () => {},
      this.props.isMaastOnlyCustomer,
      this.props.handleExpandSubscription,
      false,
      false
    );
  };

  renderAuthorizations = () => {
    if (!this.props?.order?.authorizations?.length > 0) {
      return (
        <div className="folderList">
          <div data-testid="No authorizations found" className="folderListItems openFolder">
            No credit payment authorizations found.
            <br />
            <br />
          </div>
        </div>
      );
    }
    const headers = [
      { classes: "header", columnheading: "Created" },
      { classes: "header", columnheading: "Source" },
      { classes: "header", columnheading: "Terminal" },
      { classes: "header", columnheading: "Code" },
      { classes: "header", columnheading: "Message" },
      { classes: "header", columnheading: "Auth Code" },
      { classes: "header", columnheading: "Amount" },
      { classes: "header", columnheading: "Card" },
      { classes: "header", columnheading: "Updated" },
      { classes: "header centerAligned", columnheading: "Status" },
      { classes: "header", columnheading: "" },
    ];
    return (
      <div className="folderList">
        <div className="folderAuthorizationItems openFolder">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.props.order?.authorizations}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderAuthorizationsToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={() => {}}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
        </div>
      </div>
    );
  };

  renderAuthorizationsToColumns = item => {
    if (item?.gateway === Constants.NMI) {
      return this.renderAuthorizationsToColumnsNMI(item);
    } else {
      return this.renderAuthorizationsToColumnsHandpointMaast(item);
    }
  };

  renderAuthorizationsToColumnsNMI = item => {
    const response = JSON.parse(item.response || "{}");
    /**
    {
        authcode: "123456",
        avsresponse: "N",
        cvvresponse: "M",
        orderid: "cd265d2f83a9b9106736c8e25e80",
        response: "1",
        response_code: "100",
        responsetext: "SUCCESS",
        transactionid: "10334844175",
        type: "sale",
    }
    **/

    let message = <span data-testid="Authorization Response Message">{response.responsetext || response.action?.response_text}</span>;
    let code = response.response_code || response.action?.response_code || "-";
    if (item.status === Constants.AUTH_CANCELLED) {
      message = (
        <span data-testid="Authorization Response Message" className="italic">
          User cancelled
        </span>
      );
    } else if (item.status === Constants.AUTH_PENDING) {
      message = (
        <span data-testid="Authorization Response Message" className="italic">
          Pending{" "}
          <Tooltip
            explanation={true}
            text='This authorization is pending. The status will be updated when the authorization is completed. Do not attempt additional credit payments until resolved. You may click the "Pending" status to retrieve the latest status.'
          />
        </span>
      );
    } else if (item.status === Constants.AUTH_FAILED) {
      message = (
        <span data-testid="Authorization Response Message" className="italic">
          Request timed out
        </span>
      );
    }

    let cc = response.tokenization_response?.maskedcardnumber || response.masked_card || response.cc_number || "-";
    if (cc.length > 5) {
      cc = cc.slice(-5);
      cc = cc.replace(/\D/g, "*");
    }

    let sn = item.serialnumber;
    if (item.terminaltype === Constants.MANUAL) {
      sn = `${item.gateway} ${item.serialnumber}`;
    }

    const creationdatetime = <span data-testid="Authorization Creation Date Time">{Helper.formatDate(item.creationdatetime, false, true)}</span>;
    const serialnumber = <span data-testid="Authorization Serial Number">{sn}</span>;
    const terminaltype = <span data-testid="Authorization Terminal Type">{item.terminaltype}</span>;
    const rcode = <span data-testid="Authorization Response Code">{code}</span>;
    const authCode = <span data-testid="Authorization Response Message Code">{response.authcode || response.authorization_code || "-"}</span>;
    const amount = <span data-testid="Authorization Amount">{numeral(item.amount).format(Constants.CURRENCY)}</span>;
    const cardNumber = <span data-testid="Authorization Card Number">{cc}</span>;
    const lastupdated = <span data-testid="Authorization Last Updated">{Helper.formatDate(item.lastupdated, false, true)}</span>;
    const status = (
      <span
        data-testid="Authorization Status"
        onClick={() => {
          this.props.handleRetrieveResult(item.status, item.authuuid);
        }}
      >
        {Helper.renderStatus(item.status)}
      </span>
    );

    return [
      { rowvalue: creationdatetime },
      { rowvalue: serialnumber },
      { rowvalue: terminaltype },
      { rowvalue: rcode, classes: "centerAligned" },
      { rowvalue: message },
      { rowvalue: authCode },
      { rowvalue: amount, classes: "right-aligned" },
      { rowvalue: cardNumber },
      { rowvalue: lastupdated },
      { rowvalue: status },
      { rowvalue: "" },
    ];
  };

  renderAuthorizationsToColumnsHandpointMaast = item => {
    const response = JSON.parse(item.response || "{}");

    let message = <span data-testid="Authorization Response Message">{response.rmsg || response.statusMessage}</span>;
    let code = response.rcode || response.issuerResponseCode || "-";
    if (item.status === Constants.AUTH_CANCELLED) {
      message = (
        <span data-testid="Authorization Response Message" className="italic">
          {response.statusMessage || "User cancelled"}
        </span>
      );
    } else if (item.status === Constants.AUTH_PENDING) {
      message = (
        <span data-testid="Authorization Response Message" className="italic">
          Pending{" "}
          <Tooltip
            explanation={true}
            text='This authorization is pending. The status will be updated when the authorization is completed. Do not attempt additional credit payments until resolved. You may click the "Pending" status to retrieve the latest status.'
          />
        </span>
      );
    } else if (item.status === Constants.AUTH_FAILED) {
      message = (
        <span data-testid="Authorization Response Message" className="italic">
          Request timed out
        </span>
      );
    }

    let cc = response.maskedCardNumber || response.tokenization_response?.card_number || "-";
    if (cc.length > 5) {
      cc = cc.slice(-5);
      cc = cc.replace(/\D/g, "*");
    }

    let sn = item.serialnumber;
    if (item.terminaltype === Constants.MANUAL) {
      sn = `${item.gateway} ${item.serialnumber}`;
    }

    const creationdatetime = <span data-testid="Authorization Creation Date Time">{Helper.formatDate(item.creationdatetime, false, true)}</span>;
    const serialnumber = <span data-testid="Authorization Serial Number">{sn}</span>;
    const terminaltype = <span data-testid="Authorization Terminal Type">{item.terminaltype}</span>;
    const rcode = <span data-testid="Authorization Response Code">{code}</span>;
    const authCode = <span data-testid="Authorization Response Message Code">{response.auth_code || response.authorisationCode || "-"}</span>;
    const amount = <span data-testid="Authorization Amount">{numeral(item.amount).format(Constants.CURRENCY)}</span>;
    const cardNumber = <span data-testid="Authorization Card Number">{cc}</span>;
    const lastupdated = <span data-testid="Authorization Last Updated">{Helper.formatDate(item.lastupdated, false, true)}</span>;
    const status = (
      <span
        data-testid="Authorization Status"
        onClick={() => {
          this.props.handleRetrieveResult(item.status, item.authuuid);
        }}
      >
        {Helper.renderStatus(item.status)}
      </span>
    );

    return [
      { rowvalue: creationdatetime },
      { rowvalue: serialnumber },
      { rowvalue: terminaltype },
      { rowvalue: rcode, classes: "centerAligned" },
      { rowvalue: message },
      { rowvalue: authCode },
      { rowvalue: amount, classes: "right-aligned" },
      { rowvalue: cardNumber },
      { rowvalue: lastupdated },
      { rowvalue: status },
      { rowvalue: "" },
    ];
  };

  renderAgreements = () => {
    if (this.props.company?.agreements?.length > 0) {
      const headers = [
        { classes: "header", columnheading: "Campaign Name" },
        { classes: "header", columnheading: "Name" },
        { classes: "header", columnheading: "Created Date" },
        { classes: "header", columnheading: "Updated Date" },
        { classes: "header", columnheading: "Agreement Date" },
        { classes: "header", columnheading: "" },
      ];
      let loadmore = "";
      return (
        <div className="folderList">
          <div className="folderAgreementItems openFolder">
            <ListHeaderView headerRowItems={headers} />
            <ListItemView
              listitems={this.props.company?.agreements}
              expandedListItems={null}
              selectedListItems={[]}
              renderItemToColumns={this.renderAgreementsToColumns}
              toggleCollapsed={() => {}}
              selectListItem={() => {}}
              handleEditItem={() => {}}
              handleTouchStart={() => {}}
              handleTouchEnd={() => {}}
            />
            {loadmore}
          </div>
        </div>
      );
    } else {
      return (
        <div className="folderList">
          <div data-testid="No agreements found" className="folderListItems openFolder">
            No agreements found.
            <br />
            <br />
          </div>
        </div>
      );
    }
  };

  renderProspectMatches = () => {
    if (!this.state?.matches) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder">
            Calculating possible matches... <FontAwesomeIcon icon={faSpinner} spin />
            <br />
            <br />
          </div>
        </div>
      );
    } else if (this.state.matches.length === 0) {
      return (
        <div className="folderList">
          <div data-testid="No messages found" className="folderListItems openFolder">
            No matches found.
            <br />
            <br />
          </div>
        </div>
      );
    }
    const headers = [
      { classes: "header", columnheading: "First Name" },
      { classes: "header", columnheading: "Last Name" },
      { classes: "header", columnheading: "Email" },
      { classes: "header", columnheading: "Mobile Phone" },
      { classes: "header", columnheading: "Other Phone" },
      { classes: "header", columnheading: "Address" },
      { classes: "header", columnheading: "Similarity Score" },
      { classes: "header", columnheading: "" },
    ];
    let loadmore = "";
    return (
      <div className="folderList">
        <div className="folderMatchListItems openFolder">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.state.matches}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderProspectMatchesToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={() => {}}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
          {loadmore}
        </div>
      </div>
    );
  };

  renderShippingProduct = () => {
    if (!this.props.product) {
      return (
        <div className="folderList">
          <div className="folderShippingItems openFolder ">Loading product...</div>
        </div>
      );
    }
    return (
      <div className="folderList">
        <div className="folderShippingItems openFolder">{this.renderProductDimensionsProduct()}</div>
      </div>
    );
  };

  renderShippingInvoice = () => {
    if (!this.props.order) {
      return (
        <div className="folderList">
          <div className="folderShippingItems openFolder">Loading invoice...</div>
        </div>
      );
    }
    return (
      <div className="folderList">
        <div className="folderShippingItems openFolder">
          {this.renderProductDimensionsInvoice()}
          {this.renderTracking()}
          {/* {this.renderProductPostage()} */}
        </div>
      </div>
    );
  };

  renderProductDimensionsProduct = () => {
    return (
      <div className="shippingDimensions marginBottom1em">
        <h3 className="product-header span3">Product Dimensions</h3>
        <label htmlFor="length" className="shippinglabel">
          Length
        </label>
        <div className="areaInputItem">
          <input
            type="text"
            name="length"
            id="length"
            data-testid="Length in inches"
            autoComplete="off"
            placeholder="Length in inches"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.props.handleChange(event, Constants.PRODUCT, this.props.product?.productuuid);
            }}
            onBlur={event => {
              this.props.handleBlur(event, Constants.PRODUCT, this.props.product?.productuuid, null, "float");
            }}
            value={this.props.product?.length ?? ""}
          />
        </div>
        <div className="shippinglabel">in.</div>
        <label htmlFor="width" className="shippinglabel">
          Width
        </label>
        <div className="areaInputItem">
          <input
            type="text"
            name="width"
            id="width"
            data-testid="Width in inches"
            autoComplete="off"
            placeholder="Width in inches"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.props.handleChange(event, Constants.PRODUCT, this.props.product?.productuuid);
            }}
            onBlur={event => {
              this.props.handleBlur(event, Constants.PRODUCT, this.props.product?.productuuid, null, "float");
            }}
            value={this.props.product?.width ?? ""}
          />
        </div>
        <div className="shippinglabel">in.</div>
        <label htmlFor="height" className="shippinglabel">
          Height
        </label>
        <div className="areaInputItem">
          <input
            type="text"
            name="height"
            id="height"
            data-testid="Height in inches"
            autoComplete="off"
            placeholder="Height in inches"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.props.handleChange(event, Constants.PRODUCT, this.props.product?.productuuid);
            }}
            onBlur={event => {
              this.props.handleBlur(event, Constants.PRODUCT, this.props.product?.productuuid, null, "float");
            }}
            value={this.props.product?.height ?? ""}
          />
        </div>
        <div className="shippinglabel">in.</div>
        <label htmlFor="weight" className="shippinglabel">
          Weight
        </label>
        <div className="areaInputItem">
          <input
            type="text"
            name="weight"
            id="weight"
            data-testid="Weight in ounces"
            autoComplete="off"
            placeholder="Weight in ounces"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.props.handleChange(event, Constants.PRODUCT, this.props.product?.productuuid);
            }}
            onBlur={event => {
              this.props.handleBlur(event, Constants.PRODUCT, this.props.product?.productuuid, null, "float");
            }}
            value={this.props.product?.weight ?? ""}
          />
        </div>
        <div className="shippinglabel">oz.</div>
      </div>
    );
  };

  renderProductDimensionsInvoice = () => {
    const noWeight = this.props.order?.orderitems?.find(item => {
      return item.weight === "" || item.weight === null || item.weight === undefined;
    });
    const weightWarning = noWeight ? (
      <span className="highlight" title="One or more products do not have a weight value">
        <FontAwesomeIcon icon={faTriangleExclamation} />
      </span>
    ) : (
      ""
    );

    let weight = this.props.order?.orderitems?.reduce((acc, item) => {
      // Replace non-digits with empty string
      const wt = numeral(item.weight).value();
      const qty = numeral(item.quantity).value();
      return acc + wt * qty;
    }, 0);
    weight = numeral(weight).format(Constants.DECIMAL_VALUE);
    return (
      <div className="shippingDimensions marginBottom1em">
        <h3 className="product-header span3">Combined Weight {weightWarning}</h3>
        <label data-testid="Shipping Label" htmlFor="weight" className="shippinglabel">
          Weight
        </label>
        <div className="areaInputItem">
          <input
            type="text"
            name="weight"
            id="weight"
            data-testid="Weight in ounces"
            autoComplete="off"
            placeholder="Weight in ounces"
            onFocus={() => {}}
            onChange={() => {}}
            onBlur={() => {}}
            value={weight}
          />
        </div>
        <div className="shippinglabel">oz.</div>
      </div>
    );
  };

  renderTracking = () => {
    return (
      <div className="trackingInformation">
        <h3 className="product-header span2">Tracking Information</h3>
        <label data-testid="Tracking Number Label" htmlFor="trackingnumber" className="trackingnbrlabel">
          Tracking number(s) &nbsp;{" "}
          <span className="trackShipment" onClick={this.props.handleTrackShipment}>
            <FontAwesomeIcon icon={faTruck} />
          </span>
        </label>
        <div className="areaInputItem">
          <TextareaAutosize
            className="trackingnumber"
            name="trackingnumber"
            id="trackingnumber"
            data-testid="Tracking Number"
            autoComplete="off"
            maxLength={500}
            onFocus={Helper.handleFocus}
            onChange={event => this.props.handleChange(event, Constants.ORDER)}
            onBlur={event => this.props.handleBlur(event, Constants.ORDER, this.props.order?.orderuuid)}
            value={this.props.order?.trackingnumber}
          />
        </div>
      </div>
    );
  };

  renderProductPostage = () => {
    let address = "";
    if (
      this.props.order?.company?.contacts?.length > 0 &&
      this.props.order?.company?.contacts[0]?.address1 &&
      this.props.order?.company?.contacts[0]?.city &&
      this.props.order?.company?.contacts[0]?.state &&
      this.props.order?.company?.contacts[0]?.postalcode
    ) {
      address =
        this.props.order?.company?.contacts[0].address1 +
        ", " +
        this.props.order?.company?.contacts[0].city +
        ", " +
        this.props.order?.company?.contacts[0].state +
        " " +
        this.props.order?.company?.contacts[0].postalcode;
    }
    return (
      <div className="trackingInformation">
        <h3 className="product-header span2">Postage</h3>
        <div>Shipping address {address}</div>
      </div>
    );
  };

  renderTextList = () => {
    if (this.props.appState?.twilio?.phonenumber?.length === 0) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder" data-testid="Texts Not Setup">
            Text messaging is not setup. Contact tech support for assistance.
            <br />
            <br />
          </div>
        </div>
      );
    } else if (!this.state.smscontacts) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder">
            Loading messages... <FontAwesomeIcon icon={faSpinner} spin />
            <br />
            <br />
          </div>
        </div>
      );
    } else if (this.state.smscontacts && this.state.smscontacts.length === 0) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder">
            No messages found.
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      const smscontacts = this.state.smscontacts.map(contact => {
        const lastmessagedatetime = Helper.formatDate(contact.lastmessagedatetime, false);
        let classes = "list-item";
        if (contact.uuid === this.state.selectedsmscontact?.uuid) {
          classes += " selected";
        }
        return (
          <React.Fragment key={contact.uuid}>
            <div
              className={classes}
              onClick={() => {
                this.handleSelectSmsContact(contact.uuid);
              }}
            >
              {lastmessagedatetime}
            </div>
            <div
              className={classes}
              onClick={() => {
                this.handleSelectSmsContact(contact.uuid);
              }}
            >
              {Helper.renderSmsContactName(contact)}
              <br />
              <span className="ghost">{contact.phonenumber}</span>
            </div>
          </React.Fragment>
        );
      });
      let smsmessages = (
        <TextsPanel
          selectedListItems={[this.state.selectedsmscontact]}
          downloadingTexts={false}
          newMessage={""}
          textPanelRef={this.textPanelRef}
          handleGetOlderTexts={this.handleGetOlderTexts}
          handleGetNewTexts={this.handleGetNewTexts}
          handleChangeNewMessage={() => {}}
          handleSendNewMessage={() => {}}
          showNewTextMessage={false}
        />
      );
      // }
      return (
        <div className="folderTextList">
          <div className="folderTextListItems openFolder">
            <div className="panel_left">{smscontacts}</div>
            <div className="panel_right">{smsmessages}</div>
          </div>
        </div>
      );
    }
  };

  renderAgreementsToColumns = item => {
    const campaignname = (
      <span title="View Campaign Definition">
        {item.campaignname}{" "}
        <span
          onClick={() => {
            this.props.handleViewCampaign({ campaignuuid: item.campaignuuid });
          }}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </span>
      </span>
    );
    const create = <span title="Creation Date Time">{Helper.formatDateTime(item.creationdatetime, false)}</span>;
    const agree = !item.agreedatetime ? (
      <span title="No specified agreement on Campaign">No Agreement specified</span>
    ) : (
      <span title="Agreement signed date time">{Helper.formatDateTime(item.agreedatetime, false)}</span>
    );
    const updated = <span title="Last Updated Date Time">{Helper.formatDateTime(item.lastupdated, false)}</span>;

    const name = (
      <span title="View Full Agreement">
        {item.firstname} {item.lastname}{" "}
        <span
          onClick={() => {
            this.props.handleViewProspect(item);
          }}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </span>
      </span>
    );

    return [{ rowvalue: campaignname }, { rowvalue: name }, { rowvalue: create }, { rowvalue: updated }, { rowvalue: agree }, { rowvalue: "" }];
  };

  renderProspectMatchesToColumns = item => {
    let email = <span title="Email">{item.email}</span>;
    let mobilephone = <span title="Mobile Phone">{item.mobilephone ? item.mobilephone : "-"}</span>;
    let otherphone = <span title="Other Phone">{item.otherphone ? item.otherphone : "-"}</span>;
    let firstname = <span title="First Name">{item.firstname}</span>;
    let lastname = <span title="Last Name">{item.lastname}</span>;
    let address1 = item.address1 ? item.address1 : "-";
    let address = (
      <div>
        <div title="Address 1">{address1}</div>
        <div title="Address 2">{item.address2}</div>
        <div title="City State Postalcode">
          {item.city} {item.state} {item.postalcode}
        </div>
      </div>
    );
    let score = <span title="Weighted % for matching attributes">{Helper.formatPercent(item.scorepercent)}</span>;
    let color = item.scorepercent < 0.75 ? "black" : "white";
    let style = { backgroundColor: `hsl(104, 29%, ${100 - 100 * item.scorepercent * 0.53}%)`, color: color };
    let match = (
      <span
        className="action-button "
        style={style}
        onClick={() => {
          this.handleMatch(this.props.company?.contacts[0].marketingprospectuuid, item.contactuuid);
        }}
      >
        Match
      </span>
    );

    return [
      { rowvalue: firstname },
      { rowvalue: lastname },
      { rowvalue: email },
      { rowvalue: mobilephone },
      { rowvalue: otherphone },
      { rowvalue: address },
      { rowvalue: score },
      { rowvalue: match },
    ];
  };

  renderMessageItemsToColumns = item => {
    let creationdatetime = <span data-testid="Creation">{Helper.formatDateTime(item.creationdatetime, false)}</span>;
    let subject = <span data-testid="subject">{item.subject}</span>;
    let message = <span data-testid="message">{he.decode(item.message_text)}</span>;

    return [
      { rowvalue: creationdatetime, classes: "noSelect" },
      { rowvalue: subject, classes: "noSelect" },
      { rowvalue: message, classes: "noSelect" },
    ];
  };

  renderLinkedList = () => {
    let items = this.props.order.linkeditems ?? [];
    if (this.props.order?.ordertype === Constants.PURCHASE) {
      items = this.getLinkedOrdersForOrderItems() ?? [];
    }
    // If no items to display (linked or purchase), then show "no items"
    if (items.length === 0) {
      return (
        <div className="folderList">
          <div className="folderListItems openFolder" data-testid="Linked List No items">
            No items found.
            <br />
            <br />
          </div>
        </div>
      );
    }
    items.sort((a, b) => a.creationdatetime.localeCompare(b.creationdatetime));
    let headers = [
      { classes: "header", columnheading: "Date" },
      { classes: "header", columnheading: "#" },
      { classes: "header", columnheading: "Name" },
      { classes: "header", columnheading: "PO/Tracking Number" },
      { classes: "header right-aligned", columnheading: "Order Total" },
      {
        classes: "header right-aligned",
        columnheading: [Constants.FOLDER_INVOICES, Constants.FOLDER_UNPAID_INVOICES].includes(this.state.folderType) ? "Bal. Due" : "",
      },
      { classes: "header centerAligned", columnheading: "Status" },
    ];

    return (
      <div className="folderList">
        <div className="linkedListItems">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={items}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderOrderItemsToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={this.handleEditItem}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
          <br />
        </div>
      </div>
    );
  };

  renderOrderItemsToColumns = item => {
    // Format the creation date with a test id
    const creationdate = <span data-testid="Folder Creation Date">{Helper.formatDate(item.creationdatetime, false)}</span>;
    // TODO: Remove external id from display
    const ordernumber = (
      <span data-testid="Folder Order Number">
        {Helper.getOrderNumber(item.ordertype, item.ordersubtype, item.ordernumber, item.externalid, { tab: "Invoices" })}
      </span>
    );

    //Contact name is the supplier company name for purchase orders
    let contactname = item.contactname;
    if (item.ordertype === Constants.PURCHASE) {
      contactname = item.companyname ?? "";
      if (item.contactname) {
        contactname += " - " + item.contactname;
      }
    }
    contactname = <span data-testid="Folder Contact Name">{contactname}</span>;

    // Purchase orders use the PO number, other order types use the tracking number
    let poNumber = item.ponumber ? item.ponumber : item.trackingnumber;
    poNumber = <span data-testid="Folder PO/Tracking">{poNumber}</span>;
    if (this.state.folderType === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
      poNumber = <span data-testid="Folder Subscription Number">{item.subscriptionnumber}</span>;
    }

    // Format the product name to include the model if the product name is not available
    let productname = item.productname ?? item.model;
    if (item.itemcount === 2) {
      productname += " (and 1 more item)";
    } else if (item.itemcount > 2) {
      productname += " (and " + (item.itemcount - 1) + " more items)";
    }
    // Format the product name with a test id
    productname = <span data-testid="Folder Product Name">{productname}</span>;

    // Format the total cost for purchase orders, or the total price + tax for other order types
    let totalAsNumeral =
      item.ordertype === Constants.PURCHASE ? numeral(item.totalcost ?? 0) : numeral(item.totalprice ?? 0).add(numeral(item.totaltax ?? 0).value());
    let total = <span data-testid="Folder Total">{totalAsNumeral.format(Constants.CURRENCY_WITH_SYMBOL)}</span>;

    const balanceDueAmount = Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(item.orderstatus)
      ? "-"
      : numeral(item.totalpriceplustax ?? 0)
          .subtract(item.totalpayments ?? 0)
          .format(Constants.CURRENCY_WITH_SYMBOL);
    const balanceDue = [Constants.FOLDER_INVOICES, Constants.FOLDER_UNPAID_INVOICES, Constants.FOLDER_SUBSCRIPTION_INVOICES].includes(
      this.state.folderType
    ) ? (
      <span data-testid="Folder Balance Due">{balanceDueAmount}</span>
    ) : (
      ""
    );

    //Format the status
    let status = <span data-testid="Folder Order Status">{Helper.renderStatus(item.orderstatus)}</span>;

    // If the folder type is linked items, then don't display the product name column
    if (this.state.folderType === Constants.FOLDER_LINKED_ITEMS) {
      return [
        { rowvalue: creationdate },
        { rowvalue: ordernumber },
        { rowvalue: contactname },
        { rowvalue: poNumber },
        { rowvalue: total, classes: "right-aligned" },
        { rowvalue: "" },
        { rowvalue: status },
      ];
    } else {
      let columns = [
        { rowvalue: creationdate },
        { rowvalue: ordernumber },
        { rowvalue: contactname },
        { rowvalue: poNumber },
        { rowvalue: productname },
        { rowvalue: total, classes: "right-aligned" },
        { rowvalue: balanceDue, classes: "right-aligned" },
        { rowvalue: status },
      ];
      if (this.state.folderType === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
        if (item.authorizations?.length > 0) {
          const auth = item.authorizations[0];
          const response = JSON.parse(auth.response || "{}");
          let rowvalue = "";
          // TODO: Remove Maast code
          const nmiResponseText = `${response.responsetext} ${response.authcode || ""}`.trim();
          let message = response.rmsg || response.statusMessage || nmiResponseText || auth.status;
          if (item.authorizations?.length > 1) {
            const text = "+" + (item.authorizations.length - 1) + " more";
            const tooltip = this.state.hideAuthorizations ? (
              <span className="highlight">
                <Tooltip explanation={true} text={text} explanationClass={"explanationNarrow"} />
              </span>
            ) : (
              ""
            );
            message = (
              <span>
                {message} {tooltip}
              </span>
            );
          }
          rowvalue = message;
          const row = { rowvalue: rowvalue };
          // Insert the auth status column before the status column
          columns.splice(columns.length - 2, 0, row);
          // If showing all auths, then add a row for each auth
          if (!this.state.hideAuthorizations && item.authorizations?.length > 1) {
            for (let i = 1; i < item.authorizations.length; i++) {
              const auth = item.authorizations[i];
              const response = JSON.parse(auth.response || "{}");
              const nmiResponseText = `${response.responsetext} ${response.authcode || ""}`.trim();
              columns.push({ rowvalue: "", classes: "span6" });
              columns.push({ rowvalue: response.rmsg || response.statusMessage || nmiResponseText || auth.status });
              columns.push({ rowvalue: "" });
              columns.push({ rowvalue: "" });
            }
          }
        } else {
          const row = { rowvalue: "-" };
          columns.splice(columns.length - 2, 0, row);
        }
      }
      return columns;
    }
  };

  // TODO: Rewrite this to accommodate the new linkeditems structure
  getLinkedOrdersForOrderItems = () => {
    let linkedOrders = [];
    if (this.props.order?.orderitems?.length) {
      // Loop through each order item
      this.props.order.orderitems.forEach(orderitem => {
        // Loop through each parent
        orderitem.parent_data.forEach(parent => {
          const orderuuid = parent.orderuuid;
          // Check if the order is already in the list
          if (linkedOrders.filter(item => item.orderuuid === orderuuid).length === 0) {
            // If not already in the list, add it
            parent.type = "Parent";
            linkedOrders.push(parent);
          }
        });
      });
    }
    return linkedOrders;
  };

  refreshFolders = () => {
    this.setState({ notes: [] }, () => {
      this.getNotes();
    });
  };

  handleToggleHideInactive = () => {
    this.setState(prevState => ({
      hideInactiveSubscriptions: !prevState.hideInactiveSubscriptions,
    }));
  };

  handleToggleHideAuthorizations = () => {
    this.setState({ hideAuthorizations: !this.state.hideAuthorizations });
  };

  handleMatch = (marketingprospectuuid, contactuuid) => {
    // Confirm the match
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      title: "Confirm Match",
      text: "Are you sure you want to match this contact with the prospect?\nThis action cannot be undone.",
      callback: response => {
        if (response === Constants.OVERLAY_RESPONSE_YES) {
          this.putProspectMatch(marketingprospectuuid, contactuuid);
        } else {
          this.props.hideOverlay();
        }
      },
    });
  };

  handleClick = type => {
    const tabChanged = type !== this.state.folderType;
    if (!tabChanged) {
      return;
    }
    // These folders just use fields from the relevant detail object
    // so we don't need to make any database calls
    if (
      type === Constants.FOLDER_AUTHORIZATIONS ||
      type === Constants.FOLDER_MAAST_CARDS ||
      type === Constants.FOLDER_MAAST_CUSTOM ||
      type === Constants.FOLDER_MAAST_INVOICES ||
      type === Constants.FOLDER_MAAST_TRANSACTIONS ||
      type === Constants.FOLDER_PRINTED_NOTES ||
      type === Constants.FOLDER_PROSPECT_MATCHES
    ) {
      this.setState({ folderType: type });
    } else if (type === Constants.FOLDER_EMAIL) {
      this.setState({ folderType: type }, () => {
        const item = {};
        if (Constants.CONTACT_VIEWS.includes(this.props.appState.currentView)) {
          item.uuid = this.props.refuuid;
          if (this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
            item.type = Constants.PROSPECT;
          } else {
            item.type = Constants.COMPANY;
          }
        } else {
          item.orderuuid = this.props.refuuid;
        }
        this.props.getEmail(null, item, Constants.URL_MAIL, response => {
          if (response.status === 200 && response.body) {
            this.setState({ emails: response.body.records });
          }
        });
      });
    } else if (type === Constants.FOLDER_TEXT) {
      this.setState({ folderType: type }, () => {
        if (this.props.appState?.twilio?.phonenumber?.length > 0) {
          this.props.getSMSContacts(this.props.refuuid, response => {
            if (response.status === 200 && response.body) {
              // Reverse the messages for all contacts (the first one should be the only one that has messages)
              response.body.records.forEach(contact => {
                contact.messages?.records?.reverse();
              });
              this.setState(
                {
                  smscontacts: response.body.records,
                  selectedsmscontact: response.body.records?.length > 0 ? response.body.records[0] : null,
                },
                () => {
                  if (this.textPanelRef.current) {
                    this.textPanelRef.current.scrollTo({
                      top: this.textPanelRef.current.scrollHeight,
                      behavior: "smooth",
                    });
                  }
                }
              );
            }
          });
        }
      });
    } else if (type === Constants.FOLDER_REVERB) {
      this.setState({ folderType: type }, () => {
        if (this.props.appState?.thirdparty?.reverbapikey) {
          this.props.getShippingProfiles(() => {
            this.props.getListingConditions(() => {
              this.props.getListingCategories(() => {
                if (this.props.product?.storesku) {
                  this.props.getReverbListing(this.props.product.storesku);
                }
              });
            });
          });
        }
      });
    } else if (type === Constants.FOLDER_SUBSCRIPTIONS) {
      this.setState({ folderType: type }, () => {
        // Load the subscriber data
        this.getSubscriptions();
      });
    } else if (type === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
      // Pull the latest copy of the notes/orders from the database
      this.setState({ folderType: type, ordersCount: 0, orders: [] }, () => {
        this.getOrders();
      });
    } else {
      // Pull the latest copy of the notes/orders from the database
      this.setState({ folderType: type, notesCount: 0, notes: [], ordersCount: 0, orders: [] }, () => {
        if (type === Constants.FOLDER_NOTES || type === Constants.FOLDER_INTERNAL_NOTES) {
          this.getNotes();
        } else if (
          [
            Constants.FOLDER_INVOICES,
            Constants.FOLDER_ORDERS,
            Constants.FOLDER_PURCHASES,
            Constants.FOLDER_QUOTES,
            Constants.FOLDER_REPAIRS,
            Constants.FOLDER_LINKED_ITEMS,
            Constants.FOLDER_UNPAID_INVOICES,
          ].includes(type)
        ) {
          this.getOrders();
        }
      });
    }
  };

  handleSelectSmsContact = uuid => {
    if (uuid === this.state.selectedsmscontact?.uuid) {
      return;
    }
    const contact = this.state.smscontacts.find(contact => contact.uuid === uuid);
    this.setState({ selectedsmscontact: contact }, () => {
      // Get the messages for this SMS contact
      this.handleAfterSelectSmsContact(contact);
    });
  };

  handleAfterSelectSmsContact = contact => {
    this.props.getText(contact, false, Constants.URL_SMS, 4, response => {
      if (response.status === 200) {
        // Reverse the messages for the contact in the response so that the newest message is on the bottom
        response.body.records.reverse();
        this.setState(
          prevState => ({
            downloadingTexts: false,
            smscontacts: prevState.smscontacts.map(listItem => {
              if (listItem.uuid === contact.uuid) {
                // If this is a request for new messages, then add the new messages to the end of the list and increment the count
                if (contact.oldmessageuuid) {
                  listItem.messages.records = [
                    ...response.body.records.map(rec => {
                      rec.easeIn = true;
                      rec.easeInActive = false;
                      return rec;
                    }),
                    ...listItem.messages.records.map(rec => {
                      rec.easeIn = false;
                      rec.easeInActive = false;
                      return rec;
                    }),
                  ];
                } else {
                  listItem.messages = response.body;
                }
              }
              return listItem;
            }),
          }),
          () => {
            // Scroll to the bottom with smooth animation, if not loading older messages
            if (!contact.oldmessageuuid) {
              this.textPanelRef.current.scrollTo({
                top: this.textPanelRef.current.scrollHeight,
                behavior: "smooth",
              });
            }
            // This is a hack to get the animation to work. The animation will not work if the state is set immediately after the setState call above.
            setTimeout(() => {
              this.setState(prevState => ({
                smscontacts: prevState.smscontacts.map(listItem => {
                  if (listItem.uuid === contact.uuid) {
                    listItem.messages.records = listItem.messages.records.map(rec => {
                      rec.easeIn = true;
                      rec.easeInActive = true;
                      return rec;
                    });
                  }
                  return listItem;
                }),
              }));
            }, 300);
          }
        );
      } else {
        this.setState({ downloadingTexts: false });
      }
    });
  };

  handleGetOlderTexts = () => {
    this.setState({ downloadingTexts: true }, () => {
      let item = Helper.deepCopy(this.state.selectedsmscontact);
      const messages = this.state.selectedsmscontact?.messages?.records;
      item.oldmessageuuid = messages[0]?.smsmessageuuid;
      this.handleAfterSelectSmsContact(item);
    });
  };

  handleChangeNote = (noteuuid, noteText) => {
    this.setState(prevState => ({
      notes: prevState.notes.map(note => {
        if (note.noteuuid === noteuuid) {
          note.note = noteText;
        }
        return note;
      }),
    }));
  };

  handleBlurNote = (event, noteuuid, note) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    // Trim the note text, if required and update the state
    if (note !== note.trim()) {
      note = note.trim();
      this.handleChangeNote(noteuuid, note);
    }
    if (prev === note) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    const cc = Helper.detectCreditCardNumbers(note);
    if (cc.length > 0) {
      this.setState(prevState => ({
        notes: prevState.notes.map(note => {
          if (note.noteuuid === noteuuid) {
            note.error = true;
          }
          return note;
        }),
      }));
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Credit card numbers are not allowed in notes. Changes not saved.",
      });
      return;
    }

    // Clear any error flags
    this.setState(prevState => ({
      notes: prevState.notes.map(note => {
        if (note.noteuuid === noteuuid) {
          note.error = false;
        }
        return note;
      }),
    }));

    if (noteuuid === "new" && note) {
      let reftype = this.props.appState.currentView;
      let refuuid = this.props.refuuid;
      if (this.state.folderType === Constants.FOLDER_NOTES && Helper.isOrderView(this.props.appState.currentView)) {
        reftype = Constants.COMPANY;
        refuuid = this.props.order?.company?.companyuuid;
      } else if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
        reftype = Constants.PROSPECT;
        refuuid = this.props.company?.contacts[0].marketingprospectuuid;
      } else if (Constants.CONTACT_VIEWS.includes(this.props.appState.currentView)) {
        reftype = Constants.COMPANY;
        refuuid = this.props.refuuid;
      }
      this.postNote(reftype, refuuid, note);
    } else if (noteuuid && noteuuid !== "new" && note) {
      this.putNote(noteuuid, note);
    } else if (noteuuid !== "new") {
      this.deleteNote(noteuuid, note);
    }
  };

  handleEditItem = item => {
    let menu = "";
    let view = item.ordertype;
    if (item.ordertype === Constants.INVOICE) {
      menu = Constants.INVOICES;
    } else if (item.ordertype === Constants.ORDER) {
      menu = Constants.ORDERS;
    } else if (item.ordertype === Constants.QUOTE) {
      menu = Constants.QUOTES;
    } else if (item.ordertype === Constants.REPAIR) {
      menu = Constants.REPAIRS;
    } else if (item.type === Constants.SUBSCRIPTION) {
      menu = Constants.RECURRINGS;
      view = Constants.RECURRING;
    }
    this.props.handleEditItem(view, menu, item);
  };

  handleDeletePhoto = photouuid => {
    // Block deletion of the last photo for a Cartloom product
    if (this.props.product?.photos?.length === 1 && this.props.product?.externalid) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "You cannot delete the last photo for a Cartloom product.",
      });
    } else {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to delete this photo?",
        key: photouuid,
        callback: this.maybeDeletePhoto,
      });
    }
  };

  maybeDeletePhoto = (response, photouuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.props.deletePhoto(photouuid);
    }
  };

  handleDropPhoto = event => {
    event.preventDefault();
    let files = event.dataTransfer.files;
    let limit = 25 - this.props.product.photos.length;
    if (this.props.product.photos.length + files.length > 25) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "You can only have 25 photos per product.",
      });
    }
    if (files.length > 0) {
      var count = 0;
      for (var i = 0; i < files.length; i++) {
        const file = files[i];
        // Skip processing of files w/o file extension using Helper.getFileType(file.name)
        if (Helper.getFileType(file.name) === "") {
          continue;
        }
        const reader = new FileReader();
        reader.onload = e => {
          this.props.postPhotoBinary(
            this.props.product?.productuuid,
            Constants.PRODUCT,
            e.target.result,
            file.name,
            file.size,
            file.type || Helper.getFileType(file.name),
            this.props.handleAfterAddPhotoBinary
          );
        };
        reader.readAsDataURL(file);
        count++;
        if (count >= limit) {
          break;
        }
      }
    }
  };

  handleRefundTransaction = transaction => {
    const overlay = {
      type: Constants.OVERLAY_INPUT_BOX,
      title: "Refund Transaction",
      text: "Enter the refund amount",
      placeholder: "Refund amount",
      maxLength: 12,
      callback: this.maybeRefundTransaction,
      key: transaction,
      okButtonLabel: "Refund",
      input_type: "number",
    };
    this.props.showOverlay(overlay);
  };

  maybeRefundTransaction = (response, transaction, amount) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      const amountAvailable = numeral(transaction.amt_tran).subtract(transaction.amt_refunded).value();
      if (!amount || isNaN(amount) || amount <= 0) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Invalid refund amount.",
        });
      } else if (amountAvailable < numeral(amount).value()) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Refund amount cannot exceed remaining balance.",
        });
      } else {
        // Prompt for manager login
        this.props.showOverlay({
          type: Constants.OVERLAY_AUTH_PROMPT,
          prompt: "Manager authorization required\nto process refund.",
          user: this.props.appState.username,
          callback: (response, authtoken = null) => {
            if (response === Constants.OVERLAY_RESPONSE_YES) {
              this.props.postRefundTransaction(transaction, amount, authtoken);
            }
          },
        });
      }
    } else {
      this.setState({ downloading: false }, () => {
        this.props.hideOverlay();
      });
    }
  };

  handleDeleteRequestLink = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete this request link?",
      key: this.props.company?.vaultrequest?.uuid,
      callback: this.maybeDeleteRequestLink,
    });
  };

  maybeDeleteRequestLink = (response, requestuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.props.handleDeletePaymentRequestLink(requestuuid);
    }
  };

  getFolderClassName = folderType => {
    let className = "folders desktop-inline";
    if (this.state.folderType === folderType) {
      className += " selectedFolder highlight ";
    }
    return className;
  };

  getProspectMatches = () => {
    // If no prospect uuid or already matched, then return without making the API call
    if (!this.props.company?.contacts[0]?.marketingprospectuuid || this.props.company?.contacts[0]?.contactuuid) {
      return;
    }
    const url = Constants.URL_CAMPAIGNS;
    const data = {
      action: Constants.FIND_MATCHES,
      marketingprospectuuid: this.props.company?.contacts[0].marketingprospectuuid,
    };
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState({ matches: response.body.records, error: null });
      } else {
        this.setState({ error: "Failed to load" });
      }
    });
  };

  getNotes = () => {
    let refuuid = this.props.refuuid;
    let notetype = this.props.appState.currentView;

    // For order views, the FOLDER_NOTES shows customer notes
    if (Helper.isOrderView(this.props.appState.currentView) && this.state.folderType === Constants.FOLDER_NOTES) {
      refuuid = this.props.companyuuid;
      notetype = Constants.COMPANY;
    } else if (
      // Check for prospect view
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS
    ) {
      notetype = Constants.PROSPECT;
    } else if (Constants.CONTACT_VIEWS.includes(this.props.appState.currentView)) {
      notetype = Constants.COMPANY;
    }

    // If there is no refuuid, then do not execute the API call
    if (!refuuid) {
      return;
    }

    //set up to make database call
    const url = Constants.URL_NOTES;

    // Pagination for notes
    let searchstart = 0;
    const notesLoaded = this.state.notes.length > 0;
    const existingNoteCount = this.state.notes.filter(item => item.noteuuid !== "new").length;
    const moreNotes = existingNoteCount < this.state.notesCount;
    if (notesLoaded && moreNotes) {
      // Set the search start to be the count of notes from the database
      // (exclude the new, blank one)
      searchstart = existingNoteCount;
    }
    const data = {
      notetype: notetype,
      refuuid: refuuid,
      searchstart: searchstart,
      searchlimit: this.props.appState.displaySettings.FOLDER_ROWS,
    };
    // Need to include the Preempt flag to prevent duplicate notes from being loaded
    Helper.getData(url, data, null, null, Constants.PREEMPT_GET_NOTES).then(response => {
      if (response.status === 200 && response.body && response.body.records) {
        let notes = response.body.records;
        // If there are already notes loaded, then add the new ones to the end
        if (this.state.notes && this.state.notes.length > 0) {
          notes = this.state.notes.concat(notes);
        }
        let notesCount = response.body.count ?? 0;

        // If this is the billing view, then check for notes that contain a subscription_id
        // in the format 'subscription_id:1234' and replace the placeholder with the
        // actual subscription plan_desc
        if (Constants.BILLING_DETAIL_VIEWS.includes(this.props.appState.currentView)) {
          const regex = /subscription_id:(\d+)/;
          notes = notes.map(note => {
            const matchResult = note.note.match(regex);
            const subscriptionId = matchResult ? matchResult[1] : null;
            if (subscriptionId) {
              const subscription = this.props.subscriptions?.find(subscription => {
                return (
                  // Match against either the subscription_id or the external_subscription_id
                  parseInt(subscription.external_subscription_id) === parseInt(subscriptionId) ||
                  parseInt(subscription.subscription_id) === parseInt(subscriptionId)
                );
              });
              if (subscription) {
                const name = subscription.plan_desc || subscription.plan_name;
                // The following regex replace uses a function to replace the match with the subscription name instead of 'name' directly,
                // because the 'name' variable could contain special characters that would be interpreted as regex syntax
                note.note = note.note.replace(regex, match => ` ${name} `);
              }
            }
            return note;
          });
        }
        // Add a blank record for new notes, if one does not already exist
        if (notes.filter(item => item.noteuuid === "new").length === 0) {
          notes.unshift({
            noteuuid: "new",
            note: "",
            lastupdated: "",
          });
        }

        this.setState({ notes: notes, notesCount: notesCount, error: null });
      } else {
        this.setState({ error: "Failed to load" });
      }
    });
  };

  getOrders = () => {
    //set up to make database call
    let url = Constants.URL_ORDERS_RO;
    let orderType = "";
    let params = {};
    if (this.state.folderType === Constants.FOLDER_INVOICES) {
      orderType = Constants.INVOICE;
    } else if (this.state.folderType === Constants.FOLDER_UNPAID_INVOICES) {
      orderType = Constants.INVOICE;
    } else if (this.state.folderType === Constants.FOLDER_ORDERS) {
      orderType = Constants.ORDER;
    } else if (this.state.folderType === Constants.FOLDER_QUOTES) {
      orderType = Constants.QUOTE;
    } else if (this.state.folderType === Constants.FOLDER_REPAIRS) {
      orderType = Constants.REPAIR;
    } else if (this.state.folderType === Constants.FOLDER_PURCHASES) {
      orderType = Constants.PURCHASE;
    } else if (this.state.folderType === Constants.FOLDER_SUBSCRIPTION_INVOICES) {
      orderType = Constants.INVOICE;
      params = { ordersubtype: Constants.SUBSCRIPTION };
    }
    let searchstart = 0;
    const ordersLoaded = this.state.orders.length > 0;
    const moreOrders = this.state.orders.length < this.state.ordersCount;
    if (ordersLoaded && moreOrders) {
      searchstart = this.state.orders.length;
    }
    const data = {
      ordertype: orderType,
      refuuid: this.props.refuuid,
      searchstart: searchstart,
      sortkey: "orders.creationdatetime",
      sortdirection: "D",
      ...params,
    };
    if (this.state.folderType === Constants.FOLDER_UNPAID_INVOICES) {
      data.unpaid = true;
      if (Helper.isOrderView(this.props.appState.currentView)) {
        data.refuuid = this.props.companyuuid;
      }
    }

    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body && response.body.records) {
        let orders = response.body.records;
        const ordersCount = response.body.count;
        if (this.state.orders.length > 0) {
          orders = this.state.orders.concat(orders);
        }
        this.setState({ orders: orders, ordersCount: ordersCount, error: null });
      } else {
        this.setState({ error: "Failed to load" });
      }
    });
  };

  getSubscriptions = () => {
    const url = Constants.URL_RECURRINGS;
    const data = {
      action: Constants.SUBSCRIBER,
      companyuuid: this.props.company.companyuuid,
    };
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body.subscriptions) {
        // If there are any active subscriptions, then set the hideInactiveSubscriptions flag to true
        const hideInactiveSubscriptions =
          response.body.subscriptions.filter(sub => Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(sub.status)).length > 0;
        this.setState({
          subscriptions: response.body.subscriptions,
          maastCustomer: response.body.maastCustomer,
          hideInactiveSubscriptions: hideInactiveSubscriptions,
          error: null,
        });
      } else {
        this.setState({ subscriptions: [] });
      }
    });
  };

  putProspectMatch = (marketingprospectuuid, contactuuid) => {
    const url = Constants.URL_CAMPAIGNS;
    const data = { marketingprospectuuid: marketingprospectuuid, contactuuid: contactuuid };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        const prospect = response.body;
        this.props.maybeTagCustomer(
          prospect?.company,
          "Prospect successfully matched to selected customer.\nEnter a tag to apply to this customer for follow-up.\n(Leave blank to skip)",
          prospect?.customertags,
          () => {
            this.props.updateMarketingProspect(prospect, () => {
              this.setState({ folderType: Constants.FOLDER_NOTES, prospect: prospect });
            });
          }
        );
      } else {
        this.setState({ error: "Failed to match" });
      }
    });
  };

  putNote = (noteuuid, note) => {
    //set up to make database call
    const url = Constants.URL_NOTES;
    const data = {
      noteuuid: noteuuid,
      note: note,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Update the note's lastupdated field
        this.setState(prevState => ({
          notes: prevState.notes.map(note => {
            if (note.noteuuid === noteuuid) {
              note.lastupdated = response.body.lastupdated;
            }
            return note;
          }),
        }));
      } else {
        this.setState({ error: "Failed to update note." });
      }
    });
  };

  postNote = (reftype, refuuid, note) => {
    //set up to make database call
    const url = Constants.URL_NOTES;
    const data = {
      notetype: reftype,
      note: note,
      refuuid: refuuid,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        let notes = this.state.notes.map(note => {
          if (note.noteuuid === "new") {
            note.noteuuid = response.body.noteuuid;
            note.lastupdated = response.body.lastupdated;
          }
          return note;
        });
        // Add a blank record for new notes
        notes.unshift({
          noteuuid: "new",
          note: "",
          lastupdated: "",
        });
        this.setState({ notes: notes });
      } else {
        this.setState({ error: "Failed to update note." });
      }
    });
  };

  deleteNote = noteuuid => {
    //set up to make database call
    const url = Constants.URL_NOTES;
    const data = { noteuuid: noteuuid };
    Helper.deleteData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(prevState => ({
          notes: prevState.notes.filter(note => note.noteuuid !== noteuuid),
        }));
      } else {
        this.setState({ error: "Failed to update note." });
      }
    });
  };

  handleCopyUrlToClipboard = (event, url) => {
    event.stopPropagation();
    this.setState({ copied: [...this.state.copied, url] });
    setTimeout(() => {
      this.setState({ copied: this.state.copied.filter(link => link !== url) });
    }, 1500);
    Helper.copyToClipboard(url);
  };

  renderVaultRequestElement = () => {
    if (this.props.company?.vaultrequest?.uuid && this.props.company?.vaultrequest?.url) {
      return this.renderCardVaultRequestLink();
    } else {
      return this.renderCardVaultRequestButton();
    }
  };

  renderCardVaultRequestButton = () => {
    let handler = this.props.handleRequestPaymentMethod;
    let requestLink = (
      <span data-testid="Request Vault Link Button" className="action-button brown-button vaultButton" onClick={handler}>
        Request a Link to the Vault
      </span>
    );
    return requestLink;
  };

  renderCardVaultRequestLink = () => {
    let copyIcon = faCopy;
    if (this.state.copied.includes(this.props.company?.vaultrequest?.url)) {
      copyIcon = faCheck;
    }
    const linkSpan = Helper.canClip() ? (
      <span
        title="Click to copy link"
        onClick={event => {
          this.handleCopyUrlToClipboard(event, this.props.company?.vaultrequest?.url);
        }}
        data-testid="Vault Request Link"
        data-url={this.props.company?.vaultrequest?.url}
      >
        Vault Request Link <FontAwesomeIcon icon={copyIcon} className="highlight" />
      </span>
    ) : (
      <a
        href={this.props.company?.vaultrequest?.url}
        target="_blank"
        rel="noreferrer"
        className="white"
        data-testid="Vault Request Link"
        data-url={this.props.company?.vaultrequest?.url}
      >
        Vault Request Link
      </a>
    );
    const requestLink = (
      <span className="action-button black-button vaultButton">
        {linkSpan}
        &nbsp;&nbsp;
        <span className="ghost" title="Click to delete the link" onClick={this.handleDeleteRequestLink}>
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </span>
    );

    return requestLink;
  };
}

export default Folders;
