import React, { Component } from "react";

/**
const events = { 0: { 1: "Hi" }, 1: { 2: "Hello" } };
pagecontents = <Calendar events={events} />;
 */
class Calendar extends Component {
  renderMonth = monthOffset => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() + monthOffset, 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + monthOffset + 1, 0);
    const daysInMonth = lastDayOfMonth.getDate();
    const firstDayIndex = firstDayOfMonth.getDay();
    const lastSundayOfMonth = daysInMonth - lastDayOfMonth.getDay();

    // Month name header
    const month = (
      <div className="month">
        <span>{firstDayOfMonth.toLocaleString("en-us", { month: "long" })}</span>
      </div>
    );

    // Leading blanks
    let cells = [];
    for (let i = 0; i < firstDayIndex; i++) {
      cells.push(i);
    }
    const leadingBlanks = cells.map((blank, index) => {
      return (
        <div key={index} className="blank">
          &nbsp;
        </div>
      );
    });

    // Trailing blanks
    cells = [];
    for (let i = 0; i < 6 - lastDayOfMonth.getDay(); i++) {
      cells.push(i);
    }
    const trailingBlanks = cells.map((blank, index) => {
      let classes = "blank trailing";
      if (index === cells.length - 1) {
        classes = classes + " right";
      }
      return (
        <div key={index} className={classes}>
          &nbsp;
        </div>
      );
    });

    // loop thru the days of the month
    const days = [];
    for (let i = 0; i < daysInMonth; i++) {
      days.push(i + 1);
    }

    const cal = days.map((day, index) => {
      let classes = "day";
      const column = index + firstDayIndex + 1;
      if (column % 7 === 0) {
        classes = classes + " right";
      }
      if (index >= lastSundayOfMonth - 1) {
        classes = classes + " trailing";
      }
      return (
        <div key={index} data-key={index} className={classes}>
          <span className="dayOfMonth">
            <span className="dayLabel"> {day}</span>
            <br />
            {this.renderDay(monthOffset, day)}
          </span>
        </div>
      );
    });
    return (
      <>
        {month}
        {leadingBlanks}
        {cal}
        {trailingBlanks}
      </>
    );
  };

  renderDay = (monthOffset, day) => {
    const events = this.props.events || [];
    const dayOfMonth = events.find(d => d.day === day);
    if (dayOfMonth === undefined) {
      return "";
    }
    return (
      <span className="events">
        {dayOfMonth.events.map(e => {
          return <div>{e}</div>;
        })}
      </span>
    );
  };

  render() {
    return (
      <div className="calendarWidget">
        <div className="thisMonth">{this.renderMonth(this.props.offset)}</div>
      </div>
    );
  }
}

export default Calendar;
