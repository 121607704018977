import React from "react";

import { animated, useSpring } from "@react-spring/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Capo from "../img/Capo.js";

function NewsWidget(props) {
  const [expanded, setExpanded] = React.useState(false);
  const openAnimation = useSpring({
    from: { maxHeight: "6em" },
    to: { maxHeight: expanded ? "100em" : "6em" },
    config: { duration: "400" },
  });
  let title = "Latest Updates";
  if (props.appState?.clientSettings?.NAME) {
    title = "Updates for " + props.appState?.clientSettings?.NAME;
  }
  const expanderIcon = expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
  const expander = (
    <div
      className="dbwExpander"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      {expanderIcon}
    </div>
  );
  return (
    <animated.div
      className="dashboardWidget dbwNews"
      onClick={() => {
        setExpanded(!expanded);
      }}
      style={openAnimation}
    >
      <div className="dbwTitle">
        <span className="newsLogo">
          <Capo />
        </span>
        <span data-testid="Updates for">{title}</span>
      </div>
      <hr />
      <h3 className="white">Latest Enhancements</h3>
      <ul>
        <li>Invoices can now be searched using the customer's phone number.</li>
        <li>Refund invoices for subscription payments now appear on both the regular Invoice and Billing Invoice Lists.</li>
        <li>Improved response times when accessing billing subscriptions and invoices.</li>
        <li>
          When sorting subscriptions by "date of next payment," subscriptions without a defined payment date are now automatically placed at the end
          of the list. The same applies to due dates for service orders.
        </li>
        <li>
          Billing subscription can now be manually suspended to allow new invoices to be created each cycle, without attempting a payment
          authorization.
        </li>
      </ul>
      <h3 className="white">Bug Fixes</h3>
      <ul>
        <li>Non-manager can now save product counts during inventory reconciliation.</li>
        <li>
          Refunding an invoice paid with cash now correctly calculates the tender amount, taking into consideration the change given on the original
          invoice.
        </li>
        <li>Custom field labels and values now remain visible after updating subscription details.</li>
      </ul>
      {expander}
    </animated.div>
  );
}

export default NewsWidget;
