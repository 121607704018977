import React from "react";

// Constants
import * as Constants from "./Constants";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";

// Components
import JsBarcode from "jsbarcode";
import PaymentAcceptIcon from "./img/PaymentAcceptIcon.js";
import PaymentDeclineIcon from "./img/PaymentDeclineIcon.js";

class BaseDetailViewAPI extends React.Component {
  isBillingDetailView = () => {
    return Constants.BILLING_DETAIL_VIEWS.includes(this.props.appState?.currentView);
  };

  getRecurringPaymentSchedule = (subscription, index) => {
    if (!subscription || !subscription.date_next || !subscription.plan_frequency || !subscription.plan_duration) {
      return;
    }

    // Calculate remaining_invoices
    let remaining_invoices = subscription.remaining_invoices;
    if (subscription.plan_duration === -1) {
      remaining_invoices = 4;
    } else if (!remaining_invoices) {
      remaining_invoices = Math.max(subscription.plan_duration - (subscription.plan_invoice_count ?? 0), 0);
    }
    if (subscription.skip_oxt_payment === true) {
      remaining_invoices += 1;
    }

    // Paused subscriptions have no "next payment dates" to calculate
    if (subscription.status === Constants.CH_SUBSCRIPTION_PAUSED) {
      this.setState(prevState => ({
        subscriptions: prevState.subscriptions.map((subscription, i) => {
          if (i === index) {
            subscription.date_next_payments = [];
          }
          return subscription;
        }),
      }));
      return;
    }

    const params = {
      action: Constants.PAYMENT_SCHEDULE,
      date_next: subscription.date_next,
      day_of_cycle: subscription.day_of_cycle,
      month_of_cycle: subscription.month_of_cycle,
      plan_frequency: subscription.plan_frequency,
      remaining_invoices: remaining_invoices,
    };
    const url = Constants.URL_RECURRINGS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(prevState => ({
          subscriptions: prevState.subscriptions.map((subscription, i) => {
            if (i === index) {
              subscription.date_next_payments = response.body;
            }
            return subscription;
          }),
        }));
      } else {
        this.setState(prevState => ({
          subscriptions: prevState.subscriptions.map((subscription, i) => {
            if (i === index) {
              subscription.date_next_payments = [subscription.date_next];
            }
            return subscription;
          }),
        }));
      }
    });
  };

  // Loads all the Maast billing plans for this client
  getMaastBillingPlans = (pageNumber = 0) => {
    this.setState(
      {
        downloading: true,
      },
      () => {
        if (pageNumber === 0) {
          this.setState({
            maastBillingPlans: [],
          });
        }
        const params = {
          action: Constants.BILLING_GET_PLANS,
          page: pageNumber,
          count: 100,
          order_on: "plan_desc",
          order_by: "asc",
          filter: "status,IS,E",
        };

        const url = Constants.URL_BILLING;
        Helper.getData(url, params).then(response => {
          if (response.status === 200 && response.body.code === 0 && response.body.data) {
            const pages = response.body.totalPages;
            let maastBillingPlans = response.body.data ?? [];
            maastBillingPlans = maastBillingPlans.map(plan => {
              return Helper.normalizePlan(plan);
            });
            // If this is any page other than the first one, we need to append the results to the list
            if (pageNumber > 0) {
              maastBillingPlans = this.state.maastBillingPlans.concat(maastBillingPlans);
            }
            this.setState(
              {
                maastBillingPlans: maastBillingPlans,
                pagecount: response.body.totalPages,
                error: null,
              },
              () => {
                if (pages > pageNumber + 1) {
                  // We need to load the next page
                  this.getMaastBillingPlans(pageNumber + 1);
                } else {
                  this.props.hideOverlay();
                }
              }
            );
          } else if (response.status === 503) {
            this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
            this.props.hideOverlay();
          } else {
            this.setState({ error: "GET" }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "There was an error loading the " + this.props.title + ".",
              });
            });
          }
          this.setState({
            downloading: false,
          });
        });
      }
    );
  };

  // Loads all the billing plans for this client
  getBillingPlans = (pageNumber = 0) => {
    this.setState(
      {
        downloading: true,
      },
      () => {
        if (pageNumber === 0) {
          this.setState({
            billingPlans: [],
          });
        }
        const params = {
          action: Constants.BILLING_PLAN,
          searchstart: pageNumber,
          searchlimit: 100,
        };

        const url = Constants.URL_RECURRINGS;
        Helper.getData(url, params).then(response => {
          if (response.status === 200 && response.body.records) {
            this.setState({
              billingPlans: response.body.records ?? [],
              error: null,
            });
          } else if (response.status === 503) {
            this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
          } else {
            this.setState({ error: "GET" }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "There was an error loading the " + this.props.title + ".",
              });
            });
          }
          this.setState({
            downloading: false,
          });
        });
      }
    );
  };

  getProductList = productSearchKey => {
    if (!productSearchKey.trim()) {
      return;
    }
    this.setState({
      downloading: true,
      productSearchLimit: this.props.appState.displaySettings.FOLDER_ROWS,
    });
    const params = {};
    const url = Constants.URL_PRODUCTS;
    if (productSearchKey) {
      params.searchkey = encodeURIComponent(productSearchKey.trim());
    }
    params.active = true;
    params.searchstart = 0;
    // Limit the search results to 5 times the page size
    params.searchlimit = numeral(this.props.appState.displaySettings.FOLDER_ROWS).multiply(Constants.PRODUCT_SEARCH_PAGES).value();

    // Exclude gift cards from the search if we're not on the invoice view
    params.exclude_gift_cards = [Constants.ORDER, Constants.PURCHASE, Constants.QUOTE, Constants.REPAIR].includes(this.props.appState.currentView);

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        // If a single product is returned in the search
        // and it's UPC or store SKU is an exact match for the searchkey,
        // then go ahead and add the item to the order
        // Otherwise, display the search results
        const matches = response.body.records.filter(
          item =>
            item.ean === productSearchKey ||
            item.upc === productSearchKey ||
            item.altupc === productSearchKey ||
            item.storesku.toLowerCase() === productSearchKey.toLowerCase() ||
            item.uuid.toLowerCase() === productSearchKey.toLowerCase()
        );
        if (matches.length === 1) {
          this.selectProductListItem(matches[0]);
        } else {
          this.setState({
            productSearchResults: response.body.records,
            productSearchCount: response.body.count,
            error: null,
          });
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error searching the products.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  // Looks up a product by productuuid and returns the product to the caller
  getProductDetails = (productuuid, callback) => {
    if (!productuuid) {
      return;
    }
    const params = {
      productuuid: productuuid,
    };
    const url = Constants.URL_PRODUCTS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        response.body.sellprice = numeral(response.body.sellprice).format(Constants.CURRENCY_NO_COMMA);
        response.body.cost = numeral(response.body.cost).format(Constants.CURRENCY_NO_COMMA);
        callback(response.body);
      } else {
        this.setState({ error: "GET" }, () => {
          // TODO: Handle missing products better
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error retrieving the product's full details from the server.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getOrder = orderuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDERS;
    const params = { orderuuid: orderuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Move the selected contact to front of list in company, if necessary
        if (this.state.contactuuid) {
          response.body.company = Helper.moveContactToFront(this.state.contactuuid, response.body.company);
        }

        // Update state with response, turn off downloading
        this.setState(
          {
            order: { ...Helper.formatOrder(response.body) },
            error: null,
            downloading: false,
            isNew: false,
            isReturn: response.body.ordertype === Constants.INVOICE && response.body.ordersubtype === Constants.RETURN,
          },
          () => {
            // Create Barcode for Order
            const order_number = Helper.getOrderNumber(this.state.order.ordertype, this.state.order.ordersubtype, this.state.order.ordernumber);
            try {
              JsBarcode("#barcode", order_number, {
                width: 1.75,
                height: 15,
                displayValue: false,
                margin: 2,
              });
            } catch (error) {
              console.log(Helper.clTimestamp(), error);
            }
            try {
              JsBarcode("#barcodeNarrow", order_number, {
                width: 1.75,
                height: 15,
                displayValue: true,
                font: "Poppins - Regular",
                textMargin: 1,
                fontSize: 10,
              });
            } catch (error) {
              console.log(Helper.clTimestamp(), error);
            }
          }
        );

        const balanceDue = numeral(response.body.balancedue).value();
        // Check for paid invoices on the PAY view and return to the previous view
        if (
          response.body.ordertype === Constants.INVOICE &&
          this.props.appState.currentView === Constants.PAY &&
          (balanceDue === 0 || Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, response.body.orderstatus))
        ) {
          this.props.followBreadcrumb();
        }
        // Check for pending authorizations on the PAY view
        else if (
          response.body.ordertype === Constants.INVOICE &&
          this.props.appState.currentView === Constants.PAY &&
          response.body.authorizations?.filter(auth => auth.status === Constants.AUTH_PENDING && ![Constants.MAAST].includes(auth.gateway)).length > 0
        ) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            title: "Pending Authorizations Found",
            text: "Click Ok to retrieve pending authorization statuses.",
            callback: () => {
              this.getPendingAuthorizationStatuses(
                response.body.authorizations?.find(auth => auth.status === Constants.AUTH_PENDING && ![Constants.MAAST].includes(auth.gateway))
                  .authuuid
              );
            },
          });
        }
        // Check for sales tax update messages
        else if (response.body?.notification === Constants.NOTIFICATION_SALES_TAX_UPDATED) {
          // Show notification
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Sales tax on this order has been updated",
          });
        }
        // Check for a Reverb order that did not get matched to a ClerkHound product
        else if (
          response.body.ordertype === Constants.INVOICE &&
          response.body.externalid &&
          response.body.orderitems.length > 0 &&
          response.body.orderitems[0].storesku === Constants.INFO_LINE_ITEM_SKU
        ) {
          this.handleUnmatchedProduct(response.body.orderitems[0].productname);
        }
        // Hide the overlay if no special conditions are met
        else {
          this.props.hideOverlay();
        }
      } else if (response.status === 404) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the data.",
          });
        });
      }
    });
  };

  getPendingAuthorizationStatuses = authuuid => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Retrieving authorization statuses...",
    });
    const url = Constants.URL_PAYMENTS;
    const params = {
      action: Constants.RETRIEVE_RESULT,
      authuuid: authuuid,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200) {
        // Overlay the auth in state with the auth details from the API
        let reload = false;
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              authorizations: prevState.order.authorizations.map(auth => {
                if (auth.authuuid === authuuid) {
                  if (auth.status !== response.body.status) {
                    reload = true;
                  }
                  return { ...auth, ...response.body };
                }
                return auth;
              }),
            },
          }),
          () => {
            if (reload) {
              this.getOrder(this.state.order.orderuuid);
            } else {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "Order updated.",
              });
            }
          }
        );
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error retrieving the authorization status.",
        });
      }
    });
  };

  // Customer and Supplier views have their own getCompany function
  getCompany = (companyuuid, callback = null, messageOn404 = true) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const params = { companyuuid: companyuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        let company = response.body;
        // Set the edit mode on all contacts to false because it doesn't come from the database
        company.contacts = company.contacts.map(contact => {
          contact.editmode = false;
          return contact;
        });

        // Define the contact info
        let contactuuid = null;
        let contactname = null;
        let companyname = null;

        // Move the selected contact to front of list in company, if necessary
        if (this.state.contactuuid) {
          company = Helper.moveContactToFront(this.state.contactuuid, company);
          contactuuid = this.state.contactuuid;
        } else if (company.contacts.length > 0) {
          contactuuid = company.contacts[0].contactuuid;
        }

        // Grab the contact and company names
        if (company.contacts.length > 0) {
          contactname = company.contacts[0].firstname + " " + company.contacts[0].lastname;
        }
        companyname = company.companyname;

        // If this is a purchase, override the company uuid and name on each order item
        //    since we'll be ordering from that supplier
        let orderitems = this.state.order?.orderitems;
        if (this.props.appState.currentView === Constants.PURCHASE) {
          orderitems = this.state.order?.orderitems.map(item => {
            item.companyuuid = companyuuid;
            item.companyname = companyname;
            return item;
          });
        }

        // Update order in state with the new company
        if (Helper.isOrderView(this.props.appState.currentView)) {
          this.setState(
            prevState => ({
              contactuuid: contactuuid,
              order: {
                ...prevState.order,
                company: company,
                contactuuid: contactuuid,
                contactname: contactname,
                companyname: companyname,
                orderitems: orderitems,
              },
              error: null,
              downloading: false,
            }),
            () => {
              if (this.state.pickSalesperson) {
                this.setState({ pickSalesperson: false });
                if (company.contacts.length > 1) {
                  const possibleSalespersons = company.contacts.map(item => {
                    return {
                      id: item.contactuuid,
                      text: item.firstname + " " + item.lastname,
                    };
                  });
                  // Show a prompt asking which saleperson to use.
                  this.props.showOverlay({
                    type: Constants.OVERLAY_PICKER,
                    items: possibleSalespersons,
                    text: "Pick a Salesperson",
                    id: possibleSalespersons[0].id,
                    event: {},
                    hideCreate: true,
                    callback: this.handlePickSalesperson,
                  });
                } else {
                  //Hide overlay after database action is complete
                  this.props.hideOverlay();
                }
              } else {
                //Hide overlay after database action is complete
                this.props.hideOverlay();
                if (callback) {
                  callback(company);
                }
              }
            }
          );
        } else if (this.isBillingDetailView()) {
          // For the Billing view, update the company in state
          this.setState(
            {
              company: company,
              maastCustomer: {
                customer_email: company.contacts[0].email,
                customer_first_name: company.contacts[0].firstname,
                customer_last_name: company.contacts[0].lastname,
                customer_phone: company.contacts[0].mobilephone,
                customer_id: company.companyuuid,
              },
            },
            () => {
              //Hide overlay after database action is complete
              this.props.hideOverlay();
              if (callback) {
                callback(company);
              }
            }
          );
        }
      } else if (response.status === 404) {
        if (messageOn404) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "The selected item is no longer available. It may have been deleted.",
            callback: this.props.badBreadcrumb,
          });
        } else {
          if (callback) {
            callback(null);
          }
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the data.",
          });
        });
      }
    });
  };

  getContactList = (companytypes, resultList, searchkey) => {
    this.setState({
      downloading: true,
    });
    const params = { companytypes: companytypes };
    const url = Constants.URL_CONTACTS;
    if (searchkey) {
      params.searchkey = encodeURIComponent(searchkey.trim());
    }
    params.active = true;
    // Append the start and limit values
    params.searchstart = 0;
    params.searchlimit = 5;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        this.setState({
          [resultList]: response.body.records,
          // selectedListItems: [],
          error: null,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error searching the customers.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getProtectedCustomer = contactuuid => {
    this.setState({
      downloading: true,
    });
    const params = { contactuuid: contactuuid };
    const url = Constants.URL_COMPANIES;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.contacts?.length > 0) {
        this.selectCustomerListItem(response.body.contacts[0]);
        // If we had to go get the walkin customer, then store them in local storage
        localStorage.setItem(Constants.LOCAL_STORAGE_WALKIN_CUSTOMER, JSON.stringify(response.body));
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading contact.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getBillingPlan = billingplanuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make API call
    const url = Constants.URL_RECURRINGS;
    const params = { action: Constants.BILLING_PLAN, billingplanuuid: billingplanuuid };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        response.body.recur_amt = numeral(response.body.recur_amt).format(Constants.CURRENCY);
        //Update state with response, turn off downloading, execute deep copy
        this.setState(
          {
            billingplan: { ...this.state.billingplan, ...response.body },
            error: null,
            downloading: false,
            isNew: false,
          },
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 200 && response.body?.data?.length === 0) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the plan.",
          });
        });
      }
    });
  };

  getMaastBillingPlan = plan_id => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make API call
    const url = Constants.URL_BILLING;

    const filter = "plan_id,IS," + plan_id;
    const params = { filter: filter, action: Constants.BILLING_GET_PLAN };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.data?.length > 0) {
        let plan = Helper.normalizePlan(response.body.data[0]);
        //Update state with response, turn off downloading, execute deep copy
        this.setState(
          {
            plan: plan,
            error: null,
            downloading: false,
            isNew: false,
          },
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 200 && response.body?.data?.length === 0) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the plan.",
          });
        });
      }
    });
  };

  getSubscriptions = (customer_id, activeOnly = false, pageNumber = 0, callback = null) => {
    // Dear Future Russell... I'm sorry for this mess. I'm sure you can do better.
    if (this.props.appState.currentView === Constants.RECURRING) {
      this.doGetClerkHoundSubscriptions(customer_id, null, activeOnly, pageNumber, callback);
    } else if (this.props.appState.currentView === Constants.CUSTOMER) {
      // Check for ClerkHound subscriptions first, if the feature is enabled
      if (this.props.appState.features.includes(Constants.FEATURE_RECURRINGS)) {
        this.doGetClerkHoundSubscriptions(customer_id, null, activeOnly, pageNumber, () => {
          if (callback) {
            callback();
          } else {
            this.props.hideOverlay();
          }
          // If we don't find anything in ClerkHound, then check Maast, if the feature is enabled
          if (
            !this.state.subscriptions ||
            (this.state.subscriptions?.length === 0 && this.props.appState.features.includes(Constants.FEATURE_BILLING))
          ) {
            this.doGetMaastSubscriptions(customer_id, null, activeOnly, pageNumber, callback);
          }
        });
      } else if (this.props.appState.features.includes(Constants.FEATURE_BILLING)) {
        this.doGetMaastSubscriptions(customer_id, null, activeOnly, pageNumber, callback);
      }
    } else {
      this.doGetMaastSubscriptions(customer_id, null, activeOnly, pageNumber, callback);
    }
  };

  getSubscriptionsByPlanID = (plan_id, activeOnly = false, pageNumber = 0, callback = null) => {
    this.doGetMaastSubscriptions(null, plan_id, activeOnly, pageNumber, callback);
  };

  fixReverbRootCategory(listing) {
    const firstCategory = listing.categories[0].uuid;

    // Search the top-level categories for the first category returned
    let listingCategory = this.state?.listingCategories?.find(category => category.uuid === firstCategory);

    if (!listingCategory) {
      // The first category returned is a subcategory (or a bad category) so we need to
      // search for that subcategory in the subcategories and assign the parent category too
      this.state.listingCategories?.forEach(category => {
        let result = category.subcategories.find(subcategory => subcategory.uuid === firstCategory);
        if (result) {
          listingCategory = category.root_uuid;
        }
        return false;
      });
      // Add the root category to the front of the list
      listing.categories.unshift({
        uuid: listingCategory,
      });
    }
  }

  clearSubscriptions = callback => {
    this.setState({ subscriptions: null }, () => {
      if (callback) {
        callback();
      }
    });
  };

  doGetClerkHoundSubscriptions = (companyuuid, plan_id = null, activeOnly = false, pageNumber = 0, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make API call
    const url = Constants.URL_RECURRINGS;
    const searchlimit = 100;
    const searchstart = pageNumber * searchlimit;
    let params = {
      action: Constants.SUBSCRIPTION,
      companyuuid: companyuuid,
      searchlimit: searchlimit,
      searchstart: searchstart,
      filtertype: activeOnly ? Constants.TAB_ACTIVE : Constants.TAB_ALL,
    };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.records) {
        let subscriptions = response.body.records ?? [];
        const totalRecords = response.body.count;
        let overdueTotalAmount = numeral(0);
        subscriptions = subscriptions.map(subscription => {
          // If this is a list of subs for a customer, then accummulate the overdue total
          if (companyuuid) {
            overdueTotalAmount = overdueTotalAmount.add(subscription.amt_overdue_total);
          }
          // all subscriptions should start out as collapsed, not in edit mode, and not new
          subscription.expanded = false;
          subscription.editMode = false;
          subscription.isNew = false;
          // Format the amount fields
          subscription.overdue_balance = numeral(subscription.overdue_balance).format(Constants.CURRENCY);
          subscription.amt_overdue_total = numeral(subscription.amt_overdue_total).format(Constants.CURRENCY);
          subscription.recur_amt = numeral(subscription.recur_amt).format(Constants.CURRENCY);

          return subscription;
        });
        // If this is any page other than the first one, we need to append the results to the list
        if (pageNumber > 0) {
          subscriptions = this.state.subscriptions.concat(subscriptions);
        }

        this.setState(
          {
            subscriptions: subscriptions,
            error: null,
            downloading: false,
          },
          () => {
            // Only store the overdue total in state if this is a list of subs for a customer
            if (companyuuid) {
              this.setState({ overdueTotalAmount: overdueTotalAmount.value() });
            }
            if (callback) {
              callback();
            }
            if (totalRecords > subscriptions.length) {
              // We need to load the next page
              this.getSubscriptions(companyuuid, activeOnly, pageNumber + 1, callback);
            } else {
              // If there is a callback, it is responsible for hiding the overlay
              if (!callback) {
                this.props.hideOverlay();
              }
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the subscriptions.",
          });
        });
      }
    });
  };

  doGetMaastSubscriptions = (customer_id, plan_id = null, activeOnly = false, pageNumber = 0, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make API call
    const url = Constants.URL_BILLING;
    let params = {
      action: Constants.BILLING_GET_SUBSCRIPTION,
      filter: [],
      count: 100,
      page: pageNumber,
    };
    if (customer_id) {
      params.filter.push("customer_id,IS," + customer_id);
    } else if (plan_id) {
      params.filter.push("plan_id,IS," + plan_id);
    }
    if (activeOnly) {
      params.filter = params.filter.concat(["status,IN,[A,P,S]"]);
    }

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.code === 0) {
        const pages = response.body.totalPages;
        let subscriptions = response.body.data ?? [];
        let overdueTotalAmount = numeral(0);
        subscriptions = subscriptions.map(subscription => {
          // all subscriptions should start out as collapsed.
          subscription.expanded = false;
          // If this is a list of subs for a customer, then accummulate the overdue total
          if (customer_id) {
            overdueTotalAmount = overdueTotalAmount.add(subscription.amt_overdue_total);
          }
          // TODO: Does Helper.normalizePlan do this already?
          subscription.uuid = subscription.subscription_id;
          subscription.editMode = false;
          subscription.isNew = false;
          subscription.plan_type = subscription.plan_id === 0 ? Constants.PLAN_TYPE_INDIVIDUAL : Constants.PLAN_TYPE_GROUP;
          subscription.amt_tran = subscription.recur_amt;
          subscription.is_individual = subscription.plan_id === 0;
          subscription.plan_desc = subscription.plan_desc || subscription.plan_name;
          // Maast uses a "default" card for some customers, which is the card marked as "primary"
          if (!subscription.card_id) {
            const primaryCard = subscription.customer?.billing_cards?.find(card => card.primary);
            if (primaryCard) {
              subscription.card_id = primaryCard.card_id;
            }
          }
          return subscription;
        });
        // If this is any page other than the first one, we need to append the results to the list
        if (pageNumber > 0) {
          subscriptions = this.state.subscriptions.concat(subscriptions);
        }

        this.setState(
          {
            subscriptions: subscriptions,
            error: null,
            downloading: false,
          },
          () => {
            // Only store the overdue total in state if this is a list of subs for a customer
            if (customer_id) {
              this.setState({ overdueTotalAmount: overdueTotalAmount.value() });
            }
            if (callback) {
              callback();
            }
            if (pages > pageNumber + 1) {
              // We need to load the next page
              this.getSubscriptions(customer_id, activeOnly, pageNumber + 1, callback);
            } else {
              // If there is a callback, it is responsible for hiding the overlay
              if (!callback) {
                this.props.hideOverlay();
              }
            }
          }
        );
      } else if (response.status === 200 && !response.body?.data) {
        if (this.state.isNew) {
          this.setState({ error: null, loading: false, downloading: false });
          this.props.hideOverlay();
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "The selected item is no longer available. It may have been deleted.",
            callback: this.props.badBreadcrumb,
          });
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the subscriptions.",
          });
        });
      }
    });
  };

  getCustomFields = (companyuuid, subscriptionid, callback) => {
    // If we're on Billing, then use the Maast action, otherwise use the regular action
    const action = this.props.appState.currentView === Constants.BILLING ? Constants.CUSTOM_FIELD_MAAST : Constants.CUSTOM_FIELD;
    console.log(action);
    const url = Constants.URL_RECURRINGS;
    let params = {
      action: action,
      companyuuid: companyuuid,
      subscriptionid: subscriptionid,
    };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        let customFields = response.body ?? [];
        callback(customFields);
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      }
    });
  };

  getMaastCustomFields = (subscription_id, callback) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true, maastInvoices: [] });

    //set up to make API call
    const url = Constants.URL_BILLING;
    let params = {
      action: Constants.BILLING_GET_CUSTOM_FIELDS,
      subscription_id: subscription_id,
    };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.data) {
        let customFields = response.body.data ?? [];
        callback(customFields);
        this.props.hideOverlay();
      } else if (response.status === 200 && !response.body?.data) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the custom field data.",
          });
        });
      }
    });
  };

  cancelReport = () => {
    this.setState({ abortReport: true });
  };

  getMaastInvoicesForDates = (start, end, pageNumber = 0) => {
    if (this.state.abortReport) {
      this.setState({ abortReport: false, loading: false });
      this.props.hideOverlay();
      return;
    }

    if (pageNumber === 0) {
      this.setState({ downloading: true, maastInvoices: [], loading: true, abortReport: false }, () => {
        this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Downloading data...", cancelCallback: this.cancelReport });
      });
    }

    //set up to make API call
    const url = Constants.URL_BILLING;
    const filter = [`date_invoice,GREATER_EQUAL,${start}`, `date_invoice,LESS_EQUAL,${end}`];
    let params = {
      action: Constants.BILLING_GET_ALL_INVOICES,
      filter: filter,
      order_on: "date_invoice",
      order_by: "asc",
      count: 100,
      page: pageNumber,
    };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.data) {
        let maastInvoices = response.body.data ?? [];
        this.setState(
          prevState => ({
            maastInvoices: [...prevState.maastInvoices, ...maastInvoices],
            error: null,
            downloading: false,
          }),
          () => {
            // Get the next page of transactions
            if (response.body.totalPages > pageNumber + 1) {
              this.getMaastInvoicesForDates(start, end, pageNumber + 1);
            } else {
              this.setState({ abortReport: false, loading: false });
              this.props.hideOverlay();
            }
          }
        );
      } else if (response.status === 200 && !response.body?.data) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the invoices.",
          });
        });
      }
    });
  };

  getMaastInvoices = (customer_id, pageNumber = 0, append = false) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true, maastInvoices: append ? this.state.maastInvoices : [] });

    //set up to make API call
    const url = Constants.URL_BILLING;
    let params = {
      action: Constants.BILLING_GET_INVOICES,
      customer_id: customer_id,
      order_on: "date_invoice",
      order_by: "desc",
      count: this.props.appState.displaySettings.LIST_ROWS,
      page: pageNumber,
    };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.data) {
        let maastInvoices = response.body.data ?? [];
        // Add a description of the subscription, if we have subscriptions to look up
        if (this.state.subscriptions) {
          maastInvoices = maastInvoices.map(invoice => {
            // copy the invoice_id to the uuid attribute
            invoice.uuid = invoice.invoice_id;
            const sub = this.state.subscriptions.find(subscription => subscription.subscription_id === invoice.source_id);
            if (sub) {
              invoice.subscription_description = sub.plan_desc || sub.plan_name;
            } else {
              invoice.subscription_description = "N/A";
            }
            return invoice;
          });
        }
        if (append) {
          maastInvoices = this.state.maastInvoices.concat(maastInvoices);
        }
        this.setState(
          {
            maastInvoices: maastInvoices,
            maastInvoicesPagination: {
              totalPages: response.body.totalPages,
              pageNumber: pageNumber,
              totalRecords: response.body.totalRecords,
            },
            error: null,
            downloading: false,
          },
          () => {
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 200 && !response.body?.data) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the invoices.",
          });
        });
      }
    });
  };

  getTransactionsForCustomer = customer_id => {
    const filter = [`customer_id,IS,${customer_id}`];
    this.getMaastTransactions(filter);
  };

  getTransactionsForDates = (start, end) => {
    if (this.props.appState?.features.includes(Constants.FEATURE_RECURRINGS)) {
      this.getReport(Constants.REPORT_RECURRING_REVENUE, this.state.start, this.state.end);
    } else {
      const filter = [`tran_date,GREATER_EQUAL,${start}`, `tran_date,LESS_EQUAL,${end}`, `rcode,IN,[000,010]`];
      this.getMaastTransactions(filter);
    }
  };

  getAllTransactionsForDates = (start, end) => {
    const filter = [`tran_date,GREATER_EQUAL,${start}`, `tran_date,LESS_EQUAL,${end}`];
    this.getMaastTransactions(filter);
  };

  getMaastTransactions = (filter, pageNumber = 0) => {
    if (this.state.abortReport) {
      this.setState({ abortReport: false, loading: false });
      this.props.hideOverlay();
      return;
    }

    if (pageNumber === 0) {
      this.setState({ downloading: true, maastTransactions: [], loading: true, abortReport: false }, () => {
        this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Downloading data...", cancelCallback: this.cancelReport });
      });
    }

    //set up to make API call
    const url = Constants.URL_BILLING;
    let params = {
      action: Constants.BILLING_GET_TRANSACTIONS,
      filter: filter,
      order_on: "tran_date",
      order_by: "desc",
      count: 100,
      page: pageNumber,
    };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.data) {
        let maastTransactions = response.body.data ?? [];
        // Add a description of the subscription, if we have subscriptions to look up
        if (this.state.subscriptions) {
          maastTransactions = maastTransactions.map(transaction => {
            const sub = this.state.subscriptions.find(subscription => subscription.subscription_id === transaction.subscription_id);
            if (sub) {
              transaction.subscription_description = sub.plan_desc || sub.plan_name;
            } else {
              transaction.subscription_description = transaction.subscription_id;
            }
            return transaction;
          });
        }
        this.setState(
          prevState => ({
            maastTransactions: [...prevState.maastTransactions, ...maastTransactions],
            error: null,
            downloading: false,
          }),
          () => {
            // Get the next page of transactions
            if (response.body.totalPages > pageNumber + 1) {
              this.getMaastTransactions(filter, pageNumber + 1);
            } else {
              this.setState({ abortReport: false, loading: false });
              this.props.hideOverlay();
            }
          }
        );
      } else if (response.status === 200 && !response.body?.data) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the transactions.",
          });
        });
      }
    });
  };

  getCustomerFromVault = (customer_id, callback = null) => {
    if (this.props.appState?.clientSettings?.PAYMENT_GATEWAY === Constants.MAAST) {
      this.getCustomerFromVaultMaast(customer_id, callback);
    } else if (this.props.appState?.clientSettings?.PAYMENT_GATEWAY === Constants.NMI) {
      this.getCustomerFromVaultNMI(customer_id, callback);
    } else {
      console.log(Helper.clTimestamp(), "Unknown payment gateway");
    }
  };

  getCustomerFromVaultNMI = (customer_id, callback = null) => {
    this.getSubscriber(customer_id, callback);
  };

  getCustomerFromVaultMaast = (customer_id, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make API call
    const url = Constants.URL_BILLING;
    const params = {
      action: Constants.BILLING_GET_CUSTOMER,
      customer_id: customer_id,
    };

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.data) {
        let maastCustomer = { ...response.body.data, vaulted: true };
        let contactuuid = response.body.data?.reference_id;

        // Give each billing_card a uuid
        maastCustomer.billing_cards = maastCustomer.billing_cards.map(card => {
          return { ...card, uuid: card.card_id };
        });

        // Get the first contact from the company
        let company = this.state.company;
        let contacts = this.state.company?.contacts;
        let contact = this.state.company?.contacts?.[0];

        // You can only have a "new" ClerkHound contact with an "existing" Maast contact
        // when the contact only exists in the Maast database
        if (contact?.contactuuid === "new") {
          // TODO: Move this to be copied from selectedItem once these fields are added by Maast
          contact = Helper.deepCopy(contact);
          contact.mobilephone = maastCustomer.customer_phone;
          contact.email = maastCustomer.customer_email;

          // Update the "new" contact with the Maast contact's details
          contacts = [contact];

          // Update the company with the Maast company name
          company = { ...company, companyname: maastCustomer.customer_firm_name };
        }
        this.setState(
          {
            maastCustomer: maastCustomer,
            company: { ...company, contacts: contacts },
            contactuuid: contactuuid,
            error: null,
            downloading: false,
          },
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            if (callback) {
              callback(true);
            }
          }
        );
      } else if (response.status === 200 && !response.body.data) {
        if (this.state.isNew) {
          this.setState(prevState => ({
            error: null,
            downloading: false,
            maastCustomer: { ...prevState.maastCustomer, vaulted: false },
          }));
          this.props.hideOverlay();
          if (callback) {
            callback(false);
          }
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "The selected item is no longer available. It may have been deleted.",
            callback: this.props.badBreadcrumb,
          });
        }
      } else if (response.status === 404) {
        this.setState(
          prevState => ({
            error: null,
            downloading: false,
            maastCustomer: { ...prevState.maastCustomer, vaulted: false },
          }),
          () => {
            if (callback) {
              callback(false);
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the subscriber.",
          });
        });
      }
    });
  };

  getShippingProfiles = callback => {
    // Get Reverb api key from settings
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const url = "/api/shop";
    Helper.getData(url, {}, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      if (response.status >= 200 && response.status < 300 && response.body?.shipping_profiles) {
        response.body.shipping_profiles.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({ shippingProfiles: response.body.shipping_profiles }, () => {
          if (callback) {
            callback();
          }
        });
      } else if (response.status >= 400 && response.status < 500) {
        const error = new Helper.AppError(response.body?.message ?? "Reverb returned an authentication error.", response.status);
        this.setState({ shippingProfiles: error });
      } else {
        const error = new Helper.AppError(response.body?.message ?? "Reverb returned an error.", response.status);
        this.setState({ shippingProfiles: error });
      }
    });
  };

  getListingConditions = callback => {
    // Get Reverb api key from settings
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const url = "/api/listing_conditions";
    Helper.getData(url, {}, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      if (response.status >= 200 && response.status < 300 && response.body?.conditions) {
        this.setState({ listingConditions: response.body.conditions }, () => {
          if (callback) {
            callback();
          }
        });
      } else {
        this.setState({ listingConditions: null });
      }
    });
  };

  getListingCategories = callback => {
    // Get Reverb api key from settings
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const url = "/api/categories";
    Helper.getData(url, {}, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      if (response.status >= 200 && response.status < 300 && response.body?.categories) {
        this.setState({ listingCategories: response.body.categories }, () => {
          if (callback) {
            callback();
          }
        });
      } else {
        this.setState({ listingCategories: null });
      }
    });
  };

  getReverbListing = storesku => {
    // Get Reverb api key from settings
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const url = "/api/my/listings";
    const params = {
      sku: storesku,
      state: Constants.ALL,
    };
    Helper.getData(url, params, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      if (response.status >= 200 && response.status < 300 && response.body?.listings && response.body.listings.length > 0) {
        const listing = response.body.listings[0];
        this.fixReverbRootCategory(listing);

        this.setState(prevState => ({
          product: {
            ...prevState.product,
            reverbListing: listing,
          },
        }));
      } else {
        this.setState({ reverbListing: null });
      }
    });
  };

  getReverbShippingProviderList = callback => {
    // Get Reverb api key from settings
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const url = "/api/shipping/providers";
    Helper.getData(url, {}, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      if (response.status >= 200 && response.status < 300 && response.body?.shipping_providers && response.body.shipping_providers.length > 0) {
        const providers = response.body.shipping_providers.map(provider => {
          return {
            id: provider.name,
            text: provider.name,
          };
        });
        this.setState({ reverbShippingProviders: providers }, () => {
          if (callback) {
            callback();
          }
        });
      } else {
        this.setState({ reverbShippingProviders: [] });
      }
    });
  };

  // Fires when a supplier is selected in the search widget
  // Replaces the cost and SKU on an order item with the selected supplier's cost and SKU
  getProductSuppliers = productuuids => {
    if (!productuuids || productuuids.length === 0) {
      return;
    }
    this.setState({
      downloading: true,
    });
    const params = { productuuids: productuuids };
    const url = Constants.URL_SUPPLIERS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        const suppliers = response.body.records;
        let updatedOrderItems = [];
        const orderitems = this.state.order?.orderitems.map(item => {
          const supplier = suppliers.find(
            supplier => supplier.productuuid === item.productuuid && supplier.companyuuid === this.state.order?.company?.companyuuid
          );
          if (supplier) {
            item.cost = numeral(supplier.cost).format(Constants.CURRENCY);
            item.sku = supplier.sku;
            updatedOrderItems.push(item);
          }
          return item;
        });
        this.setState(
          prevState => ({
            order: Helper.newOrderRecalculate({ ...prevState.order, orderitems: orderitems }),
          }),
          () => {
            if (!this.state.isNew && updatedOrderItems.length > 0) {
              this.putOrderItems(this.state.order, updatedOrderItems);
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error retrieving the product supplier list.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getSMSContacts = (companyuuid, callback) => {
    const params = {
      action: Constants.SMS_ACTION_GET_CONTACTS,
      companyuuid: companyuuid,
      includeblocked: false,
      searchstart: 0,
      searchlimit: this.state.displaySettings.FOLDER_ROWS,
      messagesearchlimit: this.state.displaySettings.FOLDER_ROWS,
    };
    const url = Constants.URL_SMS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        callback(response);
      } else {
        this.setState({ error: "Failed to load" });
      }
    });
  };

  getAssociates = callback => {
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Loading associates..." });
    const url = Constants.URL_USERS;
    Helper.getData(url, {}).then(response => {
      if (response.status === 200 && response.body.records) {
        if (response.body.records.length === 0) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There are no associates to display.",
          });
        } else {
          callback(response.body.records);
        }
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the associates.",
          });
        });
      }
    });
  };

  getProductSuggestions = () => {
    const url = Constants.URL_PRODUCTS;
    const params = { action: Constants.ACTION_SUGGESTIONS };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState({ productSuggestions: response.body });
      }
    });
  };

  getCampaign = campaignuuid => {
    this.props.showOverlay();
    this.setState({ downloading: true });
    const url = Constants.URL_CAMPAIGNS;
    const params = { campaignuuid: campaignuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        const campaign = response.body;

        this.setState(
          prevState => ({ campaign: campaign, loading: false }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the campaign.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getCampaigns = (filtertype = null, callback = null) => {
    this.setState({ downloading: true });
    const url = Constants.URL_CAMPAIGNS;
    const params = {};
    if (filtertype !== null) {
      params.filtertype = filtertype;
    }
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        const campaigns = response.body.records;
        this.setState({ campaigns: campaigns, loading: false }, () => {
          if (callback) {
            callback();
          }
        });
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the campaigns.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getProspect = marketingprospectuuid => {
    this.props.showOverlay();
    this.setState({ downloading: true });
    const url = Constants.URL_CAMPAIGNS;
    const params = { marketingprospectuuid: marketingprospectuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({ prospect: response.body, company: { ...prevState.company, contacts: [response.body] }, loading: false }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the prospect.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getTagSuggestions = (reftype, callback) => {
    const url = Constants.URL_TAGS;
    const data = { action: Constants.ACTION_SUGGESTIONS, reftype: reftype };
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({ suggestions: { ...prevState.suggestions, [reftype]: response.body } }),
          () => {
            callback(response.body);
          }
        );
      }
    });
  };

  getSubscriber = (companyuuid, callback = null) => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Loading customer data...",
    });
    this.setState({ downloading: true });
    const url = Constants.URL_RECURRINGS;
    const params = {
      action: Constants.SUBSCRIBER,
      companyuuid: companyuuid,
      includeplans: true,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        const company = Helper.moveContactToFront(this.state.contactuuid, response.body);
        let overdueTotalAmount = numeral(0);
        // Format the subscription amounts & calculate the overdue total
        let subscriptions = company.subscriptions?.map(sub => {
          sub.overdue_balance = numeral(sub.overdue_balance).format(Constants.CURRENCY);
          sub.recur_amt = numeral(sub.recur_amt).format(Constants.CURRENCY);
          sub.amt_overdue_total = numeral(sub.amt_overdue_total).format(Constants.CURRENCY);
          overdueTotalAmount = overdueTotalAmount.add(sub.amt_overdue_total);
          return sub;
        });
        const maastCustomer = company.maastCustomer;
        const plans = company.plans;
        // Remove subscriptions, maastCustomer, and plans from the company object
        delete company.subscriptions;
        delete company.maastCustomer;
        delete company.plans;

        // If a subscription has already been selected, we need to add the subscription to the list of subscriptions that came back from getSubscriber
        const newSubscription = this.state.subscriptions?.length > 0 ? this.state.subscriptions[0] : null;
        if (newSubscription && !subscriptions.find(sub => sub.subscription_id === newSubscription.subscription_id)) {
          subscriptions.unshift(newSubscription);
        }

        // If we selected a subscription from the list, move that subscription to the front
        if (this.props.selectedItem?.type === Constants.SUBSCRIPTION && this.props.selectedItem?.subscriptionuuid) {
          subscriptions = Helper.moveSubscriptionToFront(subscriptions, this.props.selectedItem.subscriptionuuid);
        }

        // We want to hide inactive subscriptions if there is at least one active subscription and the selected item that brought us here is active
        const hideInactiveSubscriptions =
          subscriptions.some(sub => Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(sub.status)) &&
          Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(this.props.selectedItem?.status);

        this.setState(
          {
            company: company,
            subscriptions: subscriptions,
            maastCustomer: maastCustomer,
            billingPlans: plans?.records ?? [],
            overdueTotalAmount: overdueTotalAmount.value(),
            isNew: false,
            loading: false,
            hideInactiveSubscriptions: hideInactiveSubscriptions,
          },
          () => {
            // If the selected customer does not have an email address,
            // then show a prompt that further editing is blocked until an email address is added
            if (!company.contacts[0].email) {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "An email address is required before a subscription can be created.",
              });
            } else {
              this.props.hideOverlay();
            }
            if (callback) {
              callback(true);
            }
          }
        );
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the subscriber.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  putReverbListing = product => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Sending product data...",
    });
    this.setState({ downloading: true });

    const data = {
      categories: product.reverbListing.categories,
      condition: { uuid: product.reverbListing.condition.uuid },
      description: product.longdescription,
      has_inventory: true,
      inventory: numeral(product.inventory).format(Constants.INTEGER_NO_COMMA),
      make: product.make,
      model: product.model,
      photos: product.photos.map(photo => {
        return photo.photourl;
      }),
      price: {
        amount: "" + numeral(product.sellprice).format(Constants.CURRENCY_NO_COMMA),
        currency: "USD",
      },
      publish: true,
      shipping_profile_id: product.reverbListing.shipping_profile_id,
      sku: product.storesku,
      title: product.productname,
      upc_does_not_apply: product.upc ? "false" : "true",
      upc: product.upc,
    };
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const url = "/api/listings/" + product.reverbListing.id;
    Helper.putData(url, data, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      this.setState({ downloading: false });
      if (response.status >= 200 && response.status < 300 && response.body) {
        if (response.body.message) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Reverb says " + response.body.message,
          });
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Product successfully updated on Reverb.",
          });
        }
      } else if ((response.status >= 400 || response.status < 500) && response?.body?.message) {
        console.log(Helper.clTimestamp(), response);
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Reverb says '" + response.body.message + "'",
        });
      } else {
        console.log(Helper.clTimestamp(), response);
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error updating product data on the Reverb servers.",
        });
      }
    });
  };

  postReverbListing = product => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Sending product data...",
    });
    this.setState({ downloading: true });

    const data = {
      categories: product.reverbListing.categories,
      condition: { uuid: product.reverbListing.condition.uuid },
      description: Helper.ln2br(product.longdescription, false),
      has_inventory: true,
      inventory: "" + numeral(product.inventory).format(Constants.INTEGER_NO_COMMA),
      make: product.make,
      model: product.model,
      photos: product.photos.map(photo => {
        return photo.photourl;
      }),
      price: {
        amount: "" + numeral(product.sellprice).format(Constants.CURRENCY_NO_COMMA),
        currency: "USD",
      },
      publish: true,
      shipping_profile_id: product.reverbListing.shipping_profile_id,
      sku: product.storesku,
      title: product.productname,
      upc_does_not_apply: product.upc ? "false" : "true",
      upc: product.upc,
    };
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const url = "/api/listings";
    Helper.postData(url, data, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      this.setState({ downloading: false });
      if (response.status >= 200 && response.status < 300 && response.body?.listing) {
        this.fixReverbRootCategory(response.body.listing);

        this.setState(prevState => ({
          product: { ...prevState.product, reverbListing: response.body.listing },
        }));
        if (response.body.message) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Reverb says " + response.body.message,
          });
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Product successfully listed on Reverb.",
          });
        }
      } else if ((response.status >= 400 || response.status < 500) && response?.body?.message) {
        console.log(Helper.clTimestamp(), response);
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Reverb says '" + response.body.message + "'",
        });
      } else {
        console.log(Helper.clTimestamp(), response);
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error sending product data to the Reverb servers.",
        });
      }
    });
  };

  postReverbShipment = (provider, trackingNumber) => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Notifying Reverb...",
    });
    this.setState({ downloading: true });

    // Strip the shipper type from the end of the tracking number
    const trackingNumberParts = trackingNumber.split(" ");
    if (trackingNumberParts.length > 1) {
      trackingNumber = trackingNumberParts[0];
    }

    const data = {
      provider: provider,
      tracking_number: trackingNumber,
      send_notification: true,
    };
    const reverbapikey = "Bearer " + this.props.appState?.thirdparty?.reverbapikey;
    const headers = {
      Authorization: reverbapikey,
      "Content-Type": "application/hal+json",
      "Accept-Version": "3.0",
      Accept: "application/hal+json",
    };
    const orderNumber = this.state.order?.externalid;
    const url = `/api/my/orders/selling/${orderNumber}/ship`;
    Helper.postData(url, data, this.props.appState.thirdparty.reverbapiurl, headers).then(response => {
      this.setState({ downloading: false });
      if (response.status >= 200 && response.status < 300) {
        if (response.body.message) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Reverb says " + response.body.message,
          });
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Shipment successfully sent to Reverb.",
          });
        }
      } else if (response.status === 403) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Unable to notify Reverb of status update.\nPlease check your Reverb API key\nand ensure it has the 'write_orders' scope assigned.",
        });
      } else if ((response.status >= 400 || response.status < 500) && response?.body?.message) {
        console.log(Helper.clTimestamp(), response);
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Reverb says '" + response.body.message + "'",
        });
      } else {
        console.log(Helper.clTimestamp(), response);
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error sending shipping data to the Reverb servers.",
        });
      }
    });
  };

  putCartloomProduct = product => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: product.externalid ? "Sending request to unpublish..." : "Sending product data to Cartloom...",
    });
    this.setState({ downloading: true });

    // Determine if we're publishing or unpublishing
    const action = product.externalid ? "unpublished" : "published";
    const data = {
      productuuid: product.productuuid,
      action: product.externalid ? Constants.ACTION_UNPUBLISH : Constants.ACTION_PUBLISH,
    };
    const url = Constants.URL_PRODUCTS;
    Helper.putData(url, data).then(response => {
      this.setState({ downloading: false });
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({ product: { ...prevState.product, ...response.body } }),
          () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: `Product successfully ${action}.`,
            });
          }
        );
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error processing your request.\nPlease try again later.",
        });
      }
    });
  };

  // There is another copy of this function in Import.jsx for importing products
  postProduct = (product, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    let data = Helper.deepCopy(product);
    data.sellprice = numeral(product.sellprice).value();
    data.inventory = numeral(product.inventory).value() ?? 0;
    data.cost = numeral(product.cost).value() ?? 0;
    data.maxdiscount = numeral(product.maxdiscount ?? 0).value();
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Format the product values for display
        const product = {
          ...response.body,
          sellprice: numeral(response.body.sellprice).format(Constants.CURRENCY),
          cost: numeral(response.body.cost).format(Constants.CURRENCY),
          inventory: numeral(response.body.inventory).format(Constants.DECIMAL_VALUE),
          maxdiscount: Helper.formatPercent(response.body.maxdiscount),
        };
        if (callback) {
          callback(product);
        }
      } else if (response.status === 409) {
        // Duplicate UPC, revert to previous value
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Duplicate UPC value.",
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the product.",
          });
        });
      }
    });
  };

  postContactFromProspect = marketingprospectuuid => {
    //Protect screen during downloading data
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Creating customer..." });
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CAMPAIGNS;
    const data = { marketingprospectuuid: marketingprospectuuid };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.company) {
        // Display confirmation message
        const prospect = response.body;
        this.maybeTagCustomer(
          prospect?.company,
          "Customer successfully created.\nEnter a tag to apply to this customer for follow-up.\n(Leave blank to skip)",
          prospect?.customertags,
          () => {
            this.setState({ prospect: prospect });
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the contact.",
          });
        });
      }
    });
  };

  postResendCampaignLinkToProspect = marketingprospectuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CAMPAIGNS;
    const data = {
      marketingprospectuuid: marketingprospectuuid,
      action: Constants.RESEND,
    };
    Helper.postData(url, data).then(response => {
      this.setState({ downloading: false }, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: response.status === 200 ? "Link resent." : "Error resending link.",
        });
      });
    });
  };

  postContact = (contact, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CONTACTS;
    const companyuuid = this.state.order?.company.companyuuid ? this.state.order?.company.companyuuid : "";
    const companyname = this.state.order?.company.companyname ? this.state.order?.company.companyname : "";
    let companytype = Constants.SUPPLIER_COMPANY;
    if (this.props.appState.currentView !== Constants.PURCHASE && this.props.appState.currentView !== Constants.SUPPLIER) {
      companytype = companyname ? Constants.CUSTOMER_COMPANY : Constants.CUSTOMER_FAMILY;
    }
    contact = {
      ...contact,
      companyuuid: companyuuid,
      companyname: companyname,
      companytype: companytype,
      returncompany: true, // Return the whole company record from POST contact
    };
    Helper.postData(url, contact).then(response => {
      if (response.status === 200 && response.body) {
        let company = response.body;
        let contactuuid = this.state.contactuuid;
        let contactname = this.state.order?.contactname;
        // If contact was added to existing company, maintain the editmode flag
        if (this.state.order?.company && this.state.order?.company.companyuuid) {
          company.contacts = company.contacts.map(c => {
            const contactsfromstate = this.state.order?.company.contacts.filter(cs => cs.contactuuid === c.contactuuid);
            // If this is the newly created contact (we won't be able to find it by contactuuid),
            if (contactsfromstate.length === 0) {
              c.editmode = true;
            } else {
              // Copy the edit mode flag from state
              c.editmode = contactsfromstate[0].editmode;
            }
            return c;
          });
          // Move the contact associated with this order to the front of the list
          company = Helper.moveContactToFront(this.state.contactuuid, response.body);
        } else {
          // If the contact & company was just posted to the database
          contactuuid = company.contacts[0].contactuuid;
          contactname = company.contacts[0].firstname + " " + company.contacts[0].lastname;
          company.contacts[0].editmode = false;
        }
        // Update the state
        this.setState(
          prevState => ({
            contactuuid: contactuuid,
            order: {
              ...prevState.order,
              company: company,
              contactname: contactname,
              contactuuid: contactuuid,
            },
          }),
          // After the state is updated, perform some cleanup work and (maybe) POST the order
          () => {
            if (callback) {
              // If there is a callback, it MUST hide the overlay and reset the error/downloading flags
              callback();
            } else {
              this.setState({ downloading: false, error: null });
              this.props.hideOverlay();
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the contact.",
          });
        });
      }
    });
  };

  // Create a new order record in the database
  postOrder = () => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDERS;
    const contactuuid = this.state.contactuuid ? this.state.contactuuid : this.state.order?.contactuuid;
    const order = {
      // Contact from the top of the order.
      // When order, repair, quote, invoice, contact = customer
      // When purchase, contact = supplier
      contactuuid: contactuuid,
      orderitems: this.state.order?.orderitems.map(item => {
        item = Helper.deepCopy(item);
        item.orderitemuuid = null;
        item.quantity = numeral(item.quantity ?? 0).value() ?? 0;
        item.quantityreceived = numeral(item.quantityreceived ?? 0).value() ?? 0;
        item.sellprice = numeral(item.sellprice ?? 0).value() ?? 0;
        item.discount = numeral(item.discount ?? 0).value() ?? 0;
        item.cost = numeral(item.cost ?? 0).value() ?? 0;
        return item;
      }),
      repairitems: this.state.order?.repairitems
        ? this.state.order?.repairitems.filter(item => {
            return item.model || item.serialnumber || item.description || item.technician || item.family;
          })
        : [],
      payments: this.state.order?.payments ?? [],
      ordertype: this.props.ordertype,
      ordersubtype: this.state.order?.ordersubtype,
      ponumber: this.state.order?.ponumber,
      duedatetime: Helper.formatDateTimeForWebService(this.state.order?.duedatetime),
      notes: this.state.order?.notes,
      orderstatus: Constants.ORDER_STATUS_OPEN,
      sourceorderuuid: this.state.order?.sourceorderuuid ? this.state.order?.sourceorderuuid : null,
    };

    // For refund invoices, remove zero-quantity items when saving and (optionally) copy the subscription ID
    if (Helper.isReturn(order)) {
      order.orderitems = order.orderitems.filter(item => numeral(item.quantity).value() !== 0);
      if (this.state.order?.subscription_id) {
        order.subscription_id = this.state.order?.subscription_id;
      }
    }

    Helper.postData(url, order).then(response => {
      if (response.status === 200 && response.body) {
        // Move contact to front
        response.body.company = Helper.moveContactToFront(contactuuid, response.body.company);
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              ...Helper.formatOrder(response.body),
            },
            // Clear the order item selection on save since the order items from the API will not exactly match the order items from the draft order (uuid's will be different)
            selectedOrderItems: [],
            // Switch back to "normal" invoice view after save (even for returns)
            isReturn: false,
            // returnView: false,
            downloading: false,
            error: null,
            isNew: false,
          }),
          () => {
            // Create Barcode for Order
            const order_number = Helper.getOrderNumber(this.state.order.ordertype, this.state.order.ordersubtype, this.state.order.ordernumber);
            try {
              JsBarcode("#barcode", order_number, {
                width: 1.75,
                height: 15,
                displayValue: false,
                margin: 2,
              });
            } catch (error) {
              console.log(Helper.clTimestamp(), error);
            }
            try {
              JsBarcode("#barcodeNarrow", order_number, {
                width: 1.75,
                height: 15,
                displayValue: true,
                font: "Poppins - Regular",
                textMargin: 1,
                fontSize: 10,
              });
            } catch (error) {
              console.log(Helper.clTimestamp(), error);
            }
            this.props.updateBreadcrumb({ selectedItem: this.state.order });
            // Create function to handle the "edit order" action, which might happen now or after a message
            // TODO: This is triggering a second getData() request to /orders. Fix it, Future Russell!
            const editOrder = () => {
              // Clear any messages before proceeding
              this.setState(
                prevState => ({ order: { ...prevState.order, message: null } }),
                () => {
                  this.props.handleEditItem(this.props.appState.currentView, this.props.appState.currentMenu, response.body);
                }
              );
            };
            if (response.body?.message) {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: response.body.message,
                callback: editOrder,
              });
            } else {
              this.props.hideOverlay();
              editOrder();
            }
          }
        );
      } else if (response.status === 409) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the order.",
          });
        });
      }
      this.postingOrder = false;
    });
  };

  // Writes a new repair to the database and updates the order,
  //  finally storing the order (with orderitems) in the state
  postRepairItem = (order, repairitem, index) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_REPAIR_ITEMS;
    const data = {
      ...repairitem,
      orderuuid: order.orderuuid,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Get the new repair item uuid
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              ...Helper.formatOrder(response.body),
            },
            downloading: false,
            error: null,
            isNew: false,
          }),
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error adding the item.",
          });
        });
      }
    });
  };

  // Writes all ordered items to the database and updates the order,
  //  finally storing the order (with orderitems) in the state
  postOrderItems = (order, orderitems) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDER_ITEMS;
    const data = {
      ordertype: this.props.ordertype,
      orderuuid: order.orderuuid,
      orderitems: orderitems.map(product => {
        let item = {
          productuuid: product.productuuid,
          storesku: product.storesku || "",
          upc: product.upc || "",
          productname: product.productname,
          companyuuid: product.companyuuid,
          companyname: product.companyname,
          quantity: numeral(product.quantity).value(),
          sellprice: numeral(product.sellprice).value(),
          discount: numeral(product.discount).value(),
          cost: numeral(product.cost).value(),
          commission: product.commission,
          associate: this.props.appState?.salesperson,
          taxable: product.taxable,
          lineitemstatus: product.lineitemstatus,
          rootorderitemuuid: null,
          parentorderitemuuid: null,
        };
        if (product.giftCardNumber) {
          item.giftCardNumber = product.giftCardNumber;
        }
        return item;
      }),
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body && response.body.orderitems) {
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              ...Helper.formatOrder(response.body),
            },
            isNew: false,
            error: null,
            downloading: false,
          }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 409) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the order.",
          });
        });
      }
    });
  };

  postShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = "/addresses";
    const address = {
      ...Helper.getContactByUUID(this.state.order?.company, contactuuid).shippingaddress,
      contactuuid: contactuuid,
    };
    Helper.postData(url, address).then(response => {
      if (response.status === 200 && response.body) {
        const contacts = this.state.order?.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "POST" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the shipping address.",
          });
        });
      }
    });
  };

  postPhoto = (productuuid, photouuid, callback) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    const data = {
      productuuid: productuuid,
      photouuid: photouuid,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        callback(true, response.body);
      } else {
        callback(false, null);
      }
      this.setState({ downloading: false });
      this.props.hideOverlay();
    });
  };

  postBillingPlan = () => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    const data = {
      ...this.props.params,
      plan: {
        plan_name: this.state.billingplan.plan_name,
        plan_frequency: this.state.billingplan.plan_frequency,
        plan_duration: this.state.billingplan.plan_duration,
        recur_amt: this.state.billingplan.recur_amt,
        tran_currency: "840",
      },
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          {
            billingplan: { ...this.state.billingplan, ...response.body, recur_amt: numeral(response.body.recur_amt).format(Constants.CURRENCY) },
            downloading: false,
            isNew: false,
          },
          () => {
            this.props.updateBreadcrumb({ selectedItem: this.state.billingplan });
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the plan.",
          });
        });
      }
    });
  };

  postMaastBillingPlan = retries => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    const data = {
      ...this.props.params,
      action: Constants.BILLING_POST_PLAN,
      plan_code: this.state.plan.plan_type + Helper.uuid(15),
      plan_name: this.state.plan.plan_desc,
      plan_desc: this.state.plan.plan_desc,
      plan_frequency: this.state.plan.plan_frequency,
      plan_duration: this.state.plan.plan_duration,
      amt_tran: this.state.plan.amt_tran,
      tran_currency: "840",
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.data) {
        //Update state with response, turn off downloading
        const plan = {
          ...this.state.plan,
          ...response.body.data,
          is_individual: response.body.data.plan_code.slice(0, 1) === Constants.PLAN_TYPE_INDIVIDUAL,
        };
        this.setState({ plan: plan, downloading: false, isNew: false }, () => {
          this.props.updateBreadcrumb({ selectedItem: this.state.plan });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else if (
        response.status === 400 &&
        response.body?.code === 2 &&
        response.body?.data?.plan_code === "Duplicate Plan Code not allowed" &&
        retries > 0
      ) {
        // Try again with a new plan code
        this.postMaastBillingPlan(retries - 1);
      } else if (
        response.status === 400 &&
        response.body?.code === 2 &&
        response.body?.data?.plan_code === "Duplicate Plan Code not allowed" &&
        retries === 0
      ) {
        // Wow! How did we generate so many duplicate plan codes?
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the plan.",
          });
        });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the plan.",
          });
        });
      }
    });
  };

  // Only called from Recurring Billing (not Maast Billing)
  postSubscription = (subscription, company, maastCustomer) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;

    // Remove the plans from the company object
    company = Helper.deepCopy(company);
    delete company.plans;

    // Make sure the date_next is populated from the date_start, if needed
    if (!subscription.date_next) {
      subscription.date_next = subscription.date_start;
    }

    const data = {
      ...this.props.params,
      action: Constants.SUBSCRIPTION,
      subscription: subscription,
      company: company,
      maastcustomer: maastCustomer,
      customfields: subscription.custom_fields,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        const company = Helper.moveContactToFront(this.state.contactuuid, response.body);
        const subscriptions = company.subscriptions?.map(sub => {
          sub.recur_amt = numeral(sub.recur_amt).format(Constants.CURRENCY);
          return sub;
        });
        // Move the newly added subscription to the front of the list
        subscriptions.unshift(subscriptions.pop());

        const maastCustomer = company.maastCustomer;
        const plans = company.plans;
        // Remove subscriptions, maastCustomer, and plans from the company object
        delete company.subscriptions;
        delete company.maastCustomer;
        delete company.plans;
        this.setState(
          {
            company: company,
            subscriptions: subscriptions?.map(sub => {
              // Format the amount fields
              sub.overdue_balance = numeral(sub.overdue_balance).format(Constants.CURRENCY);
              sub.amt_overdue_total = numeral(sub.amt_overdue_total).format(Constants.CURRENCY);
              sub.recur_amt = numeral(sub.recur_amt).format(Constants.CURRENCY);
              return sub;
            }),
            maastCustomer: maastCustomer,
            billingPlans: plans?.records ?? [],
            isNew: false,
            loading: false,
            downloading: false,
          },
          () => {
            if (this.refreshFoldersCallback) {
              this.refreshFoldersCallback();
            }
            if (response.body.message) {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: response.body.message,
              });
            } else {
              this.props.hideOverlay();
            }
          }
        );
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message || "There was an error adding the subscription.",
          });
        });
      }
    });
  };

  // When you post a subscription, Maast (currently) ignores the plan_name and plan_desc fields for GROUP
  // plans and uses the values on the plan. We can update this value later with a PUT without affecting the plan
  postMaastSubscription = (subscription, is_new_customer, customer_id, contact = null, billing_cards = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // Individual subscriptions must have the plan code/ID removed
    if (subscription.is_individual) {
      delete subscription.plan_code;
      delete subscription.plan_id;
    }

    //set up to make database call
    const url = Constants.URL_BILLING;
    const data = {
      ...this.props.params,
      action: Constants.BILLING_POST_SUBSCRIPTION,
      ref_uuid: customer_id,
      ...subscription,
    };
    if (is_new_customer) {
      data.customer = {
        customer_id: customer_id,
        customer_first_name: contact.firstname,
        customer_last_name: contact.lastname,
        customer_phone: contact.mobilephone,
        customer_email: contact.email,
        reference_id: contact.contactuuid,
        billing_cards: billing_cards,
      };
    } else {
      data.customer_id = customer_id;
    }
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.data) {
        const data = response.body.data;
        this.setState(
          prevState => ({
            company: { ...prevState.company, vaulted: true },
            maastCustomer: {
              ...prevState.maastCustomer,
              ...data.customer,
              vaulted: true,
            },
            subscriptions: prevState.subscriptions.map(sub => {
              // Replace the subscription being edited with the new data
              if (sub.editMode) {
                return {
                  ...data,
                  amt_tran: data.recur_amt,
                  customer: null,
                  is_individual: data.plan_id === 0,
                };
              } else {
                return sub;
              }
            }),
            downloading: false,
            isNew: false,
          }),
          () => {
            this.props.updateBreadcrumb({ selectedItem: this.state.selectedSubscription });
            this.props.hideOverlay();
            if (response.body.note_created && !is_new_customer) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
          }
        );
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          let message = "There was an error adding the subscription.";
          if (response.body?.data && Object.keys(response.body?.data).length > 0) {
            message = "";
            Object.keys(response.body?.data).forEach(key => {
              message += response.body?.data[key] + "\n";
            });
          }
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: message,
          });
        });
      }
    });
  };

  // Called to vault a customer from the Customer Detail Screen
  postMaastCustomer = (customer_id, contact = null, billing_cards = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    let data = {
      action: Constants.BILLING_POST_CUSTOMER,
      customer_id: customer_id,
      customer_first_name: contact.firstname,
      customer_last_name: contact.lastname,
      customer_phone: contact.mobilephone,
      customer_email: contact.email,
      reference_id: contact.contactuuid,
      billing_cards: billing_cards,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.data) {
        const data = response.body.data;
        this.setState(
          prevState => ({
            company: { ...prevState.company, vaulted: true },
            maastCustomer: {
              ...prevState.maastCustomer,
              ...data,
              vaulted: true,
            },
            downloading: false,
          }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error adding the customer to the vault.",
          });
        });
      }
    });
  };

  postBillingSendInvoice = (invoice, email) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    let data = {
      invoice_id: invoice.invoice_id,
      email_address: [email],
    };
    if (invoice.date_sent) {
      data.action = Constants.BILLING_POST_RESEND_INVOICE;
    } else {
      data.action = Constants.BILLING_POST_SEND_INVOICE;
    }
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.code === 0) {
        this.setState(
          prevState => ({
            selectedInvoices: prevState.selectedInvoices.filter(i => i.invoice_id !== invoice.invoice_id),
            maastInvoices: prevState.maastInvoices.map(i => {
              if (i.invoice_id === invoice.invoice_id) {
                return {
                  ...i,
                  date_sent: i.date_sent || Helper.dateTimeAsString(),
                };
              } else {
                return i;
              }
            }),
          }),
          () => {
            if (this.state.selectedInvoices.length === 0) {
              this.setState({ downloading: false });
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "Invoice(s) sent successfully.",
              });
            }
          }
        );
      } else {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error resending invoice(s).",
          });
        });
      }
    });
  };

  postRefundTransaction = (transaction, amount, authtoken) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    let data = {
      action: Constants.BILLING_POST_REFUND_TRANSACTION,
      pgIdOrig: transaction.pg_id,
      amt_tran: numeral(amount).value(),
      authtoken: authtoken,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.rcode === Constants.QP_SALE_APPROVED) {
        this.setState(
          prevState => ({
            maastTransactions: prevState.maastTransactions.map(item => {
              if (item.pg_id === transaction.pg_id) {
                item.amt_refunded = numeral(item.amt_refunded).add(amount).value();
              }
              return item;
            }),
          }),
          () => {
            this.setState({ downloading: false }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: response.body.rmsg || "Refund request accepted",
              });
            });
          }
        );
      } else if (response.status !== 200 && response.body?.rmsg) {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.rmsg,
          });
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error refunding transaction.",
          });
        });
      }
    });
  };

  postBillingApplyInvoicePayment = (invoice_id, amt_paid, type, date_payment, description, callback) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    let data = {
      action: Constants.BILLING_POST_APPLY_INVOICE_PAYMENT,
      invoice_id: invoice_id,
      amt_paid: amt_paid,
      date_payment: Helper.formatDateForWebService(date_payment),
      type: type,
      description: description,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.data) {
        this.setState(
          prevState => ({
            selectedInvoices: prevState.selectedInvoices.filter(invoice => invoice.invoice_id !== invoice_id),
            overdueTotalAmount: Math.max(0, numeral(prevState.overdueTotalAmount).subtract(amt_paid).value()),
            maastInvoices: prevState.maastInvoices.map(invoice => {
              if (invoice.invoice_id === invoice_id) {
                invoice = {
                  ...invoice,
                  amt_balance: Math.max(0, numeral(invoice.amt_balance).subtract(amt_paid).value()),
                  amt_paid: amt_paid,
                  date_payment: response.body.data.date_payment,
                  payment_id: response.body.data.payment_id,
                  status: response.body.data.status,
                  type: type,
                  description: description,
                };
              }
              return invoice;
            }),
          }),
          () => {
            if (this.state.selectedInvoices.length === 0) {
              this.setState({ downloading: false });
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "Invoice(s) marked as paid.",
              });
              if (callback) {
                callback();
              }
            }
          }
        );
      } else {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error marking invoice(s) as paid.",
          });
        });
      }
    });
  };

  postStoredPaymentMaast = (company, contact, billing_cards, card_id) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    const data = {
      ...this.props.params,
      company: company,
      contact: contact,
      billing_cards: billing_cards,
      card_id: card_id,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Response is the updated subscriber object
        const company = Helper.moveContactToFront(this.state.contactuuid, response.body);
        const maastCustomer = response.body.maastCustomer;
        const plans = response.body.plans;
        let subscriptions = response.body.subscriptions;

        // Remove the billing cards, maastCustomer, plans, and subscriptions from the company object
        delete company.billing_cards;
        delete company.maastCustomer;
        delete company.plans;
        delete company.subscriptions;

        // If we're in the middle of creating a new subscription and a plan has been chosen
        // then do not lose the plan
        let newSubscriptions = this.state.subscriptions.filter(sub => sub.isNew);
        if (newSubscriptions.length > 0) {
          subscriptions = newSubscriptions.concat(subscriptions);
        }

        this.setState(
          {
            company: company,
            plans: plans,
            maastCustomer: maastCustomer,
            subscriptions: subscriptions,
            downloading: false,
          },
          () => {
            // If we are creating a new subscription and a plan has been chosen
            // and the subscription does not have a card_id,
            // then add the card to the subscription
            if (this.state.isNew && this.state.subscriptions.length > 0 && !this.state.subscriptions[0].card_id) {
              this.setState(prevState => ({
                subscriptions: prevState.subscriptions.map(sub => {
                  sub.card_id = card_id;
                  return sub;
                }),
              }));
            }
            this.props.hideOverlay();
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
          }
        );
      } else {
        this.setState({ downloading: false }, () => {
          let errors = "";
          for (let key in response.body?.data || {}) {
            errors += response.body.data[key] + "\n";
          }
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error adding the card.",
            bullets: errors,
          });
        });
      }
    });
  };

  postMaastStoredPayment = (customer_id, billing_first, billing_last, billing_zip, card_id, exp_date, masked_card_number) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    const data = {
      ...this.props.params,
      company: this.state.company,
      contact: this.state.company.contacts[0],
      billing_cards: [
        {
          card_id: card_id,
          card_number: masked_card_number,
          masked_card_number: masked_card_number,
          billing_first_name: billing_first,
          billing_last_name: billing_last,
          billing_zip: billing_zip,
        },
      ],
      card_id: card_id,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.billing_cards) {
        // Update state with response, turn off downloading
        const billing_cards = response.body.billing_cards;
        this.setState(
          prevState => ({
            maastCustomer: { ...prevState.maastCustomer, billing_cards: billing_cards },
            subscriptions: response.body.subscriptions,
            downloading: false,
          }),
          () => {
            // If we are creating a new subscription and a plan has been chosen
            // and the subscription does not have a card_id,
            // then add the card to the subscription
            if (this.state.isNew && this.state.subscriptions.length > 0 && !this.state.subscriptions[0].card_id) {
              this.setState(prevState => ({
                subscriptions: prevState.subscriptions.map(sub => {
                  sub.card_id = card_id;
                  return sub;
                }),
              }));
            }
            this.props.hideOverlay();
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
          }
        );
      } else {
        this.setState({ downloading: false }, () => {
          let errors = "";
          for (let key in response.body?.data || {}) {
            errors += response.body.data[key] + "\n";
          }
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error adding the card.",
            bullets: errors,
          });
        });
      }
    });
  };

  postMailProspect = (marketingprospectuuid, intro_paragraph, replyto = null, includeCampaignResponse = false, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Sending email..." });
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_MAIL;
    let data = {
      action: Constants.SEND_EMAIL,
      marketingprospectuuid: marketingprospectuuid,
      intro_paragraph: intro_paragraph || "",
      replyto: replyto,
      includeCampaignResponse: includeCampaignResponse,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200) {
        this.setState({ downloading: false }, () => {
          if (callback) {
            callback();
          } else {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Email sent successfully.",
              icon: <PaymentAcceptIcon />,
            });
          }
        });
      }
      if (response.status === 409) {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message || "Email address is on the 'do not email' list",
          });
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message || "There was an error sending the email.",
            icon: <PaymentDeclineIcon />,
          });
        });
      }
    });
  };

  postMailContact = (contactuuid, intro_paragraph, replyto = null, includePayLink = false, campaignuuid = null, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Sending email..." });
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_MAIL;
    let data = {
      contactuuid: contactuuid,
      intro_paragraph: intro_paragraph || "",
      replyto: replyto,
      includepaylink: includePayLink,
      campaignuuid: campaignuuid,
    };
    if (Helper.isOrderView(this.props.appState.currentView)) {
      data.action = Constants.SEND_ORDER;
      data.orderuuid = this.state.order?.orderuuid;
    } else {
      data.action = Constants.SEND_EMAIL;
    }
    Helper.postData(url, data).then(response => {
      if (response.status === 200) {
        this.setState({ downloading: false }, () => {
          if (callback) {
            callback();
          } else {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Email sent successfully.",
              icon: <PaymentAcceptIcon />,
            });
          }
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message || "There was an error sending the email.",
            icon: <PaymentDeclineIcon />,
          });
        });
      }
    });
  };

  postSMS = (smscontactuuid, message, contactuuid = null, callback = null) => {
    if (!smscontactuuid && !contactuuid) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Missing contact information. Please try again.",
      });
      return;
    }
    // Calling function should protect screen during function call
    // this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SMS;
    const data = {
      message: message,
    };
    if (smscontactuuid) {
      data.smscontactuuid = smscontactuuid;
    } else if (contactuuid) {
      data.contactuuid = contactuuid;
      data.consentee = this.props.appState.salesperson;
    }
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body.smscontactuuid) {
        this.setState({ downloading: false }, () => {
          // Callback is responsible for hiding the overlay
          if (callback) {
            callback(response.body.smscontactuuid);
          }
        });
      } else if (response.status === 400) {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message ?? "There was an error sending the message.",
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error sending the message.",
          });
        });
      }
    });
  };

  postCompanyRequestPaymentMethod = (companyuuid, contactuuid) => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Requesting link...",
    });
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const data = {
      companyuuid: companyuuid,
      contactuuid: contactuuid,
      action: Constants.ACTION_REQUEST_PAYMENT_METHOD,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Store the url and uuid in the company object
        this.setState(
          prevState => ({
            company: {
              ...prevState.company,
              vaultrequest: { uuid: response.body.uuid, url: response.body.url, contactuuid: contactuuid },
            },
            downloading: false,
            error: null,
          }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the link.",
          });
        });
      }
    });
  };

  // Update order details
  putOrder = (orderuuid, fieldname, value, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    if (["creationdatetime", "duedatetime"].includes(fieldname)) {
      value = Helper.formatDateTimeForWebService(value);
    }

    //set up to make database call
    const url = Constants.URL_ORDERS;
    const data = {
      orderuuid: orderuuid,
      ordertype: this.state.order?.ordertype,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        response.body.company.contacts = response.body.company.contacts.map(c => {
          const contactsfromstate = this.state.order?.company.contacts.filter(cs => cs.contactuuid === c.contactuuid);
          // If this is the newly created contact (we won't be able to find it by contactuuid),
          if (contactsfromstate.length === 0) {
            c.editmode = true;
          } else {
            // Copy the edit mode flag from state
            c.editmode = contactsfromstate[0].editmode;
          }
          return c;
        });
        // Move the selected contact to front of list in company, if necessary
        if (this.state.contactuuid) {
          response.body.company = Helper.moveContactToFront(this.state.contactuuid, response.body.company);
        }
        // Changing order data could impact totals so update state
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              ...Helper.formatOrder(response.body),
            },
            downloading: false,
            error: null,
          }),
          () => {
            this.props.hideOverlay();
            if (callback) {
              callback();
            }
          }
        );
      } else if (response.status === 409 && response.body.code === "orderstatus") {
        // Certain order status changes produces a conflict. No need to show an error message
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
        if (callback) {
          callback();
        }
      } else if (response.status === 409) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the order.",
          });
        });
      }
    });
  };

  // Update repair item details
  putRepairItem = (repairitemuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_REPAIR_ITEMS;
    const data = {
      repairitemuuid: repairitemuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state with response, turn off downloading
        this.setState({ downloading: false });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the item.",
          });
        });
      }
    });
  };

  // Update order item details
  putOrderItem = (order, orderitemuuid, fieldname, value, callback = null, returnOrder = true) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // Perform any required data conversions
    if (
      fieldname === "quantity" ||
      fieldname === "quantityreceived" ||
      fieldname === "sellprice" ||
      fieldname === "discount" ||
      fieldname === "cost"
    ) {
      value = numeral(value).value();
    }
    //set up to make database call
    const url = Constants.URL_ORDER_ITEMS;
    const data = {
      ordertype: this.props.ordertype,
      orderuuid: order.orderuuid,
      orderitemuuid: orderitemuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        if (returnOrder) {
          // Totals may have been updated
          // Plus we may need to clear some attributes of the associate, if it was updated
          const updatedOrder = Helper.formatOrder(response.body);
          this.setState(
            prevState => ({
              order: {
                ...prevState.order,
                ...updatedOrder,
                orderitems: updatedOrder.orderitems.map(item => {
                  // If associate was updated, clear the associateUpdating flag and reset the prev value
                  if (item.uuid === orderitemuuid && fieldname === "associate") {
                    item.associateUpdating = false;
                    item.prevAssociate = value;
                  }
                  return item;
                }),
              },
              downloading: false,
              error: null,
            }),
            () => {
              if (response.body.message) {
                this.props.showOverlay({
                  type: Constants.OVERLAY_MESSAGE,
                  text: response.body.message,
                });
              } else {
                this.props.hideOverlay();
              }
              if (callback) {
                callback();
              }
            }
          );
        } else {
          // Order Item is being returned so update it in state
          const updatedOrderItem = response.body;
          this.setState(
            prevState => ({
              order: {
                ...prevState.order,
                orderitems: prevState.order.orderitems.map(item => {
                  if (item.uuid === orderitemuuid) {
                    item = { ...item, ...updatedOrderItem };
                  }
                  return item;
                }),
              },
              downloading: false,
              error: null,
            }),
            () => {
              this.props.hideOverlay();
              if (callback) {
                callback();
              }
            }
          );
        }
      } else if (response.status === 404) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Unable to update the item. It may have been deleted or not saved.",
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the item.",
          });
        });
      }
    });
  };

  // Update order item statuses
  putOrderItemStatuses = (orderitemuuids, lineitemstatus, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDER_ITEMS;
    const data = {
      ordertype: this.state.order?.ordertype,
      orderuuid: this.state.order?.orderuuid,
      orderitemuuids: orderitemuuids,
      lineitemstatus: lineitemstatus,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Order may have been updated
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              ...Helper.formatOrder(response.body),
            },
            downloading: false,
            error: null,
          }),
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            if (callback) {
              callback();
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the items.",
          });
        });
      }
    });
  };

  // Update the order items' attributes in the database
  putOrderItems = (order, orderitems) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDER_ITEMS;
    const data = {
      ordertype: this.state.order?.ordertype,
      orderuuid: this.state.order?.orderuuid,
      orderitems: orderitems,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Order may have been updated
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              ...Helper.formatOrder(response.body),
            },
            downloading: false,
            error: null,
          }),
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the items.",
          });
        });
      }
    });
  };

  // Handle Live Editing: Only send the field value that changed.
  putContact = (contactuuid, fieldname, value, prev) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CONTACTS;
    const data = {
      contactuuid: contactuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Replace contact with data from web service but keep editing flag
        let contact = response.body;
        // Set the edit mode to keep the new contact's card open
        contact.editmode = true;
        const contacts = Helper.replaceItemInList(this.getContactsContainerRef().contacts, contact);

        // If the contact that was edited is the stock order or walk-in customer, update local storage
        this.maybeUpdateLocalStorageContact(contact);

        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          if (this.state.order) {
            // If the first contact on the order was updated, then update the contact name on the order
            //   with the first contact's name because the contact name is outside the contact object.
            if (Helper.inList(["firstname", "lastname"], fieldname) && contactuuid === this.state.order?.contactuuid) {
              const contactname = this.state.order?.company.contacts[0].firstname + " " + this.state.order?.company.contacts[0].lastname;
              this.setState(prevState => ({
                order: {
                  ...prevState.order,
                  contactname: contactname,
                },
              }));
              this.putOrder(this.state.order?.orderuuid, "contactuuid", contactuuid);
            } else {
              this.setState({ downloading: false });
              this.props.hideOverlay();
            }
          } else {
            this.setState({ downloading: false });
            this.props.hideOverlay();
          }
          if (response.body.note_created) {
            if (this.refreshFoldersCallback) {
              this.refreshFoldersCallback();
            }
          }
        });
      } else if (response.status === 409) {
        const message = response.body.message || "Email address cannot be removed for billing customers.";
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: message,
          });
          // Copy the contact and update that field
          const contacts = this.getContactsContainerRef().contacts.map(contact => {
            if (contact.contactuuid === contactuuid) {
              contact[fieldname] = prev;
            }
            return contact;
          });
          // Update the state with the updated contact.
          this.setStateContacts(contacts);
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the contact.",
          });
        });
      }
    });
  };

  putCompany = (companyuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const data = {
      companyuuid: companyuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state with response, turn off downloading
        this.setState({ downloading: false });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the order.",
          });
        });
      }
    });
  };

  putShipAddress = (addressuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });
    //set up to make database call
    const url = Constants.URL_ADDRESSES;
    const data = {
      addressuuid: addressuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Replace contact with data from web service but keep editing flag
        const contacts = this.state.order?.company.contacts.map(contact => {
          if (contact.shippingaddress.addressuuid === addressuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "PUT" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the shipping address.",
          });
        });
      }
    });
  };

  putPhoto = (photouuid, photourl) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    const params = { photouuid: photouuid, photo: photourl };
    Helper.putData(url, params).then(response => {
      if (response.status === 200) {
        // Delete the photo from the product
        this.setState(prevState => ({
          product: {
            ...prevState.product,
            photos: prevState.product.photos.map(photo => {
              if (photo.photouuid === photouuid) {
                photo.photourl = photourl;
              }
              return photo;
            }),
          },
        }));
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the photo.",
          });
        });
      }
    });
  };

  putMaastBillingPlan = (datatype, field, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //format numbers before executing the PUT
    if (datatype === "float" || datatype === "int") {
      value = numeral(value ?? 0).value();
    }

    //set up to make database call
    const url = Constants.URL_BILLING;
    let data = {
      action: Constants.BILLING_PUT_PLAN,
      plan_code: this.state.plan.plan_code,
    };
    data[field] = value;
    if (field === "plan_desc") {
      data.plan_name = value;
    }
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        const plan = { ...this.state.plan, ...response.body.data };
        this.setState({ plan: plan, downloading: false }, () => {
          this.props.updateBreadcrumb({ selectedItem: this.state.plan });
          if (field === "plan_desc") {
            this.props.showOverlay({
              text: "Please note:\nUpdates to the plan name will not be\nreflected in existing subscriptions.\nAll future subscriptions will use the new plan name.",
              type: Constants.OVERLAY_MESSAGE,
            });
          } else {
            this.props.hideOverlay();
          }
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the plan.",
          });
        });
      }
    });
  };

  putBillingPlan = (datatype, field, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //format numbers before executing the PUT
    if (datatype === "float" || datatype === "int") {
      value = numeral(value ?? 0).value();
    }

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    let data = {
      action: Constants.BILLING_PLAN,
      billingplanuuid: this.state.billingplan.billingplanuuid,
    };
    data[field] = value;

    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        const billingplan = { ...this.state.billingplan, ...response.body };
        billingplan.recur_amt = numeral(billingplan.recur_amt).format(Constants.CURRENCY);
        this.setState({ billingplan: billingplan, downloading: false }, () => {
          this.props.updateBreadcrumb({ selectedItem: this.state.billingplan });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the plan.",
          });
        });
      }
    });
  };

  // Update the subscription with a new card
  putMaastSubscription = (subscription_id, new_card_id, new_plan_desc, new_date_next, payment_flag, callback = null) => {
    // Check that data has changed
    if (!new_card_id && !new_plan_desc && !new_date_next) {
      return;
    }
    // If the date_next is being edited, it must be accompanied by a payment flag
    // If the flag is not present, then prompt for it.
    if (new_date_next && payment_flag === null) {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Is this a permanent change to the billing date?\nSelecting 'No' will postpone/advance only the next payment\nand all other payments will remain on the original schedule",
        callback: response => {
          const payment_flag = response === Constants.OVERLAY_RESPONSE_YES ? Constants.ALL : Constants.NEXT;
          this.putMaastSubscription(subscription_id, new_card_id, new_plan_desc, new_date_next, payment_flag);
        },
      });
      return;
    }

    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    let data = {
      ...this.props.params,
      action: Constants.BILLING_PUT_SUBSCRIPTION,
      subscription_id: subscription_id,
      ref_uuid: this.getContactsContainerRef().companyuuid,
      masked_card_number: this.state.maastCustomer.billing_cards.find(card => card.card_id === new_card_id)?.card_number,
    };
    if (new_card_id) {
      data.card_id = new_card_id;
    }
    if (new_plan_desc) {
      data.plan_desc = new_plan_desc;
    }
    if (new_date_next && payment_flag) {
      data.date_next = new_date_next;
      data.payment_flag = payment_flag;
    }
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body?.code === 0) {
        this.setState(
          prevState => ({
            downloading: false,
            subscriptions: prevState.subscriptions.map(sub => {
              if (sub.subscription_id === subscription_id) {
                let old_card_id = sub.card_id;
                sub = { ...sub, ...response.body.data };
                // Maast does not return the card_id for subscriptions that use the "default card" on file
                // Therefore we go to the customer's card list to find the correct "default" card
                // If we can't find the primary card, then use the new_card_id or the old_card_id from state
                if (!sub.card_id) {
                  sub.card_id = response.body.data.customer.billing_cards.find(card => card.primary === true)?.card_id || new_card_id || old_card_id;
                }
              }
              return sub;
            }),
          }),
          () => {
            this.props.hideOverlay();
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
            if (callback) {
              callback();
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the subscription.",
          });
        });
      }
    });
  };

  putMaastStoredPayment = (customer_id, billing_first, billing_last, billing_zip, card_id, exp_date, masked_card_number) => {
    // Format Expiration date to only contain integers
    exp_date = exp_date.replace(/\D/g, "");
    // Format billing zip code to remove trailing dashes
    billing_zip = billing_zip.replace(/-$/g, "");

    // If nothing changed, then don't make the API call
    const card = this.state.maastCustomer.billing_cards.find(card => card.card_id === card_id);
    if (
      billing_first === card.billing_first_name &&
      billing_last === card.billing_last_name &&
      billing_zip === card.billing_zip &&
      exp_date === card.exp_date
    ) {
      this.props.hideOverlay();
      return;
    }

    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });
    //set up to make database call
    const url = Constants.URL_BILLING;
    const data = {
      ...this.props.params,
      action: Constants.BILLING_PUT_STORED_PAYMENT,
      customer_id: customer_id,
      card_id: card_id,
      masked_card_number: masked_card_number,
      billing_first_name: billing_first,
      billing_last_name: billing_last,
      billing_zip: billing_zip,
      exp_date: exp_date,
      updated: {
        billing_first: billing_first !== card.billing_first_name,
        billing_last: billing_last !== card.billing_last_name,
        billing_zip: billing_zip !== card.billing_zip,
        exp_date: exp_date !== card.exp_date,
      },
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body?.data) {
        //Update state with response, turn off downloading
        const billing_cards = response.body.data.billing_cards;
        this.setState(
          prevState => ({
            maastCustomer: { ...prevState.maastCustomer, billing_cards: billing_cards },
            downloading: false,
          }),
          () => {
            // If we are creating a new subscription and a plan has been chosen
            // and the subscription does not have a card_id,
            // then add the card to the subscription
            if (this.state.isNew && this.state.subscriptions.length > 0 && !this.state.subscriptions[0].card_id) {
              this.setState(prevState => ({
                subscriptions: prevState.subscriptions.map(sub => {
                  sub.card_id = card_id;
                  return sub;
                }),
              }));
            }
            this.props.hideOverlay();
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
          }
        );
      } else {
        this.setState({ downloading: false }, () => {
          let errors = "";
          for (let key in response.body?.data || {}) {
            errors += response.body.data[key] + "\n";
          }
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error adding the card.",
            bullets: errors,
          });
        });
      }
    });
  };

  putSubscription = (subscriptionuuid, datatype, fieldname, value, callback = null, card_id) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    if (datatype === "float") {
      value = numeral(value).value();
    }
    let data = {
      action: Constants.SUBSCRIPTION,
      subscriptionuuid: subscriptionuuid,
      [fieldname]: value,
    };
    // A card_id is only required for updating a subscription's status from paused to active
    if (card_id) {
      data.card_id = card_id;
    }

    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(prevState => ({
          downloading: false,
          subscriptions: prevState.subscriptions.map(sub => {
            if (sub.subscriptionuuid === subscriptionuuid) {
              sub = {
                ...sub,
                ...response.body,
                // Merge custom fields from the response with the custom fields in state
                custom_fields: sub.custom_fields.map(cf => {
                  return { ...cf, ...(response.body.custom_fields.find(field => field.customfieldlabeluuid === cf.customfieldlabeluuid) || {}) };
                }),
              };
            }
            return sub;
          }),
        }));
        if (this.refreshFoldersCallback) {
          this.refreshFoldersCallback();
        }
        this.props.hideOverlay();
        if (callback) {
          callback();
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the subscription.",
          });
        });
      }
    });
  };

  /**
   * This is the API call to add or update a custom field on a subscription in the ClerkHound database.
   */
  putCustomField = (companyuuid, subscriptionid, customfieldlabeluuid, customfieldvalue) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const action = this.props.appState.currentView === Constants.BILLING ? Constants.CUSTOM_FIELD_MAAST : Constants.CUSTOM_FIELD;
    const url = Constants.URL_RECURRINGS;
    let data = {
      action: action,
      companyuuid: companyuuid,
      subscriptionid: `${subscriptionid}`,
      customfieldlabeluuid: customfieldlabeluuid,
      customfieldvalue: customfieldvalue,
    };

    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({
            downloading: false,
            // When a successful add/edit/delete request is made,
            // remove deleted fields and/or
            // turn off the isNew flag for modified fields
            subscriptions: prevState.subscriptions.map(sub => {
              if (sub.subscriptionuuid === subscriptionid) {
                sub.custom_fields = sub.custom_fields
                  // remove deleted fields from state
                  .filter(field => {
                    return !(field.archived === true && !field.customfieldvalue);
                  });
              }
              return sub;
            }),
          }),
          () => {
            if (this.refreshFoldersCallback) {
              this.refreshFoldersCallback();
            }
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the custom field.",
          });
        });
      }
    });
  };

  putProductByUUID = (productuuid, type, field, value, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //format numbers before executing the PUT
    if (type === "float") {
      value = numeral(value).value();
    }

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    let data = {
      productuuid: productuuid,
    };
    data[field] = value;
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state with response, turn off downloading
        this.setState({ downloading: false });
        //Hide overlay after database action is complete
        if (callback) {
          callback();
        } else {
          this.props.hideOverlay();
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the product.",
          });
        });
      }
    });
  };

  postMaastSubscriptionStatus = (subscription, newSubscriptionStatus, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    let status = "";
    if (newSubscriptionStatus === Constants.SUBSCRIPTION_CANCELLED) {
      status = "cancel";
    } else if (newSubscriptionStatus === Constants.SUBSCRIPTION_PAUSED) {
      status = "pause";
    } else if (newSubscriptionStatus === Constants.SUBSCRIPTION_ACTIVE) {
      status = "resume";
    }

    let data = {
      ...this.props.params,
      action: Constants.BILLING_POST_SUBSCRIPTION_STATUS,
      subscription_id: subscription.subscription_id,
      status: status,
      customer_id: subscription.customer_id,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({
            subscriptions: prevState.subscriptions.map(sub => {
              if (sub.subscription_id === subscription.subscription_id) {
                sub.status = newSubscriptionStatus;
              }
              return sub;
            }),
            downloading: false,
          }),
          () => {
            if (callback) {
              callback();
            } else {
              this.props.hideOverlay();
            }
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the payment card.",
          });
        });
      }
    });
  };

  selectSpawnedDeposits = (orderuuid, callback) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    let params = { action: Constants.GET_SPAWNED_DEPOSITS, orderuuid: orderuuid };
    Helper.getData(url, params).then(response => {
      callback(response);
    });
  };

  deleteTag = taguuid => {
    //set up to make database call
    const url = Constants.URL_TAGS;
    let params = { taguuid: taguuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        if (Helper.isOrderView(this.props.appState.currentView)) {
          this.setState(prevState => ({
            order: {
              ...prevState.order,
              company: {
                ...prevState.order.company,
                tags: prevState.order.company.tags.filter(tag => tag.taguuid !== taguuid),
              },
            },
          }));
        } else if (this.props.appState.currentView === Constants.PRODUCT) {
          this.setState(prevState => ({
            product: {
              ...prevState.product,
              tags: prevState.product.tags.filter(tag => tag.taguuid !== taguuid),
            },
          }));
        } else {
          this.setState(prevState => ({
            company: {
              ...prevState.company,
              tags: prevState.company.tags.filter(tag => tag.taguuid !== taguuid),
            },
          }));
        }
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the tag",
          });
        });
      }
    });
  };

  deleteOrderTag = taguuid => {
    //set up to make database call
    const url = Constants.URL_TAGS;
    let params = { taguuid: taguuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(prevState => ({
          order: {
            ...prevState.order,
            tags: prevState.order.tags.filter(tag => tag.taguuid !== taguuid),
          },
        }));
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the tag",
          });
        });
      }
    });
  };

  deletePaymentRequestLink = requestuuid => {
    //set up to make database call
    const url = Constants.URL_COMPANIES;
    let params = { requestuuid: requestuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200) {
        this.setState(
          prevState => ({
            company: {
              ...prevState.company,
              vaultrequest: null,
            },
          }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the payment method request link",
          });
        });
      }
    });
  };

  postTag = (tag, index, callback = null) => {
    //set up to make database call
    const url = Constants.URL_TAGS;
    let params = {
      tagname: tag.tagname.trim(),
      tagcolor: tag.tagcolor,
      reftype: tag.reftype,
      refuuid: tag.refuuid,
      reflabel: tag.reflabel,
    };
    Helper.postData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        if (Helper.isOrderView(this.props.appState.currentView)) {
          this.setState(prevState => ({
            order: {
              ...prevState.order,
              company: {
                ...prevState.order.company,
                tags: prevState.order.company.tags.map((tag, i) => {
                  if (i === index) {
                    tag = response.body;
                  }
                  return tag;
                }),
              },
            },
          }));
        } else if (this.props.appState.currentView === Constants.PRODUCT) {
          this.setState(prevState => ({
            product: {
              ...prevState.product,
              tags: prevState.product.tags.map((tag, i) => {
                if (i === index) {
                  tag = response.body;
                }
                return tag;
              }),
            },
          }));
        } else {
          if (index === -1) {
            this.setState(prevState => ({
              company: {
                ...prevState.company,
                tags: [...prevState.company.tags, response.body],
              },
            }));
          } else {
            this.setState(prevState => ({
              company: {
                ...prevState.company,
                tags: prevState.company.tags.map((tag, i) => {
                  if (i === index) {
                    tag = response.body;
                  }
                  return tag;
                }),
              },
            }));
          }
        }
        if (callback) {
          callback();
        }
      } else if (response.status === 403) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "You already have a tag by that name!",
        });
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error saving the tag",
        });
      }
    });
  };

  postOrderTag = (tag, index) => {
    //set up to make database call
    const url = Constants.URL_TAGS;
    let params = {
      tagname: tag.tagname.trim(),
      tagcolor: tag.tagcolor,
      reftype: tag.reftype,
      refuuid: tag.refuuid,
      reflabel: tag.reflabel,
    };
    Helper.postData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(prevState => ({
          order: {
            ...prevState.order,
            tags: prevState.order.tags.map((tag, i) => {
              if (i === index) {
                tag = response.body;
              }
              return tag;
            }),
          },
        }));
      } else if (response.status === 403) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "You already have a tag by that name!",
        });
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error saving the tag",
        });
      }
    });
  };

  postCampaign = (campaignname, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CAMPAIGNS;
    let data = { campaignname: campaignname };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({ downloading: false, loading: false, campaign: response.body }),
          () => {
            this.props.hideOverlay();
            if (callback) {
              callback();
            }
          }
        );
      } else {
        this.setState({ error: "POST", downloading: false, loading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the campaign.",
          });
        });
      }
    });
  };

  putTag = (tag, index, reftype) => {
    // Check for no changes
    if (tag.prevTagName === tag.tagname.trim() && tag.prevTagColor === tag.tagcolor) {
      return;
    }

    //set up to make database call
    const url = Constants.URL_TAGS;
    let params = {
      taguuid: tag.taguuid,
      reftype: reftype,
    };
    if (tag.prevTagName !== tag.tagname) {
      params.tagname = tag.tagname;
    }
    if (tag.prevTagColor !== tag.tagcolor) {
      params.tagcolor = tag.tagcolor;
    }
    Helper.putData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        if (Helper.isOrderView(this.props.appState.currentView)) {
          this.setState(prevState => ({
            order: {
              ...prevState.order,
              company: {
                ...prevState.order.company,
                tags: prevState.order.company.tags.map((tag, i) => {
                  if (i === index) {
                    tag = response.body;
                  }
                  return tag;
                }),
              },
            },
          }));
        } else if (this.props.appState.currentView === Constants.PRODUCT) {
          this.setState(prevState => ({
            product: {
              ...prevState.product,
              tags: prevState.product.tags.map((tag, i) => {
                if (i === index) {
                  tag = response.body;
                }
                return tag;
              }),
            },
          }));
        } else {
          this.setState(prevState => ({
            company: {
              ...prevState.company,
              tags: prevState.company.tags.map((tag, i) => {
                if (i === index) {
                  tag = response.body;
                }
                return tag;
              }),
            },
          }));
        }
      } else if (response.status === 403) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "You already have a tag by that name!",
        });
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error updating the tag",
        });
      }
    });
  };

  putOrderTag = (tag, index, reftype) => {
    // Check for no changes
    if (tag.prevTagName === tag.tagname.trim() && tag.prevTagColor === tag.tagcolor) {
      return;
    }

    //set up to make database call
    const url = Constants.URL_TAGS;
    let params = {
      taguuid: tag.taguuid,
      reftype: reftype,
    };
    if (tag.prevTagName !== tag.tagname) {
      params.tagname = tag.tagname;
    }
    if (tag.prevTagColor !== tag.tagcolor) {
      params.tagcolor = tag.tagcolor;
    }
    Helper.putData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(prevState => ({
          order: {
            ...prevState.order,
            tags: prevState.order.tags.map((tag, i) => {
              if (i === index) {
                tag = response.body;
              }
              return tag;
            }),
          },
        }));
      } else if (response.status === 403) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "You already have a tag by that name!",
        });
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error updating the tag",
        });
      }
    });
  };

  putProspect = event => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CAMPAIGNS;
    let data = {
      marketingprospectuuid: this.state.prospect.marketingprospectuuid,
      [event.target.name]: event.target.value,
    };
    Helper.putData(url, data).then(response => {
      if ([200, 202].includes(response.status) && response.body) {
        // Update the prospect in state (mainly for phone number formatting)
        this.setState(prevState => ({
          prospect: { ...prevState.prospect, ...response.body },
        }));
        // Update state with response, turn off downloading
        this.setState({ downloading: false });
        // Hide overlay after database action is complete
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the prospect.",
          });
        });
      }
    });
  };

  putCampaign = (field, value, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CAMPAIGNS;
    let data = {
      campaignuuid: this.state.campaign.campaignuuid,
    };
    data[field] = value;
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state with response, turn off downloading
        this.setState(
          prevState => ({
            downloading: false,
            campaign: { ...prevState.campaign, ...response.body },
          }),
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            if (callback) {
              callback();
            }
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the campaign.",
          });
        });
      }
    });
  };

  deleteOrder = (orderuuid, authtoken) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    const order_number = Helper.getOrderNumber(this.state.order.ordertype, this.state.order.ordersubtype, this.state.order.ordernumber);

    //set up to make database call
    const url = Constants.URL_ORDERS;
    let params = { orderuuid: orderuuid };
    if (authtoken) {
      params.authtoken = authtoken;
    }
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Order " + order_number + " deleted.",
            callback: () => {
              this.props.followBreadcrumb();
            },
          });
        });
      } else if (response.status === 400 && response.body?.message) {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 401 && response.body?.message) {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the order.",
          });
        });
      }
    });
  };

  archivePlan = plan_code => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    const params = {
      plan_name: this.state.plan?.plan_name,
      action: Constants.BILLING_POST_PLAN_ARCHIVE,
      plan_code: plan_code,
    };

    Helper.postData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.hideOverlay();
          this.props.followBreadcrumb();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error archiving the plan.",
          });
        });
      }
    });
  };

  deleteMaastBillingPlan = plan_id => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    const params = {
      action: Constants.BILLING_DELETE_PLAN,
      plan_id: plan_id,
    };

    Helper.deleteData(url, params).then(response => {
      if (response.status === 200) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.hideOverlay();
          this.props.followBreadcrumb();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the plan.",
          });
        });
      }
    });
  };

  deleteBillingPlan = billingplanuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    const params = {
      action: Constants.BILLING_PLAN,
      billingplanuuid: billingplanuuid,
    };

    Helper.deleteData(url, params).then(response => {
      if (response.status === 200) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.hideOverlay();
          this.props.followBreadcrumb();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the plan.",
          });
        });
      }
    });
  };

  deleteMaastInvoice = invoice => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    const params = {
      action: Constants.BILLING_DELETE_CANCEL_INVOICE,
      invoice_id: invoice.invoice_id,
      amt_balance_zero: "True",
    };

    Helper.deleteData(url, params).then(response => {
      if (response.status === 200) {
        // Update the UI
        this.setState(prevState => ({
          maastInvoices: prevState.maastInvoices.map(inv => {
            if (inv.invoice_id === invoice.invoice_id) {
              inv.status = Constants.MAAST_INVOICE_STATUS_CANCELED;
              inv.amt_balance = "0.00";
            }
            return inv;
          }),
        }));
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error cancelling the invoice.",
          });
        });
      }
    });
  };

  deleteStoredPayment = card => {
    if (this.props.appState.clientSettings.PAYMENT_GATEWAY === Constants.MAAST) {
      this.deleteStoredPaymentMaast(card);
    } else {
      this.deleteStoredPaymentNMI(card);
    }
  };

  deleteStoredPaymentNMI = card => {
    //Protect screen during downloading data
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    const params = {
      action: Constants.BILLING_PUT_DELETE_STORED_PAYMENT,
      companyuuid: this.state.company.companyuuid,
      customer_vault_id: card.customer_vault_id,
    };

    Helper.deleteData(url, params).then(response => {
      if (response.status === 200) {
        this.setState(
          prevState => ({
            maastCustomer: {
              ...prevState.maastCustomer,
              billing_cards: prevState.maastCustomer.billing_cards.filter(c => c.customer_vault_id !== card.customer_vault_id),
            },
            downloading: false,
          }),
          () => {
            this.props.hideOverlay();
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
          }
        );
      } else if (response.status === 401) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        // Show a message saying the card cannot be deleted because it is being used in a subscription
        this.props.showOverlay(
          {
            type: Constants.OVERLAY_MESSAGE,
            text: "This card cannot be deleted because it is currently assigned to a subscription.",
          },
          () => {
            // Refresh the subscription list
            this.getSubscriptions(this.state.maastCustomer.customer_id);
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the card.",
          });
        });
      }
    });
  };

  deleteStoredPaymentMaast = card => {
    //Protect screen during downloading data
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    const params = {
      action: Constants.BILLING_PUT_DELETE_STORED_PAYMENT,
      card_id: card.card_id,
      masked_card_number: card.card_number,
      customer_id: this.state.maastCustomer.customer_id,
    };

    Helper.putData(url, params).then(response => {
      if (response.status === 200) {
        const billing_cards = response.body.data.billing_cards;
        this.setState(
          prevState => ({
            maastCustomer: { ...prevState.maastCustomer, billing_cards: billing_cards },
            downloading: false,
          }),
          () => {
            this.props.hideOverlay();
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
          }
        );
      } else if (response.status === 401) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        // Show a message saying the card cannot be deleted because it is being used in a subscription
        this.props.showOverlay(
          {
            type: Constants.OVERLAY_MESSAGE,
            text: "This card cannot be deleted because it is currently assigned to a subscription.",
          },
          () => {
            // Refresh the subscription list
            this.getSubscriptions(this.state.maastCustomer.customer_id);
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the card.",
          });
        });
      }
    });
  };

  deleteRepairItem = repairitemuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_REPAIR_ITEMS;
    const params = { repairitemuuid: repairitemuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update state to turn off downloading and hide the overlay
        let order = this.state.order;
        order.repairitems = this.state.order?.repairitems.filter(item => item.repairitemuuid !== repairitemuuid);
        order = Helper.maybeAddDefaultRepairItem(order);
        this.setState(
          prevState => ({
            downloading: false,
            error: null,
            order: {
              ...prevState.order,
              repairitems: order.repairitems,
            },
          }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the item.",
          });
        });
      }
    });
  };

  deleteOrderItem = orderitemuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDER_ITEMS;
    const params = { orderitemuuid: orderitemuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({
            order: {
              ...prevState.order,
              ...Helper.formatOrder(response.body),
            },
            downloading: false,
            error: null,
          }),
          () => {
            if (response.body.note_created) {
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            }
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the item.",
          });
        });
      }
    });
  };

  deleteShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    const contact = Helper.getContactByUUID(this.state.order, contactuuid);
    const addressuuid = contact.shippingaddress.addressuuid;
    //set up to make database call
    const url = "/addresses";
    const params = { addressuuid: addressuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Turn off the shipping address switch
        const contacts = this.state.order?.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.hasshippingaddress = false;
            contact.shippingaddress = Helper.getBlankShippingAddress();
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "DELETE" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };

  deletePhoto = photouuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // Mark the photo as being deleted
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        photos: prevState.product.photos.map(photo => {
          if (photo.photouuid === photouuid) {
            photo.deleting = true;
          }
          return photo;
        }),
      },
    }));

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    const params = { photouuid: photouuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200) {
        // Delete the photo from the product
        this.setState(prevState => ({
          downloading: false,
          product: {
            ...prevState.product,
            photos: prevState.product.photos.filter(photo => photo.photouuid !== photouuid),
          },
        }));
      } else {
        this.setState(
          prevState => ({
            error: "DELETE",
            downloading: false,
            photos: prevState.product.photos.map(photo => {
              if (photo.photouuid === photouuid) {
                photo.deleting = false;
              }
              return photo;
            }),
          }),
          () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "There was an error deleting the photo.",
            });
          }
        );
      }
    });
  };

  deleteSubscription = subscription => {
    const subscriptionuuid = subscription.subscriptionuuid;
    if (!subscriptionuuid) {
      return;
    }

    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_RECURRINGS;
    const params = { subscriptionuuid: subscriptionuuid };

    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Remove the deleted subscription from the list and redisplay
        const subscriptions = this.state.subscriptions.filter(sub => sub.subscriptionuuid !== subscriptionuuid);
        this.setState({ subscriptions: subscriptions, downloading: false }, () => {
          // Refresh the Notes tab in folders
          if (this.refreshFoldersCallback) {
            this.refreshFoldersCallback();
          }
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the subscription.",
          });
        });
      }
    });
  };

  deleteContact = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // If new contact, just remove from list
    if (contactuuid === "new") {
      const contacts = this.getContactsContainerRef().contacts.filter(contact => contact.contactuuid !== "new");
      this.setStateContacts(contacts, () => {
        this.setState({ downloading: false });
        this.props.hideOverlay();
      });
    } else {
      //set up to make database call
      const url = Constants.URL_CONTACTS;
      const params = { contactuuid: contactuuid };
      Helper.deleteData(url, params).then(response => {
        if (response.status === 200 && response.body) {
          // Remove the deleted contact from the list and redisplay
          const contacts = this.getContactsContainerRef().contacts.filter(contact => contact.contactuuid !== contactuuid);
          // If this was the last contact, then the webservice will delete the company
          if (contacts.length === 0) {
            //Update state to turn off downloading
            this.setState({ downloading: false, error: null });
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            //Return to the List screen
            this.props.followBreadcrumb();
          } else {
            // Update state with response, turn off downloading and hide the overlay
            this.setStateContacts(contacts, () => {
              this.setState({ downloading: false });
              this.props.hideOverlay();
            });
          }
        } else if (response.status === 409) {
          this.setState({ error: null, downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Cannot delete customer with open orders.",
            });
          });
        } else if (response.status === 503) {
          this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
          this.props.hideOverlay();
        } else {
          this.setState({ error: "DELETE", downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "There was an error deleting the contact.",
            });
          });
        }
      });
    }
  };

  deletePayment = (authtoken, payment, orderuuid) => {
    //Protect screen during downloading data
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Deleting payment...",
    });
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    let data = {
      orderuuid: orderuuid,
      paymentuuid: payment.paymentuuid,
      authtoken: authtoken,
    };
    Helper.deleteData(url, data).then(response => {
      if (response.status === 200) {
        // Move the selected contact to front of list in company, if necessary
        if (this.state.contactuuid) {
          response.body.company = Helper.moveContactToFront(this.state.contactuuid, response.body.company);
        }
        // Replace the order in state with the one that came back from the database
        this.setState(
          {
            order: { ...Helper.formatOrder(response.body) },
            error: null,
            downloading: false,
          },
          () => {
            // Refresh the notes tab in folders
            if (this.refreshFoldersCallback) {
              this.refreshFoldersCallback();
            }
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Payment deleted.",
            });
          }
        );
      } else if (response.status === 400) {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message || "Unable to delete the payment.",
          });
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the payment.",
          });
        });
      }
    });
  };
}
export default BaseDetailViewAPI;
